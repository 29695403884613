import { SET_MODULES, SET_MODULE_STATUS } from '../actions/type';
import { check } from '../../utils/CheckRole';

var modules = JSON.parse(localStorage.getItem('modules'));

const initialState = {
  modules: modules,
  currentModuleStatus: check(modules, window.location.hash),
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_MODULES:
      return { ...state, modules: payload };
    case SET_MODULE_STATUS:
      return { ...state, currentModuleStatus: payload };
    default:
      return state;
  }
}
