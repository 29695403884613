import {
  SET_GOVERNMENT_SERVICES,
  SET_EVENTS,
  SET_SERVICE_PROVIDERS,
  SET_FORM_SELECTORS_REPORT_DATA,
  SET_TABLE_REPORT_DATA,
  RESET_STATE,
  ADD_FILE,
  REMOVE_FILE,
  ADD_URLS_AND_CODES,
  SET_VALIDATION_ERRORS,
  CLEAR_VALIDATION_ERRORS,
  INCREMENT_ACTIVE_OPERATIONS,
  DECREMENT_ACTIVE_OPERATIONS
} from '../constants/reportCreation';

export const setGovernmentServices = (payload) => ({
  type: SET_GOVERNMENT_SERVICES,
  payload
});

export const setEvents = (payload) => ({
  type: SET_EVENTS,
  payload
});

export const setServiceProviders = (payload) => ({
  type: SET_SERVICE_PROVIDERS,
  payload
});

export const setFormSelectorsReportData = (payload) => ({
  type: SET_FORM_SELECTORS_REPORT_DATA,
  payload
});

export const setTableReportData = (payload) => ({
  type: SET_TABLE_REPORT_DATA,
  payload
});

export const resetState = () => ({
  type: RESET_STATE
});

export const addFile = (payload) => ({
  type: ADD_FILE,
  payload
});

export const removeFile = (payload) => ({
  type: REMOVE_FILE,
  payload
});

export const addUrlsAndCodes = (payload) => ({
  type: ADD_URLS_AND_CODES,
  payload
});

export const setValidationErrors = (payload) => ({
  type: SET_VALIDATION_ERRORS,
  payload
});

export const clearValidationErrors = () => ({
  type: CLEAR_VALIDATION_ERRORS
});

export const incrementActiveOperations = () => ({
  type: INCREMENT_ACTIVE_OPERATIONS
});

export const decrementActiveOperations = () => ({
  type: DECREMENT_ACTIVE_OPERATIONS
});
