import { chain, isArray } from 'lodash';

export function combineWithNumbers(data, parentNumber = '') {
  return data.map((obj, index) => {
    const number = parentNumber ? `${parentNumber}.${index + 1}` : `${index + 1}`;

    let processedChildren;
    if (isArray(obj.children)) {
      processedChildren = combineWithNumbers(obj.children, number);
    }

    const newObj = {
      ...obj,
      uniqId: number,
    };

    if (processedChildren && processedChildren.length > 0) {
      newObj.children = processedChildren;
    }

    return newObj;
  });
}

export function testData(data) {
  const withChapter = data.filter((item) => item.eduActivity.chapter);
  const withoutChapter = data.filter((item) => !item.eduActivity.chapter);

  const groupedChapters = groupByChapter(withChapter);
  const topLevelCategories = groupByCategory(withoutChapter, { topLevel: true });

  const merged = [...groupedChapters, ...topLevelCategories];
  const combined = combineWithNumbers(merged);return combined;
}

function groupByChapter(items) {
  return chain(items)
    .groupBy((item) => (item.eduActivity.chapter && item.eduActivity.chapter.name) || '')
    .map((groupItems, groupKey) => {
      const first = groupItems[0];
      const chapterId = (first.eduActivity.chapter && first.eduActivity.chapter.id) || null;
      const chapterName = (first.eduActivity.chapter && first.eduActivity.chapter.name) || groupKey;

      const children = groupByCategory(groupItems, { topLevel: false });

      const chapterFiles = groupItems.flatMap((gi) => gi.files.filter((f) => f.type === 'CHAPTER'));

      const sumChildren = children.reduce((acc, c) => acc + (c.count || 0), 0);

      return {
        id: chapterId,
        type: 'chapter',
        name: chapterName,
        isAttachmentRequired: first.eduActivity.isAttachmentRequired,
        count: sumChildren,
        files: chapterFiles,
        mainId: first.id,
        children,
      };
    })
    .value();
}

function groupByCategory(items, { topLevel = false } = {}) {
  return chain(items)
    .groupBy((item) => (item.eduActivity.category && item.eduActivity.category.name) || '')
    .map((groupItems, groupKey) => {
      const first = groupItems[0] || {};
      const categoryId = (first.eduActivity.category && first.eduActivity.category.id) || null;
      const categoryName =
        (first.eduActivity.category && first.eduActivity.category.name) || groupKey;

      const children = groupBySubCategory(groupItems);

      const categoryFiles = groupItems.flatMap((gi) =>
        gi.files.filter((f) => f.type === 'CATEGORY')
      );

      const sumChildren = children.reduce((acc, c) => acc + (c.count || 0), 0);

      const result = {
        id: categoryId,
        type: 'category',
        name: categoryName,
        count: sumChildren,
        files: categoryFiles,
        children: children.length ? children : undefined,
        mainId: first.id,
      };

      const isAttachmentRequired = first.eduActivity.isAttachmentRequired;
      if (topLevel) {
        result.isAttachmentRequired = isAttachmentRequired;
        result.isDeleted = first.eduActivity.isDeleted;
        result.isServiceCodeRequired = first.eduActivity.isServiceCodeRequired;
        result.isUrlRequired = first.eduActivity.isUrlRequired;
      } else {
        result.isAttachmentRequired = isAttachmentRequired;
      }

      if (!children.length) {
        if (result.isUrlRequired) {
          const allInfos = groupItems.flatMap((gi) => gi.infos || []);
          if (allInfos.length > 0) {
            result.infos = allInfos;
          }
        }
        result.eduActivityId = first.eduActivity.id;
        result.mainId = first.id; // id общая, не уверен нужна ли она
      }

      Object.keys(result).forEach((key) => {
        if (result[key] === undefined) {
          delete result[key];
        }
      });

      return result;
    })
    .value();
}

function groupBySubCategory(items) {
  return chain(items)
    .groupBy((item) => (item.eduActivity.subCategory && item.eduActivity.subCategory.name) || '')
    .map((groupItems, groupKey) => {
      const first = groupItems[0] || {};
      const subCategoryId =
        (first.eduActivity.subCategory && first.eduActivity.subCategory.id) || null;
      const subCategoryName =
        (first.eduActivity.subCategory && first.eduActivity.subCategory.name) || groupKey;

      const subCategoryFiles = groupItems.flatMap((gi) =>
        gi.files.filter((f) => f.type === 'SUBCATEGORY')
      );

      const sumSubCatCount = groupItems.reduce((acc, cur) => acc + (cur.subCategoryCount || 0), 0);

      let allInfos = [];
      if (first.eduActivity.isUrlRequired) {
        allInfos = groupItems.flatMap((item) => item.infos || []);
      }

      const result = {
        id: subCategoryId,
        type: 'subCategory',
        name: subCategoryName,
        isAttachmentRequired: first.eduActivity.isAttachmentRequired,
        isDeleted: first.eduActivity.isDeleted,
        isServiceCodeRequired: first.eduActivity.isServiceCodeRequired,
        isUrlRequired: first.eduActivity.isUrlRequired,
        eduActivityId: first.eduActivity.id,
        mainId: first.id, // id общая, не уверен нужна ли она
        count: sumSubCatCount,
        files: subCategoryFiles,
      };

      if (allInfos.length) {
        result.infos = allInfos;
      }

      Object.keys(result).forEach((key) => {
        if (result[key] === undefined) {
          delete result[key];
        }
      });
      return result;
    })
    .value();
}
