/* eslint-disable react/display-name */
import { Table } from 'antd';
import React from 'react';

const getSumCount = (arr) => arr.reduce((acc, cur) => {
  acc += cur.count || 0;
  return acc;
}, 0);

export const summaryTable = (data = []) => () => (
  <>
    <Table.Summary.Row>
      <Table.Summary.Cell index={0}></Table.Summary.Cell>
      <Table.Summary.Cell index={1}>Итого</Table.Summary.Cell>
      <Table.Summary.Cell index={2} align="center">
        {data.length > 0 ? getSumCount(data) : 0}
      </Table.Summary.Cell>
    </Table.Summary.Row>
  </>
);
