import { LEGAL_ENTITIES } from '../../../constants';
import { combineDataToXls } from './chartDataUtils';

const FILL_START = {
  1: 'A10',
  2: 'A9',
  3: 'A9',
};

const FILL_CONFIG = {
  1: { startRow: 10, columns: ['E', 'G', 'I', 'K', 'M', 'O', 'Q', 'S'] },
  3: { startRow: 9, columns: ['E', 'G', 'I', 'K'] },
};

const STYLE_CONFIG = {
  1: { startRow: 10, endColumn: 'S' },
  3: { startRow: 9, endColumn: 'I' },
};

const getRangeEnd = (list, start, column) => {
  let count = start;
  while (list.cell(`${column}${count}`).value() !== undefined) {
    count++;
  }
  return `${column}${count - 1}`;
};

const applyFillToCells = (sheet, startRow, endRow, columns, data) => {
  for (let i = 0; i < endRow - startRow; i++) {
    for (let j = 0; j < columns.length; j++) {
      if (data[i] && LEGAL_ENTITIES.includes(data[i].registryService.code)) {
        sheet.cell(`${columns[j]}${i + startRow}`);
      }
    }
  }
};

export const applyFill = (sheet, data, type) => {
  const config = FILL_CONFIG[type];
  if (config) {
    const dataSize = combineDataToXls(data, type).length;
    if (type === 1) {
      applyFillToCells(sheet, config.startRow, config.startRow + dataSize, config.columns, data);
    }
  }
};

export const setDataToXls = (sheet, data, type) => {
  sheet
    .cell(FILL_START[type])
    .value(combineDataToXls(data, type))
    .style({
      border: 'Borders',
      borderStyle: 'thin',
      fontFamily: 'Arial'
    });
};

const applyStyleToRange = (sheet, startRow, endColumn) => {
  const rangeStart = `D${startRow}`;
  const rangeEnd = getRangeEnd(sheet, startRow, endColumn);

  sheet.range(rangeStart, rangeEnd).style({
    horizontalAlignment: 'center',
    verticalAlignment: 'center'
  });
};

export const applyStyle = (sheet, type) => {
  const config = STYLE_CONFIG[type];
  if (config) {
    applyStyleToRange(sheet, config.startRow, config.endColumn);
  }
};