import React from 'react';
import { Popconfirm, Spin } from 'antd';
import moment from 'moment';

export const getColumns = (type, t, downloadFile, loadingFile) => {
  const COLUMNS = {
    1: [
      {
        title: t('userActions.serviceCode'),
        dataIndex: 'serviceCode',
        key: 'serviceCode',
        align: 'center',
        render: (_, record) => record.service && record.service.code || 0,
      },
      {
        title: t('governmentServiceEducation.governmentServicesName'),
        dataIndex: 'nameOfService',
        key: 'nameOfService',
        align: 'center',
        width: 200,
        render: (_, record) => record.service && record.service.name || 0,
      },
      {
        title: t('governmentServiceEducation.totalServicesProvided'),
        dataIndex: 'summary',
        key: 'summary',
        align: 'center',
        rowSpan: 3,
        children: [
          {
            children: [
              {
                children: [
                  {
                    title: t('governmentServiceEducation.individuals'),
                    dataIndex: 'totalGovServicesProvidedPhys',
                    key: 'totalGovServicesProvidedPhys',
                    align: 'center',
                    className: 'fizColumn summaryColumn',
                    render: (_, record) => record.totalGovServicesProvidedPhys || 0
                  },
                  {
                    title: t('governmentServiceEducation.legalEntities'),
                    dataIndex: 'totalGovServicesProvidedJur',
                    key: 'totalGovServicesProvidedJur',
                    align: 'center',
                    className: 'yrColumn summaryColumn',
                    render: (_, record) => record.totalGovServicesProvidedJur || 0
                  },
                ]
              }
            ]
          }
        ],
      },
      {
        title: t('governmentServiceEducation.providedThrough'),
        children: [
          {
            title: t('governmentServiceEducation.governmentCorporation'),
            rowSpan: 2,
            children: [
              {
                children: [
                  {
                    title: t('governmentServiceEducation.individuals'),
                    dataIndex: 'governmentForCitizenPhys',
                    key: 'governmentForCitizenPhys',
                    align: 'center',
                    className: 'fizColumn',
                    render: (_, record) => record.governmentForCitizenPhys || 0
                  },
                  {
                    title: t('governmentServiceEducation.legalEntities'),
                    dataIndex: 'governmentForCitizenJur',
                    key: 'governmentForCitizenJur',
                    align: 'center',
                    className: 'yrColumn',
                    render: (_, record) => record.governmentForCitizenJur || 0
                  },
                ]
              }
            ],
          },
          {
            title: t('governmentServiceEducation.eGovPortal'),
            rowSpan: 2,
            children: [
              {
                children: [
                  {
                    title: t('governmentServiceEducation.individuals'),
                    dataIndex: 'eGovernmentPortalPhys',
                    key: 'eGovernmentPortalPhys',
                    align: 'center',
                    className: 'fizColumn',
                    render: (_, record) => record.eGovernmentPortalPhys || 0
                  },
                  {
                    title: t('governmentServiceEducation.legalEntities'),
                    dataIndex: 'eGovernmentPortalJur',
                    key: 'eGovernmentPortalJur',
                    align: 'center',
                    className: 'yrColumn',
                    render: (_, record) => record.eGovernmentPortalJur || 0
                  },
                ]
              }
            ],
          },
          {
            title: t('governmentServiceEducation.governmentAgency'),
            children: [
              {
                title: t('governmentServiceEducation.paperForm'),
                children: [
                  {
                    title: t('governmentServiceEducation.individuals'),
                    dataIndex: 'paperFormPhys',
                    key: 'paperFormPhys',
                    align: 'center',
                    className: 'fizColumn',
                    render: (_, record) => record.paperFormPhys || 0
                  },
                  {
                    title: t('governmentServiceEducation.legalEntities'),
                    dataIndex: 'paperFormJur',
                    key: 'paperFormJur',
                    align: 'center',
                    className: 'yrColumn',
                    render: (_, record) => record.paperFormJur || 0
                  },
                ],
              },
              {
                // eslint-disable-next-line max-len
                title: t('governmentServiceEducation.eServiceNoContact'),
                children: [
                  {
                    title: t('governmentServiceEducation.individuals'),
                    dataIndex: 'eServiceProviderInfoNoContactPhys',
                    key: 'eServiceProviderInfoNoContactPhys',
                    align: 'center',
                    className: 'fizColumn',
                    render: (_, record) => record.eServiceProviderInfoNoContactPhys || 0
                  },
                  {
                    title: t('governmentServiceEducation.legalEntities'),
                    dataIndex: 'eServiceProviderInfoNoContactJur',
                    key: 'eServiceProviderInfoNoContactJur',
                    align: 'center',
                    className: 'yrColumn',
                    render: (_, record) => record.eServiceProviderInfoNoContactJur || 0
                  },
                ],
              },
              {
                // eslint-disable-next-line max-len
                title: t('governmentServiceEducation.eServiceWithContact'),
                children: [
                  {
                    title: t('governmentServiceEducation.individuals'),
                    dataIndex: 'eFormServiceProviderInfoDirContactManualPhys',
                    key: 'eFormServiceProviderInfoDirContactManualPhys',
                    align: 'center',
                    className: 'fizColumn',
                    render: (_, record) => record.eFormServiceProviderInfoDirContactManualPhys || 0
                  },
                  {
                    title: t('governmentServiceEducation.legalEntities'),
                    dataIndex: 'eFormServiceProviderInfoDirContactManualJur',
                    key: 'eFormServiceProviderInfoDirContactManualJur',
                    align: 'center',
                    className: 'yrColumn',
                    render: (_, record) => record.eFormServiceProviderInfoDirContactManualJur || 0
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: t('governmentServiceEducation.justifiedRefusals'),
        key: 'test3',
        rowSpan: 2,
        className: 'refusalColumn',
        children: [
          {
            children: [
              {
                title: t('governmentServiceEducation.electronicForm'),
                dataIndex: 'eJustifiedRefusalCount',
                align: 'center',
                className: 'refusalColumn summaryColumn',
                rowSpan: 2,
                render: (_, record) => record.eJustifiedRefusalCount || 0
              },
              {
                title: t('governmentServiceEducation.paperFormShort'),
                dataIndex: 'paperJustifiedRefusalCount',
                align: 'center',
                className: 'refusalColumn summaryColumn',
                rowSpan: 2,
                render: (_, record) => record.paperJustifiedRefusalCount || 0
              },
            ],
          },
        ],
      },
      {
        title: t('governmentServiceEducation.unjustifiedRefusals'),
        key: 'test4',
        rowSpan: 2,
        className: 'refusalColumn',
        children: [
          {
            children: [
              {
                title: t('governmentServiceEducation.electronicForm'),
                dataIndex: 'eUnjustifiedRefusalCount',
                align: 'center',
                className: 'refusalColumn summaryColumn',
                rowSpan: 2,
                render: (_, record) => record.eUnjustifiedRefusalCount || 0
              },
              {
                title: t('governmentServiceEducation.paperFormShort'),
                dataIndex: 'paperUnjustifiedRefusalCount',
                align: 'center',
                className: 'refusalColumn summaryColumn',
                rowSpan: 2,
                render: (_, record) => record.paperUnjustifiedRefusalCount || 0
              },
            ],
          },
        ],
      },
    ],
    2: [
      {
        title: '№ п/п',
        dataIndex: 'uniqId',
        key: 'uniqId',
        width: 150,
      },
      {
        title: t('governmentServiceEducation.activities'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: t('governmentServiceEducation.quantity'),
        dataIndex: 'count',
        key: 'count',
        align: 'center',
        width: 120,
      },
      {
        title: 'Вложения',
        dataIndex: 'files',
        key: 'files',
        align: 'center',
        width: 140,
        render: (_, record) => {
          if (!Array.isArray(record.files) || record.files.length === 0) {
            return '-';
          }

          return record.files.map(({ file, id }, index, array) => (
            <React.Fragment key={id}>
              <a onClick={() => downloadFile(id)} style={{ cursor: "pointer" }}>
                {file.name}
              </a>
              {loadingFile === id && <Spin size="small" style={{ marginLeft: '10px' }} />}
              {index !== array.length - 1 && ", "}
            </React.Fragment>
          ));
        }
      },
      {
        title: 'Инфо',
        dataIndex: 'infos',
        key: 'infos',
        align: 'center',
        width: 140,
        render: (_, record) => {
          if (!Array.isArray(record.infos) || record.infos.length === 0) {
            return '-';
          }

          return record.infos.map(({ id, info }, index, array) => (
            <React.Fragment key={id}>
              <a href={info} target="_blank" rel="noopener noreferrer">
                {info}
              </a>
              {index !== array.length - 1 && ", "}
            </React.Fragment>
          ));
        }
      },
    ],
    3: [
      {
        title: '№',
        dataIndex: '№',
      },
      {
        title: t('governmentServiceEducation.serviceProviderName'),
        dataIndex: 'providerOrganization',
        width: 200,
        render: (_, record) => record.providerOrganization.nameRu || ''
      },
      {
        title: t('governmentServiceEducation.serviceTypesCount'),
        dataIndex: 'publicServiceTypeAmount',
        width: 200,
      },
      {
        title: t('governmentServiceEducation.employeeFullInfo'),
        dataIndex: 'employeeFullName',
        width: 200,
      },
      {
        title: t('governmentServiceEducation.qualificationYear'),
        dataIndex: 'academyQualificationYear',
        width: 200,
      },
      {
        title: t('governmentServiceEducation.contactDetails'),
        dataIndex: 'employeeContactInfo',
        width: 200,
      },
      {
        title: t('governmentServiceEducation.techSpecs'),
        children: [
          {
            title: t('governmentServiceEducation.baseClock'),
            dataIndex: 'baseClockFrequencyGHz',
            width: 200,
          },
          {
            title: t('governmentServiceEducation.cores'),
            dataIndex: 'coreCountGHz',
            width: 200,
          },
          {
            title: t('governmentServiceEducation.ram'),
            dataIndex: 'ramGb',
            width: 200,
          },
        ]
      },
    ],
  };
  return COLUMNS[type] || [];
}

export const REPORTS_TABLE = ({
   handleRowClick,
   handleClickReportEdit,
   handleClickReportDelete,
   handleClickReportApprove,
   setModalApprove,
   setModalFinalize,
   getComment,
   setRecordForFinalize,
   setCommentId,
   getColumnSearchProps,
   t,
   styles,
 }) => [
  {
    title: t('intersections.reportName'),
    dataIndex: 'reportName',
    key: 'reportName',
    align: 'center',
    width: 250,
    render: (text, record) => (
      <span className={styles.reportTitle} onClick={handleRowClick(record)}>
        {text}
      </span>
    ),
    ...getColumnSearchProps('reportName', (record) => record.reportName),
  },
  {
    title:  t('configuringUsers.region'),
    dataIndex: 'district',
    key: 'district',
    align: 'center',
    width: 150,
    ...getColumnSearchProps('district', (record) => record.district),
  },
  {
    title: t('intersections.settlement'),
    dataIndex: 'locality',
    key: 'locality',
    align: 'center',
    width: 150,
    ...getColumnSearchProps('locality', (record) => record.locality.nameRu),
    render: (_, record) => record.locality.nameRu || 0,
  },
  {
    title: t('userActions.organization'),
    dataIndex: 'organization',
    key: 'organization',
    align: 'center',
    width: 150,
    ...getColumnSearchProps('organization', (record) => record.organization.nameRu),
    render: (_, record) => record.organization.nameRu || 0,
  },
  {
    title: t('userActions.year'),
    dataIndex: 'year',
    key: 'year',
    align: 'center',
    width: 100,
    ...getColumnSearchProps('year', (record) => record.year.year),
    render: (_, record) => record.year.year || 0,
  },
  {
    title: t('userActions.month'),
    dataIndex: 'month',
    key: 'month',
    align: 'center',
    width: 100,
    ...getColumnSearchProps('month', (record) => record.month.nameRu),
    render: (_, record) => record.month.nameRu || 0,
  },
  {
    title: t('intersections.creationDate'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'center',
    width: 200,
    sorter: (a, b) =>
      moment(a.createdAt, "DD.MM.YYYY").toDate() -
      moment(b.createdAt, "DD.MM.YYYY").toDate(),
  },
  {
    title: t('intersections.status'),
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    width: 150,
    ...getColumnSearchProps('status', (record) => t(`statuses.${record.status}`)),
    render: (_, record) => t(`statuses.${record.status}`) || 0,
  },
  {
    title: t('roadRepairPlan.action'),
    render: (_, record) => (
      <div>
        {
          //record.reportName !== 'Отчет по мероприятиям' - временно
          record.status === 'CREATED' && (
            <span id="edit" className={styles.tableButton} onClick={handleClickReportEdit(record)}>
              {t('intersections.edit')}
            </span>
          )
        }
        <Popconfirm
          title={`
            ${t('intersections.exactlyWantToDelete')} ${
            record.organization.nameRu && record.month.nameRu && record.year.year
              ? ' ' + record.organization.nameRu + ' за ' + record.month.nameRu + ' ' + record.year.year
              : ''
          } ?`}
          onConfirm={handleClickReportDelete(record)}
          okText="Да"
          onCancel={(e) => e.stopPropagation()}
          cancelText={t('intersections.cancellation')}
        >
          <a href="#" className={styles.tableButton}>
            {t('intersections.delete')}
          </a>
        </Popconfirm>
        {
          !["APPROVED", "REJECTED"].includes(record.status) && (
            <Popconfirm
              title={t('warning.WARN06')}
              onConfirm={handleClickReportApprove(record)}
              okText="Да"
              onCancel={() => {
                setModalApprove(true)
                setCommentId(record.id)
              }
              }
              cancelText={t('intersections.reject')}
            >
              <a href="#" className={styles.tableButton}>
                {t('intersections.approve')}
              </a>
            </Popconfirm>
          )
        }
        {
          ["REJECTED"].includes(record.status) && record.reportName !== 'Отчет по мероприятиям' && (
            <a
              href="#"
              className={styles.tableButton}
              onClick={(e) => {
                e.preventDefault();
                setModalFinalize(true);
                getComment(record.id)
                setRecordForFinalize(record)
              }}
            >
              {t('intersections.finalize')}
            </a>
          )
        }
      </div>
    ),
  },
];


export const LEGAL_ENTITIES = [
  '203006',
  '203006/1',
  '203006/2',
  '404001',
  '701001',
  '701003',
  '701004',
  '704002',
  '704004',
  '704005',
  '704005/1',
  '704005/2',
  '704005/3',
  '704008',
  '705002',
  '705003',
  '705004',
  '705005',
  '705006',
  '705007',
  '705008',
  '705009',
  '706002',
  '706004',
  '2701001',
  '201004',
  '201004/1',
  '201004/2',
  '201005',
  '201005/1',
  '201005/2',
  '201006',
  '201006/1',
  '201006/2',
  '201007',
  '201007/1',
  '201007/2',
  '201007/3',
  '201007/4',
  '201007/5',
  '201007/6',
  '201007/7',
  '201007/8',
  '201007/9',
  '201007/10',
  '201007/11',
  '201013',
  '201013/1',
  '201013/2',
  '401001',
  '401001/1',
  '401001/2',
  '401001/3',
  '401002',
  '401002/1',
  '401002/2',
  '402001',
  '402001/1',
  '402001/2',
  '2501006',
  '2501006/1',
  '2501006/2',
  '2501006/3',
  '2501006/4',
  '2501006/5',
  '2501006/6',
  '2501006/7',
  '2501006/8',
  '2501006/9',
  '2501006/10',
  '2501006/11',
  '2501006/12',
  '2501006/13',
  '2501009',
  '102002',
  '102002/1',
  '102002/2',
  '102002/3',
  '102002/4',
  '2701002',
  '2701003',
  '2701005',
  '704006',
  '704006/1',
  '704006/2',
  '704006/3',
  '201014',
  '201014/1',
  '201014/2',
  '201014/3',
  '201014/4',
  '201014/5',
  '201014/6',
  '201014/7',
  '201014/8',
  '201014/9',
  '201014/10',
  '201014/11',
  '201014/12',
  '201014/13',
  '201015',
  '201015/1',
  '201015/2',
  '201015/3',
  '201015/4',
  '201015/5',
  '201015/6',
  '201015/7',
  '402002',
  '402003',
  '402004',
  '402006',
  '402007',
  '402010',
  '402013',
  '403001',
  '403002',
  '403003',
  '403004',
  '403005',
  '403005/1',
  '403005/2',
  '403006',
  '403007',
  '403008',
  '403009',
  '403010',
  '403011',
  '403012',
  '403015',
  '404002',
  '404003',
  '404004',
  '404006',
  '404007',
  '803002',
  '803005',
  '803006',
  '803007-1',
  '803008',
  '803009',
  '803013',
  '803013/1',
  '803013/2',
  '803014',
  '803016',
  '801012',
  '403013',
  '403013/1',
  '403013/2',
  '403013/3',
  '403014',
  '803005',
  '803007-1',
  '803014',
  '2501005',
  '2501005/1',
  '2501005/2',
  '2501005/3',
  '2501005/4',
  '2501005/5',
  '2501005/6',
  '2501005/7',
  '2501005/8',
  '2501005/9',
  '2501005/10',
  '2501009',
  '201011',
  '201011/1',
  '201011/2',
  '201011/3',
  '201011/4',
  '201011/5',
  '701002',
  '701002/1',
  '701002/2',
  '2302002',
];

export const LISTS_SERVICE_PROVIDER_FOR_EXPORT = {
  'Акимат города Кокшетау':	'Кокшетау',
  'Акимат города Степногорск':	'Степногорск',
  'Акимат Аккольского района':	'Акколь',
  'Акимат Аршалынского района':	'Аршалы',
  'Акимат Астраханского района':	'Астрахань',
  'Акимат Атбасарского района':	'Атбасар',
  'Акимат района Биржан сал': 'Биржан сал',
  'Акимат Есильского района':	'Есиль',
  'Акимат Жаксынского района':	'Жаксы',
  'Акимат Жаркаинского района':	'Жаркаин',
  'Акимат Зерендинского района':	'Зеренда',
  'Акимат Коргалжынского района':	'Коргалжын',
  'Акимат Сандыктауского района':	'Сандыктау',
  'Акимат Целиноградского района':	'Целиноград',
  'Акимат Шортандинского района':	'Шортанды',
  'Акимат Бурабайского района':	'Бурабай',
  'Акимат Егиндыкольского района':	'Егиндыколь',
  'Акимат Буландынского района':	'Буланды',
  'Акимат Ерейментауского района':	'Ерейментау',
  'Акимат города Косшы':	'Косшы',
  'Управление ветеринарии Акмолинской области':	'ВЕТЕРИНАР',
  'Управление сельского хозяйства и земельных отношений Акмолинской области':	'СЕЛЬХОЗ',
  'Управление координации занятости и социальных программ Акмолинской области':	'ЗАНЯТОСТЬ',
  'Управление образования Акмолинской области':	'ОБРАЗОВАНИЕ',
  'Управление туризма Акмолинской области': 'ТУРИЗМ',
  'Управления предпринимательства и промышленности Акмолинской области': 'ПРЕДПРИНИМАТЕЛЬСТВО',
  'Управление природных ресурсов и регулирования природопользования Акмолинской области':	'ПРИРОДНЫЕ РЕСУРСЫ',
  'Управление пассажирского транспорта и автомобильных дорог Акмолинской области': 'ПАССАЖИРСКИЙ ТРАНСПОРТ',
  'Управление культуры Акмолинской области': 'КУЛЬТУРА',
  'Управление по делам религии Акмолинской области': 'РЕЛИГИЯ',
  'Управление строительства, архитектуры и градостроительства Акмолинской области':'АРХИТЕКТУРА',
  'Управление здравоохранения Акмолинской области':'ЗДРАВООХРАНЕНИЕ',
  'Управление государственного архитектурно-строительного контроля Акмолинской области':'ГАСК',
  'Управление цифровизации и архивов Акмолинской области':'ЦИФРОВИЗАЦИЯ',
  'Управление физической культуры и спорта Акмолинской области':'СПОРТ',
};