import bcrypt from 'bcryptjs';

export function check(modules, path) {
  var check = false;
  var checkModules = modules ? true : false;
  if (checkModules) {
    for (const modul of modules) {
      if (bcrypt.compareSync(path, modul)) {
        check = true;
      }
    }
  }
  return checkModules ? check : false;
}
