import React, { useEffect, useState } from 'react';
import { Select, Button } from 'antd';
import useFiltersChart from './hooks/useFiltersChart';
import styles from './FiltersChart.module.scss';
import { getNetwork } from '../../../../../../utils/getNetwork';
import { API_AUTH } from '../../../../../../config';

const { Option } = Select;

export const FiltersChart = ({
  formValues,
  formData,
  setReports,
  setCreatedReports,
  setFormValues,
  isListReport,
  setIsListReport,
  setLoading,
  setIsConsolidatedReport,
  isModalOpen,
  requestCreatedReadings,
}) => {
  const {
    handleChangeChartType,
    handleChangeDistrict,
    handleChangeOrg,
    handleChangeLocality,
    handleChangeDateSelect,
    getChartDataWithFilters,
    handleClickResetButton,
  } = useFiltersChart({
    formValues,
    isModalOpen,
    setReports,
    setCreatedReports,
    setFormValues,
    isListReport,
    setIsListReport,
    requestCreatedReadings,
    setLoading,
    setIsConsolidatedReport,
  });

  const [locality, setLocality] = useState();
  const [orgs, setOrgs] = useState();

  useEffect(() => {
    if (!formValues.district) {
      setLocality(null);
      setOrgs(null);
      return;
    }
    const fetchData = async () => {
      try {
        const [localityRes, orgsRes] = await Promise.all([
          getNetwork().get(`${API_AUTH}/get/locality/${formValues.district.id}`),
          getNetwork().get(`${API_AUTH}/get/organization/${formValues.district.id}`)
        ]);
        setLocality(localityRes.data);
        setOrgs(orgsRes.data);
      } catch (error) {
        console.error("Ошибка при загрузке данных:", error);
      }
    };
    fetchData();
  }, [formValues.district]);

  return (
    <div className={styles.container}>
      <Select
        className={`${styles.select} ${styles.maxSelect}`}
        placeholder="Наименование отчёта"
        value={formValues.chartType}
        onChange={handleChangeChartType}
        allowClear
      >
        {formData.chartTypes.map(e => (
          <Option key={e.id} value={e.id}>
            {e.name}
          </Option>
        ))}
      </Select>
      <Select
        className={`${styles.select} ${styles.maxSelect}`}
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        placeholder="Регион"
        value={formValues.district && formValues.district.id}
        onChange={(value) =>
          handleChangeDistrict(formData.district.find((el) => el.id === value))
        }
        allowClear
      >
        {formData.district.map(el => (
          <Option key={el.id} value={el.id}>
            {el.nameRu}
          </Option>
        ))}
      </Select>
      <Select
        className={`${styles.select} ${styles.maxSelect}`}
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        placeholder="Населенный пункт"
        value={formValues.locality}
        onChange={handleChangeLocality}
        allowClear
      >
        {(locality || formData.locality).map(({ id, nameRu }) => (
          <Option key={id} value={id}>
            {nameRu}
          </Option>
        ))}
      </Select>
      <Select
        className={`${styles.select} ${styles.maxSelect}`}
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        placeholder="Организация"
        value={formValues.org}
        onChange={handleChangeOrg}
        allowClear
      >
        {(orgs || formData.orgs).map(({ id, nameRu }) => (
          <Option key={id} value={id}>
            {nameRu}
          </Option>
        ))}
      </Select>

      <Select
        className={`${styles.select} ${styles.minSelect}`}
        placeholder="Год"
        value={formValues.year}
        onChange={handleChangeDateSelect('year')}
        allowClear
      >
        {formData.years.map(el => (
          <Option key={el.id} value={el.id}>
            {el.year}
          </Option>
        ))}
      </Select>
      <Select
        className={`${styles.select} ${styles.minSelect}`}
        placeholder="Месяц"
        value={formValues.month}
        onChange={handleChangeDateSelect('month')}
        allowClear
      >
        {formData.months.map(elem => {
          if (!formValues.quarter || formValues.quarter === elem.quarter) {
            return (
              <Option key={elem.id} value={elem.id}>
                {elem.nameRu}
              </Option>
            );
          }
        })}
      </Select>
      <Button
        className={styles.searchButton}
        type="primary"
        onClick={getChartDataWithFilters}
        disabled={!formValues.chartType || !formValues.year || !formValues.month}
      >
        Найти
      </Button>
      <Button onClick={handleClickResetButton}>Сбросить</Button>
    </div>
  );
};
