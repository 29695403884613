import { useCallback, useEffect } from 'react';
import { getNetwork } from '../../../../../../../../utils/getNetwork';
import { API_ROOT } from '../../../../../../../../config';
import { combineSum } from '../../../../../../utils';

const useFiltersChart = ({
  formValues,
  isModalOpen,
  setReports,
  setCreatedReports,
  setFormValues,
  isListReport,
  setIsListReport,
  requestCreatedReadings,
  setLoading,
  setIsConsolidatedReport,
}) => {
  const withSummary = useCallback(
    (data) => {
      return combineSum(data, formValues.chartType).map((i) => ({
        ...i,
        summaryFiz:
          i.electronicalyThroughtInformationSystemsFiz +
          i.inPeperFormFiz +
          i.serviceCountByStateorgCompaniesFiz +
          i.serviceCountByStateorgPeopleFiz +
          i.throughTheServiceProviderFiz,
        summaryYr:
          i.electronicalyThroughtInformationSystemsYr +
          i.inPeperFormYr +
          i.serviceCountByStateorgCompaniesYr +
          i.serviceCountByStateorgPeopleYr +
          i.throughTheServiceProviderYr,
      }));
    },
    [formValues.chartType]
  );

  const handleClickResetButton = () => {
    setReports([]);
    setCreatedReports([]);
    setFormValues({
      chartType: null,
      org: null,
      district: null,
      year: null,
      month: null,
      quarter: null,
    });
    setIsListReport(true);
    requestCreatedReadings();
  };

  const handleChangeDateSelect = (name) => (value) => {
    setReports([]);
    setFormValues({
      ...formValues,
      month: name === 'quarter' ? null : formValues.month,
      [name]: value ? value : null,
    });
  };

  const handleChangeOrg = (value) => {
    setReports([]);
    setFormValues((prev) => ({
      ...prev,
      org: value ? value : null,
    }));
  };

  const handleChangeChartType = (value) => {
    setReports([]);
    setFormValues((prev) => ({ ...prev, chartType: value }));
  };

  const handleChangeDistrict = (value) => {
    setReports([]);
    setFormValues((prev) => ({
      ...prev,
      district: value ? value : null,
    }));
  };

  const getChartData = useCallback(() => {
    if (!formValues.chartType) return;
    setLoading(true);
    const queryFields = [
      { name: 'district', value: formValues.district },
      { name: 'organizationId', value: formValues.org },
      { name: 'yearId', value: formValues.year },
      { name: 'monthId', value: formValues.month },
      { name: 'quarter', value: formValues.quarter },
    ];
    const queryParam = new URLSearchParams();

    queryFields.forEach((query) => {
      if (query.value) {
        queryParam.set(query.name, query.value);
      }
    });
    getNetwork()
      .get(API_ROOT + `/myReport/search/byReport${formValues.chartType}?${queryParam}`)
      .then((res = {}) => {
        if (res.status === 200) {
          const result = res.data;
          const dataWithSum = formValues.chartType === 3 ? result : withSummary(result.data);
          setReports(dataWithSum);
          setIsConsolidatedReport(result.consolidatedReport);
        }
      })
      .finally(() => {
        setLoading(false);
        setIsListReport(false);
      });
  }, [formValues, withSummary, setIsConsolidatedReport, setLoading, setIsListReport, setReports]);

  useEffect(() => {
    if (formValues && isModalOpen) {
      getChartData();
      setIsListReport(true);
    }
  }, [isModalOpen, formValues, getChartData, setIsListReport]);
  return {
    getChartData,
    handleChangeChartType,
    handleChangeDistrict,
    handleChangeOrg,
    handleChangeDateSelect,
    handleClickResetButton,
  };
};

export default useFiltersChart;
