export const EDITABLE_COLUMNS = {
  1: [
    "eFormServiceProviderInfoDirContactManualJur",
    "eFormServiceProviderInfoDirContactManualPhys",
    "eGovernmentPortalJur",
    "eGovernmentPortalPhys",
    "eJustifiedRefusalCount",
    "eServiceProviderInfoNoContactJur",
    "eServiceProviderInfoNoContactPhys",
    "eUnjustifiedRefusalCount",
    "governmentForCitizenJur",
    "governmentForCitizenPhys",
    "paperFormJur",
    "paperFormPhys",
    "paperJustifiedRefusalCount",
    "paperUnjustifiedRefusalCount",
    "totalGovServicesProvidedJur",
    "totalGovServicesProvidedPhys"
  ],
  2: ['count'],
  3: [
    'employeeFullName',
    'academyQualificationYear',
    'employeeContactInfo',
    'baseClockFrequencyGHz',
  ],
};

export const EDITABLE_LIST_SERVICE_CODES = ['2302002'];