import { combineReducers } from 'redux';
import user from './user';
import module from './module';
import reportCreationDictionaries from './reportCreationDictionaries';
import reportSummaryDictionaries from './reportSummaryDictionaries';
import reportSummary from './reportSummary';
import reportCreation from './reportCreation';
import currentUser from './currentUser';
import reportView from './reportView';

export default combineReducers({
  user,
  module,
  reportCreationDictionaries,
  reportSummaryDictionaries,
  reportSummary,
  currentUser,
  reportCreation,
  reportView,
});
