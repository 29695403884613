import { sortBy } from 'lodash';

export let arrCategoryNumber = [];
export let arrChapterNumber = [];

const toFlatList = (data = []) => {
  return data.reduce((arr, el) => {
    if (Array.isArray(el.children)) {
      return [...arr, el, ...toFlatList(el.children)];
    } else {
      return [...arr, el];
    }
  }, []);
};

const getChartType1Data = (el, index) => [
  index + 1,
  el.service.code,
  el.service.name,
  el.totalGovServicesProvidedPhys || 0,
  el.totalGovServicesProvidedJur || 0,
  el.governmentForCitizenPhys || 0,
  el.governmentForCitizenJur || 0,
  el.eGovernmentPortalPhys || 0,
  el.eGovernmentPortalJur || 0,
  el.paperFormPhys || 0,
  el.paperFormJur || 0,
  el.eServiceProviderInfoNoContactPhys || 0,
  el.eServiceProviderInfoNoContactJur || 0,
  el.eFormServiceProviderInfoDirContactManualPhys || 0,
  el.eFormServiceProviderInfoDirContactManualJur || 0,
  el.eJustifiedRefusalCount || 0,
  el.paperJustifiedRefusalCount || 0,
  el.eUnjustifiedRefusalCount || 0,
  el.paperUnjustifiedRefusalCount || 0,
];

const getChartType3Data = (el, index) => [
  index + 1,
  el.providerOrganization.nameRu || '',
  el.publicServiceTypeAmount || 0,
  el.employeeFullName || '',
  el.academyQualificationYear || '',
  el.employeeContactInfo || '',
  el.baseClockFrequencyGHz || '',
  el.coreCountGHz || 0,
  el.ramGb || 0,
];

export const combineDataToXls = (data, chartType) => {
  switch (chartType) {
    case 1:
      return data.map(getChartType1Data);
    case 2:
      arrCategoryNumber = [];
      arrChapterNumber = [];
      return sortBy(toFlatList(data), [(o) => parseFloat(o.number)]).map((el) => {
        if (el.type === 'category') arrCategoryNumber.push(el.number);
        if (el.type === 'chapter') arrChapterNumber.push(el.number);
        return [el.number, el.subcategory, el.count];
      });
    case 3:
      return data.map(getChartType3Data);
    default:
      return [];
  }
};