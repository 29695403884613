import { Button } from 'antd';
import React from 'react';
import * as XlsxPopulate from 'xlsx-populate';

const combineDataToXls = (data) => {
  let xlsData = [];
  data.forEach((item) => {
    xlsData.push([
      item.number,
      item.transporter,
      item.binInn,
      item.fullnameHead,
      item.address,
      item.phoneNumber1,
      item.phoneNumber2,
      item.phoneNumber3,
      item.email
    ]);
  });
  return xlsData;
};

export const ExportTransporters = ({ fileName = 'excel', data }) => {
  function getWorkbook() {
    return new Promise(function (resolve, reject) {
      var req = new XMLHttpRequest();
      var url = '/xlsx/transporters.xlsx';
      req.open('GET', url, true);
      req.responseType = 'arraybuffer';
      req.onreadystatechange = function () {
        if (req.readyState === 4) {
          if (req.status === 200) {
            resolve(XlsxPopulate.fromDataAsync(req.response));
          } else {
            reject('Received a ' + req.status + ' HTTP code.');
          }
        }
      };

      req.send();
    });
  }

  function generate(type) {
    return getWorkbook().then(function (workbook) {
      workbook
        .sheet(0)
        .cell('A6')
        .value(combineDataToXls(data))
        .style({
          border: 'Borders',
          borderStyle: 'thin',
          fontFamily: 'Times New Roman',
          fontSize: 12,
          wrapText: true,
        });

      return workbook.outputAsync({ type: type });
    });
  }

  function generateBlob() {
    return generate()
      .then(function (blob) {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fileName + '.xlsx');
        } else {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          document.body.appendChild(a);
          a.href = url;
          a.download = fileName + '.xlsx';
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      })
      .catch(function (err) {
        alert(err.message || err);
        throw err;
      });
  }

  const exportToExcel = () => {
    generateBlob();
  };

  return (
    <Button
      onClick={exportToExcel}
      type='primary'
    >
      Экспорт в Excel
    </Button>
  );
};