import {
  SET_REPORT_NAMES,
  SET_DISTRICT,
  SET_ORGANIZATIONS,
  SET_YEAR,
  SET_MONTH,
  SET_LOCALITIES,
  LOAD_DICTIONARIES_START,
  LOAD_DICTIONARIES_SUCCESS,
  LOAD_DICTIONARIES_ERROR
} from '../constants/reportCreationDictionaries';

export const setReportNames = (payload) => ({
  type: SET_REPORT_NAMES,
  payload,
});

export const setDistrict = (payload) => ({
  type: SET_DISTRICT,
  payload,
});

export const setLocalities = (payload) => ({
  type: SET_LOCALITIES,
  payload,
});

export const setOrganizations = (payload) => ({
  type: SET_ORGANIZATIONS,
  payload,
});

export const setYear = (payload) => ({
  type: SET_YEAR,
  payload,
});

export const setMonth = (payload) => ({
  type: SET_MONTH,
  payload,
});

export const loadDictionariesStart = () => ({
  type: LOAD_DICTIONARIES_START
});

export const loadDictionariesSuccess = (payload) => ({
  type: LOAD_DICTIONARIES_SUCCESS,
  payload
});

export const loadDictionariesError = (error) => ({
  type: LOAD_DICTIONARIES_ERROR,
  payload: error
});

