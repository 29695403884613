export const physFieldsToSum = [
  'governmentForCitizenPhys',
  'paperFormPhys',
  'eGovernmentPortalPhys',
  'eServiceProviderInfoNoContactPhys',
  'eFormServiceProviderInfoDirContactManualPhys'
];

export const jurFieldsToSum = [
  'governmentForCitizenJur',
  'paperFormJur',
  'eGovernmentPortalJur',
  'eServiceProviderInfoNoContactJur',
  'eFormServiceProviderInfoDirContactManualJur'
];

export const LEGAL_ENTITIES = [
  '203006',
  '203006/1',
  '203006/2',
  '404001',
  '701001',
  '701003',
  '701004',
  '704002',
  '704004',
  '704005',
  '704005/1',
  '704005/2',
  '704005/3',
  '704008',
  '705002',
  '705003',
  '705004',
  '705005',
  '705006',
  '705007',
  '705008',
  '705009',
  '706002',
  '706004',
  '2701001',
  '201004',
  '201004/1',
  '201004/2',
  '201005',
  '201005/1',
  '201005/2',
  '201006',
  '201006/1',
  '201006/2',
  '201007',
  '201007/1',
  '201007/2',
  '201007/3',
  '201007/4',
  '201007/5',
  '201007/6',
  '201007/7',
  '201007/8',
  '201007/9',
  '201007/10',
  '201007/11',
  '201013',
  '201013/1',
  '201013/2',
  '401001',
  '401001/1',
  '401001/2',
  '401001/3',
  '401002',
  '401002/1',
  '401002/2',
  '402001',
  '402001/1',
  '402001/2',
  '2501006',
  '2501006/1',
  '2501006/2',
  '2501006/3',
  '2501006/4',
  '2501006/5',
  '2501006/6',
  '2501006/7',
  '2501006/8',
  '2501006/9',
  '2501006/10',
  '2501006/11',
  '2501006/12',
  '2501006/13',
  '2501009',
  '102002',
  '102002/1',
  '102002/2',
  '102002/3',
  '102002/4',
  '2701002',
  '2701003',
  '2701005',
  '704006',
  '704006/1',
  '704006/2',
  '704006/3',
  '201014',
  '201014/1',
  '201014/2',
  '201014/3',
  '201014/4',
  '201014/5',
  '201014/6',
  '201014/7',
  '201014/8',
  '201014/9',
  '201014/10',
  '201014/11',
  '201014/12',
  '201014/13',
  '201015',
  '201015/1',
  '201015/2',
  '201015/3',
  '201015/4',
  '201015/5',
  '201015/6',
  '201015/7',
  '402002',
  '402003',
  '402004',
  '402006',
  '402007',
  '402010',
  '402013',
  '403001',
  '403002',
  '403003',
  '403004',
  '403005',
  '403005/1',
  '403005/2',
  '403006',
  '403007',
  '403008',
  '403009',
  '403010',
  '403011',
  '403012',
  '403015',
  '404002',
  '404003',
  '404004',
  '404006',
  '404007',
  '803002',
  '803005',
  '803006',
  '803007-1',
  '803008',
  '803009',
  '803013',
  '803013/1',
  '803013/2',
  '803014',
  '803016',
  '801012',
  '403013',
  '403013/1',
  '403013/2',
  '403013/3',
  '403014',
  '803005',
  '803007-1',
  '803014',
  '2501005',
  '2501005/1',
  '2501005/2',
  '2501005/3',
  '2501005/4',
  '2501005/5',
  '2501005/6',
  '2501005/7',
  '2501005/8',
  '2501005/9',
  '2501005/10',
  '2501009',
  '201011',
  '201011/1',
  '201011/2',
  '201011/3',
  '201011/4',
  '201011/5',
  '701002',
  '701002/1',
  '701002/2',
  '2302002',
];