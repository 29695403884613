import {
  SET_FORM_SELECTORS_REPORT_DATA,
  SET_TABLE_REPORT_DATA,
  RESET_STATE,
  SET_IS_LOADING
} from '../constants/reportSummary';

export const setFormSelectorsReportData = (payload) => ({
  type: SET_FORM_SELECTORS_REPORT_DATA,
  payload
});

export const setTableReportData = (payload) => ({
  type: SET_TABLE_REPORT_DATA,
  payload
});

export const resetState = () => ({
  type: RESET_STATE
});

export const setIsLoading = (payload) => ({
  type: SET_IS_LOADING,
  payload
});