import { SET_FORM_SELECTORS_REPORT_DATA, SET_TABLE_REPORT_DATA, RESET_STATE, SET_IS_LOADING } from '../constants/reportSummary';

const initialState = {
  tableReportData: [],
  isSelectedFilters: false,
  isLoading: false,
  formSelectorsReportData: {
    reportType: '',
    reportKindId: null,
    regionIds: [],
    localityIds: [],
    organizationIds: [],
    orgTypeIds: [],
    monthIds: [],
    yearId: null,
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_FORM_SELECTORS_REPORT_DATA:
      return { ...state, formSelectorsReportData: action.payload, isSelectedFilters: true };
    case SET_TABLE_REPORT_DATA:
      return { ...state, tableReportData: action.payload };
    case RESET_STATE:
      return {
        ...state,
        formSelectorsReportData: initialState.formSelectorsReportData,
        isSelectedFilters: false,
        tableReportData: [],
      };
    case SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
}