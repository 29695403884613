import React, { useEffect, useState, useCallback } from 'react';
import ExportExcel from '../../ExportExcel';
import { groupData } from '../../../../../utils';
import { getNetwork } from '../../../../../../../utils/getNetwork';
import { API_ROOT } from '../../../../../../../config';
import { combineSum } from '../../../../../utils';
import styles from './ExcelButton.module.scss';

export const ExcelButton = ({ formData, formValues, reports, isConsolidatedReport }) => {
  const [separateReports, setSeparateReports] = useState([]);
  const getDataForExcelFormat = (arrData) => {
    if (formValues.chartType === 3) {  
      return groupData(arrData);
    }

    return arrData;
  };

  const generateTitleFile = (part, title, organizationResult, monthResult, yearResult) => {
    return title.replace(
      part,
      `${part} ${organizationResult
        ? organizationResult.organization : ''} ${monthResult && yearResult ? 'за ' + monthResult.month + ' ' + yearResult.year
        : ''}`
    ).replace(/[.,/#!$%^&*;:{}=\-_`~()]$/g, '');
  };

  const createTitleFile = (title) => {
    const organizationResult = formData.orgs.find((orgObj) => orgObj.id === formValues.org);
    const monthResult = formData.months.find((monthObj) => monthObj.id === formValues.month);
    const yearResult = formData.years.find((yearObj) => yearObj.id === formValues.year);

    if (title.includes('Отчет о работе')) {
      return generateTitleFile('Отчет о работе', title, organizationResult, monthResult, yearResult);
    }

    if (title.includes('Отчет о выполнении')) {
      return generateTitleFile('Отчет о выполнении', title, organizationResult, monthResult, yearResult);
    }

    return title;
  };

  const withSummary = useCallback(
    data => {
        return combineSum(data, formValues.chartType).map(i => ({
        ...i,
        summaryFiz:
          i.electronicalyThroughtInformationSystemsFiz +
          i.inPeperFormFiz +
          i.serviceCountByStateorgCompaniesFiz +
          i.serviceCountByStateorgPeopleFiz +
          i.throughTheServiceProviderFiz,
        summaryYr:
          i.electronicalyThroughtInformationSystemsYr +
          i.inPeperFormYr +
          i.serviceCountByStateorgCompaniesYr +
          i.serviceCountByStateorgPeopleYr +
          i.throughTheServiceProviderYr,
      }));
    },
    [formValues.chartType]
  );

  const getChartData = useCallback(() => {
    const queryFields = [
      { name: 'yearId', value: formValues.year },
      { name: 'monthId', value: formValues.month },
      { name: 'quarter', value: formValues.quarter },
    ];
    const queryParam = new URLSearchParams();

    queryFields.forEach(query => {
      if (query.value) {
        queryParam.set(query.name, query.value);
      }
    });

    getNetwork()
      .get(
        API_ROOT +
          `/myReport//filter-by-org?reportType=REPORT_${formValues.chartType}&${queryParam}`
      )
      .then((res = {}) => {
        if (res.status === 200) {
          const result = res.data;
          const dataWithSum = formValues.chartType === 3 ? result : result.map(d => withSummary(d.data));
          setSeparateReports(dataWithSum);
        }
      });
  }, [formValues]);

  useEffect(() => {
    if (isConsolidatedReport) {
      getChartData();
    }
  }, [isConsolidatedReport])

  return (
    <div className={styles.container}>
      <h3 className={styles.subtitle}>
        {createTitleFile(formData.chartTypes.find(i => i.id === formValues.chartType).reportTitle)}
      </h3>
      <ExportExcel
        fileName={createTitleFile(
          formData.chartTypes.find(i => i.id === formValues.chartType).reportTitle
        )}
        disabled={
          isConsolidatedReport ? separateReports.length === 0 || !reports.length : !reports.length
        }
        chartType={formValues.chartType}
        data={getDataForExcelFormat(reports)}
        separateData={separateReports}
        isConsolidatedReport={isConsolidatedReport}
      />
    </div>
  );
};
