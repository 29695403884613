import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import BasicLayout from '../../components/layout/BasicLayout';
import { Chart } from './tabs/Chart/Chart';
import { useTranslation } from 'react-i18next';
import { ReportBuilder } from './tabs/ReportBuilder';
import { ReportSummary } from './tabs/ReportSummary';

const { TabPane } = Tabs;

export const GovernmentServiceEducation = () => {
  const [isAction, setIsAction] = useState(false);
  const { t } = useTranslation();

  const ListTabs = () => (
    <Tabs defaultActiveKey='reportList'>
      <TabPane tab={t('gosServiceList.charts')} key="reportList">
        <Chart isAction={isAction} />
      </TabPane>
      <TabPane tab={t('gosServiceList.createChart')} key="reportBuilder">
        <ReportBuilder />
      </TabPane>
      <TabPane tab={t('gosServiceList.summaryChart')} key="reportSummary">
        <ReportSummary />
      </TabPane>
    </Tabs>
  );

  return (
    <BasicLayout>
      <ListTabs />
    </BasicLayout>
  );
};
