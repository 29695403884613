import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Ru from './Ru';
import Kz from './Kz';
const resources = {
  ru: Ru,
  kz: Kz,
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'ru',

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
