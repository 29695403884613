import React, { useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { loadOrganizations } from '../../../../../redux/asyncActions/reportSummaryDictionaries';

export const OrganizationSelect = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organizations = useSelector((state) => state.reportSummaryDictionaries.organizations);
  const form = Form.useFormInstance();
  const orgTypeIds = Form.useWatch('orgTypeIds', form);
  const [isLoading, setIsLoading] = useState(false);

  const organizationOptions = organizations.map((item) => ({
    label: item.nameRu,
    value: item.id
  }));

  useEffect(() => {
    if (orgTypeIds !== undefined && (orgTypeIds || []).length > 0) {
      dispatch(loadOrganizations(orgTypeIds, setIsLoading));
    }

    form.setFieldsValue({ organizationIds: [] });
  }, [dispatch, orgTypeIds, form]);

  return (
    <Form.Item
      name='organizationIds'
      rules={[{ required: true, message: t('error.requiredField') }]}
    >
      <Select
        mode="multiple"
        maxTagCount="responsive"
        disabled={orgTypeIds === undefined || orgTypeIds.length === 0 || organizations.length === 0 || isLoading}
        loading={isLoading}
        style={{ minWidth: 150 }}
        placeholder={t('governmentServiceEducation.organization')}
        options={organizationOptions}
      />
    </Form.Item>
  );
};