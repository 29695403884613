import { chain, isArray } from 'lodash';

const combineWithNumbers = (data, parentNumber = '') => {
  return data.map((obj, index) => {
    const number = `${parentNumber ? parentNumber + '.' : ''}${index + 1}`;
    if (isArray(obj.children)) {
      return {
        ...obj,
        uniqId: number,
        children: combineWithNumbers(obj.children, number),
      };
    }
    return {
      ...obj,
      uniqId: number,
      count: obj.count || 0,
    };
  });
};

export const groupData = (data) => {
  const withChapter = data.filter(
    (item) => item.eduActivity && item.eduActivity.chapter && !item.eduActivity.isDeleted
  );

  const groupedChapters = chain(withChapter)
    .groupBy((item) => item.eduActivity.chapter.id)
    .map((chapterItems) => {
      const firstItem = chapterItems[0];
      const chapter = firstItem.eduActivity.chapter;

      const categories = chain(chapterItems)
        .groupBy((item) => item.eduActivity.category.id)
        .map((categoryItems) => {
          const category = categoryItems[0].eduActivity.category;

          const subCategories = categoryItems.filter((item) => item.eduActivity.subCategory).map((item) => ({
            id: item.eduActivity.id,
            type: 'subCategory',
            name: item.eduActivity.subCategory.name,
            count: item.subCategoryCount,
          }));

          return {
            id: category.id,
            type: 'category',
            name: category.name,
            count: categoryItems[0].categoryCount,
            children: subCategories,
          };
        })
        .value();

      return {
        id: chapter.id,
        type: 'chapter',
        name: chapter.name,
        count: firstItem.chapterCount,
        children: categories,

      };
    })
    .value();

  const withoutChapter = data.filter(
    (item) => !(item.eduActivity && item.eduActivity.chapter)
  );

  const groupedWithoutChapter = chain(withoutChapter)
    .groupBy((item) => item.eduActivity.category.id)
    .map((categoryItems) => {
      const category = categoryItems[0].eduActivity.category;

      const subCategories = categoryItems.filter((item) => item.eduActivity.subCategory).map((item) => ({
        id: item.eduActivity.id,
        type: 'subCategory',
        name: item.eduActivity.subCategory.name,
        count: item.subCategoryCount,
      }));

      return {
        id: category.id,
        type: 'category',
        name: category.name,
        count: categoryItems[0].categoryCount,
        children: subCategories,
      };
    })
    .value();

  const groupedData = [...groupedChapters, ...groupedWithoutChapter];

  return combineWithNumbers(groupedData);
};