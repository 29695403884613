import React from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const DistrictSelect = () => {
  const { t } = useTranslation();
  const districts = useSelector((state) => state.reportSummaryDictionaries.districts);

  const districtOptions = districts.map((item) => ({
    label: item.nameRu,
    value: item.id
  }));

  return (
    <Form.Item
      name='regionIds'
      rules={[{ required: true, message: t('error.requiredField') }]}
    >
      <Select
        mode='multiple'
        maxTagCount='responsive'
        style={{ minWidth: 100 }}
        placeholder={t('governmentServiceEducation.reportSummaryDistrict')}
        filterOption={(input, option) =>
          option.label.toLowerCase().includes(input.toLowerCase())
        }
        optionFilterProp="label"
        options={districtOptions} />
    </Form.Item>
  );
};