import React, { useState } from 'react';
import { Button, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { getNetwork } from '../../../../../../../utils/getNetwork';
import { API_ROOT } from '../../../../../../../config';

export const EditUploadFile = ({ record, onUpdate, onRemove }) => {
  const { t } = useTranslation();
  const [fileList, setFileList] = useState(
    (record.files || []).map(f => ({
      uid: f.uid,
      name: f.file && f.file.name ? f.file.name : 'Без названия',
      status: 'done',
      id: f.file && f.file.id,

      type: record.type.toUpperCase(),
    })),
  );


  const hasFileError = false; // Убрал валидацию, если нужна – добавим позже
  const hasCountFileError = false; // Тоже самое

  const beforeUpload = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const { data } = await getNetwork().post(
        `${API_ROOT}/files/edu-activity-report`,
        formData,
      );


      const newFile = {
        uid: `${Date.now()}`,
        name: file.name,
        status: 'done',
        id: data.id,
        type: record.type.toUpperCase(),
      };

      setFileList(prev => {
        const updatedFiles = [newFile];
        onUpdate(record.uniqId, updatedFiles, record);
        return [...prev, newFile];
      });
    } catch (error) {
      message.error(t('error.uploadFailed'));
    }

    return false;
  };


  const handleRemove = (file) => {
    if (typeof onRemove === 'function') {
      setFileList((prev) => prev.filter((f) => f.uid !== file.uid));
      onRemove(file, record);
    } else {
      console.error('onRemove is not a function');
    }
  };

  return (
    <Upload
      multiple
      fileList={fileList}
      beforeUpload={beforeUpload}
      onRemove={handleRemove}
      accept=".pdf,.png,.jpeg"
      showUploadList
      maxCount={record.count}
    >
      <Button
        disabled={fileList.length >= record.count}
        icon={<UploadOutlined />}
        danger={hasFileError}
      >
        {t('governmentServiceEducation.uploadFiles')}
      </Button>
      {hasCountFileError && (
        <div style={{ color: 'red' }}>
          {t('governmentServiceEducation.maxFiles', { maxFiles: record.count })}
        </div>
      )}
    </Upload>
  );
};
