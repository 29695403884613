import { getNetwork } from '../utils/getNetwork';
import { API_ROOT } from '../config';

export const fetchReportNames = () => getNetwork().get(`${API_ROOT}/edu-report/consolidated/kind`);
export const fetchDistricts = () => getNetwork().get(`${API_ROOT}/edu-report/consolidated/district`);
export const fetchLocalities = (listId, cancelTokenSource) =>
  getNetwork().get(`${API_ROOT}/edu-report/consolidated/locality?regionIds=${listId}`,
    {
      cancelToken: cancelTokenSource.token,
    });
export const fetchOrganizations = (listId, cancelTokenSource) =>
  getNetwork().get(`${API_ROOT}/edu-report/consolidated/org?orgTypeIds=${listId}`, {
    cancelToken: cancelTokenSource.token,
  });

export const fetchOrganizationTypes = () => getNetwork().get(`${API_ROOT}/edu-report/consolidated/org/type`);
export const fetchYears = () => getNetwork().get(`${API_ROOT}/edu-report/consolidated/year`);
export const fetchMonths = () => getNetwork().get(`${API_ROOT}/edu-report/consolidated/month`);