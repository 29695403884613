import React, { useState, useEffect, useMemo } from 'react';
import { Button, Table, message, Spin, Input } from 'antd';
import styles from '../tabsStyles.module.scss';
import { getNetwork } from '../../../../../utils/getNetwork';
import { API_ROOT } from '../../../../../config';
import { useTranslation } from 'react-i18next';
import { ModalSettlements } from './components/ModalSettlements/ModalSettlements';

const { Search } = Input;

export const CreateDirectoryRouteTab = ({ request }) => {
  const { t } = useTranslation();
  const [isModal, setIsModal] = useState(false);
  const [settlements, setSettlements] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState(null);

  const columns = useMemo(() => {
    const handleClickChangeSettleventsButton = (record) => () => {
      const districtValue = districtData.find((item) => item.district === record.district);

      setInitialFormValues({
        id: record.id,
        latitude: `${record.latitude}`,
        longitude: `${record.longitude}`,
        regionId: districtValue ? districtValue.id : null,
        settlements: record.settlements
      });

      setIsModal(true);
    };
    return [
      {
        title: '№',
        dataIndex: 'number',
        width: 50,
      },
      {
        title: 'Район',
        dataIndex: 'district',
      },
      {
        title: 'Населенные пункты',
        dataIndex: 'settlements',
      },
      {
        title: 'Широта',
        dataIndex: 'latitude',
        align: 'center',
        render: (text) => (
          <span>{text || '–'}</span>
        )
      },
      {
        title: 'Долгота',
        dataIndex: 'longitude',
        align: 'center',
        render: (text) => (
          <span>{text || '–'}</span>
        )
      },
      {
        title: 'Действие',
        render: (_, record) => <Button type="link" onClick={handleClickChangeSettleventsButton(record)}>Редактировать</Button>
      },
    ];}, [districtData]);


  const requestGetSettlements = async () => {
    setIsLoading(true);
    const result = await getNetwork().get(API_ROOT + '/passangers/settlements');

    if (result.status === 200) {
      setSettlements(result.data);
      setSearchData(result.data);
    }

    if (result.status >= 500) {
      message.error('Сервер временно недоступен, попробуйте позже');
    }
    setIsLoading(false);
  };

  const requestGetDistrict = async () => {
    const result = await getNetwork().get(API_ROOT + '/passangers/regions');

    if (result.status === 200) {
      setDistrictData(result.data);
    }
  };

  const handleClickAddRoute = () => {
    setIsModal(true);
  };

  const onSearch = (value) => {
    setSearchValue(value);
    setSearchData(settlements.filter((item) => item.settlements.toLowerCase().includes(value.toLowerCase())));
  };

  useEffect(() => {
    requestGetSettlements();
    requestGetDistrict();
  }, []);

  return (
    <>
      <Button
        className={styles.addButton}
        onClick={handleClickAddRoute}
        type='primary'
        disabled={!districtData.length}
      >
        Добавить населенный пункт
      </Button>
      <Search
        className={styles.search}
        defaultValue={searchValue}
        placeholder="Поиск по населенным пунктам"
        onSearch={onSearch}
        enterButton
      />
      <div className={styles.box}>
        <Spin
          className={styles.spin}
          tip={t('intersections.loadingPleaseWait') + '...'}
          spinning={isLoading}
        >
          <Table sticky size='middle' columns={columns} dataSource={searchData.map((item, index) => ({
            ...item,
            number: index + 1
          }))} />
        </Spin>
      </div>
      <ModalSettlements
        isModal={isModal}
        setIsModal={setIsModal}
        district={districtData}
        initialFormValues={initialFormValues}
        setInitialFormValues={setInitialFormValues}
        requestGetSettlements={requestGetSettlements}
        request={request}
      />
    </>
  );
};
