import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

export const DropdownRender = ({ menu, handleSelectAll, formIds, dictionaryIds }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div
        className='dropdown-render'
        onClick={handleSelectAll}
      >
        {formIds && formIds.length === dictionaryIds.length
          ? t('governmentServiceEducation.deselectAll')
          : t('governmentServiceEducation.selectAll')}
      </div>
      {menu}
    </div>
  );
};
