import { chain, isArray } from 'lodash';

export const combineWithNumbers = (data, parentNumber = '') => {
  return data.map((obj, index) => {
    const number = `${parentNumber && parentNumber + '.'}${index + 1}`;
    if (isArray(obj.children)) {
      return {
        ...obj,
        key: number,
        number: number,
        children: combineWithNumbers(obj.children, number),
      };
    } else return { ...obj, key: number, number: number, count: obj.count || 0 };
  });
};

export const getGroupedData = (data, groupType) =>
  chain(data)
    .groupBy((item) => {
      if (groupType === 'chapter') {
        return item.chapter && item.chapter.name;
      }
      if (groupType === 'category') {
        return item.category && item.category.name;
      }
      if (groupType === 'subCategory') {
        return item.subCategory && item.subCategory.name;
      }
      return undefined;
    })
    .map((value, key) => {
        if (groupType === 'category') {
          return ({
            count: value.reduce((sum, cur) => sum += cur.subCategoryCount, 0),
            type: groupType,
            subcategory: key,
            subcategoryId: value[0].subcategoryId,
            children: value,
            files: value.flatMap((item) => item.files || []).filter((file) => file.type === 'CATEGORY'),
          });
        }
        if (groupType === 'subCategory') {
          return ({
            count: value.reduce((sum, cur) => sum += cur.subCategoryCount, 0),
            type: groupType,
            subcategory: key,
            subcategoryId: value[0].subcategoryId,
            files: value.flatMap((item) => item.files || []).filter((file) => file.type === 'SUBCATEGORY'),
            infos: Array.from(
              new Map(value.flatMap((item) => item.infos || []).map((info) => [info.id, info])).values()
            ),
          });
        }
      return ({
          count: value.reduce((sum, cur) => sum += cur.subCategoryCount, 0),
          type: groupType,
          subcategory: key,
          children: value,
          files: value.flatMap((item) => item.files || []).filter((file) => file.type === 'CHAPTER'),
        });
      }
    )
    .value();

export const groupData = (data) => {
  const newData = data.map(it => {
    return {
      ...it,
      ...it.eduActivity,
    }
  })
  const filteredData = newData.filter((item) => item.chapter && item.chapter.name);

  const groupedChapter = getGroupedData(
    filteredData.filter((item) => item.nameOfService !== 'Итого'),
    'chapter'
  );

  const groupedCategory = groupedChapter.map((chapter) => {
    return {
      ...chapter,
      children: getGroupedData(chapter.children, 'category'),
    };
  });

  const groupedSubcategory = groupedCategory.map((chapter) => {
    return ({
      ...chapter,
      children: chapter.children.map((category) => {
        return ({
          ...category,
          children: category.children ? getGroupedData(category.children, 'subCategory') : null,
        });
      }),
    });
  });

  return combineWithNumbers(groupedSubcategory);
};
