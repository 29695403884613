import { SET_CURRENT_USER } from '../constants/currentUser';

const initialState = {
  id: null,
  login: null,
  password: null,
  fullname: null,
  districts: [],
  localities: [],
  organizations: [],
  phone: null,
  role: null,
  roles: [],
};



export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return { ...action.payload };
    default:
      return state;
  }
}