import React, { useEffect } from 'react';
import { Button, Modal, Select, Form, Input, InputNumber, message } from 'antd';
import { getNetwork } from '../../../../../../../utils/getNetwork';
import { API_ROOT } from '../../../../../../../config';
import styles from './ModalSettlements.module.scss';

const { Option } = Select;
const { useForm } = Form;

export const ModalSettlements = (
  {
    isModal,
    setIsModal,
    district,
    requestGetSettlements,
    initialFormValues,
    setInitialFormValues,
    request
  }) => {
  const [form] = useForm();

  const requestChangeSettlements = async (body) => {
    const result = await getNetwork().put(
      API_ROOT + '/passangers/settlements',
      {
        ...body,
        id: initialFormValues.id,
        settlements: body.settlements.trim()
      }
    );
    if (result.status === 200) {
      message.success(`Населенный пункт ${result.data.settlements || ''} успешно изменен`);
      requestGetSettlements();
      request();
    }
    console.log(initialFormValues);

    if (result.status >= 500) {
      message.error('Сервер временно недоступен, попробуйте позже');
    }
  };

  const requestCreateSettlements = async (body) => {
    const result = await getNetwork().post(
      API_ROOT + '/passangers/settlements',
      {
        ...body,
        settlements: body.settlements.trim()
      }
    );
    if (result.status === 200) {
      message.success(`Населенный пункт ${result.data.settlements || ''} успешно добавлен`);
      requestGetSettlements();
      request();
    }

    if (result.status >= 500) {
      message.error('Сервер временно недоступен, попробуйте позже');
    }
  };

  const requestForm = async (body) => {
    if (initialFormValues) {
      requestChangeSettlements(body);
    } else {
      requestCreateSettlements(body);
    }

    handleCancel();
  };

  const handleCancel = () => {
    form.resetFields();
    setInitialFormValues(null);
    setIsModal(false);
  };

  const onFinish = (values) => {
    requestForm(values);
  };
  console.log(initialFormValues);

  useEffect(() => {
    form.setFieldsValue(initialFormValues);
  }, [initialFormValues, form]);

  return (
    <Modal
      title={initialFormValues ? 'Редактирование населенного пункта' : 'Новый населённый пункт'}
      open={isModal}
      onCancel={handleCancel}
      getContainer={false}
      footer={[
        <Button key="add" type="primary" htmlType="submit" form="addSettlementsForm">
          Отправить
        </Button>,
        <Button key="back" onClick={handleCancel}>
          Отменить
        </Button>
      ]}
    >
      <Form
        form={form}
        id="addSettlementsForm"
        name="formSettlements"
        labelCol={{ span: 8 }}
        layout='vertical'
        onFinish={onFinish}
      >
        <Form.Item
          label="Район"
          name="regionId"
          rules={[{ required: true, message: 'Пожалуйста выберите район' }]}
        >
          <Select>
            {district.map((item) => <Option key={item.id} value={item.id}>{item.district}</Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          label="Населённый пункт"
          name="settlements"
          rules={[
            { whitespace: true, message: 'Пожалуйста введите населённый пункт' },
            { required: true, message: 'Пожалуйста введите населённый пункт' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Широта"
          name="latitude"
          tooltip="Введите значение широты через точку"
          rules={[
            { whitespace: true, message: 'Пожалуйста введите широту' },
            { required: true, message: 'Пожалуйста введите широту' },
          ]}
        >
          <InputNumber
            className={styles.inputNumber}
            stringMode
            controls={false}
            placeholder='00.00'
          />
        </Form.Item>
        <Form.Item
          label="Долгота"
          name="longitude"
          tooltip="Введите значение долготы через точку"
          rules={[
            { whitespace: true, message: 'Пожалуйста введите долготу' },
            { required: true, message: 'Пожалуйста введите долготу' },
          ]}
        >
          <InputNumber
            className={styles.inputNumber}
            stringMode
            controls={false}
            placeholder='00.00'
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
