import React, { Component } from 'react';
import BasicLayout from '../components/layout/BasicLayout';
import { API_ROOT } from '../config';
import { Table, Divider, Popconfirm, message, Spin } from 'antd';
import { withTranslation } from 'react-i18next';
import { getNetwork } from '../utils/getNetwork';

class CrossesList extends Component {
  constructor() {
    super();
    this.state = {
      roads: [],
      crosses: [],
      loading: true,
    };
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    const result = await getNetwork().get(API_ROOT + '/api/v1/roadway/get/0/9999');
    if (result.status === 200) {
      const roads = result.data.content.sort((a, b) => {
        return a.id - b.id;
      });
      const crosses = roads.filter((road) => road.isCross);

      this.setState({ roads, crosses }, () => this.changeAttr(crosses));
    }
  }

  changeAttr(crosses) {
    let { roads } = this.state;
    crosses.forEach((element, index) => {
      let road = roads.find((r) => r.id === element.crossItems[0].itemId);
      if (road) {
        crosses[index].eventName = road.eventName;
        crosses[index].workCategory = road.workCategory;
        crosses[index].street = road.street;
        crosses[index].builder = road.builder;
      }
    });
    this.setState({
      crosses,
      loading: false,
    });
  }

  goToRoad(record) {
    this.props.history.push('/list/add?id=' + record.id);
  }

  confirmDelete(id) {
    let { t } = this.props;
    getNetwork().post(API_ROOT + '/api/v1/roadway/delete/' + id).then(() => {
      message.success(t('intersections.removed'));
      this.getData();
    });
  }

  render() {
    let { t } = this.props;
    const { roads } = this.state;

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title: t('intersections.responsible'),
        dataIndex: 'eventName',
      },
      {
        title: t('intersections.categoryOfWork'),
        dataIndex: 'workCategory',
      },
      {
        title: t('intersections.street'),
        dataIndex: 'street',
      },
      {
        title: t('intersections.contractor'),
        dataIndex: 'builder',
      },
      {
        title: t('intersections.action'),
        key: 'action',
        render: (text, record) => (
          <span>
            <span className="link" onClick={() => this.goToRoad(record)}>
              {t('intersections.edit')}
            </span>
            <Divider type="vertical" />
            <Popconfirm
              title={t('intersections.exactlyWantToDelete') + '?'}
              onConfirm={() => this.confirmDelete(record.id)}
              onCancel={this.cancel}
              okText='Да'
              cancelText="Нет"
            >
              <a href="javascript:void(0)">{t('intersections.delete')}</a>
            </Popconfirm>
          </span>
        ),
      },
    ];

    return (
      <BasicLayout>
        <h3>{t('intersections.allIntersections')}</h3>
        <Spin
          tip={t('intersections.loadingPleaseWait') + '...'}
          spinning={this.state.loading}
        >
          <Table
            rowKey={(record) => record.id}
            className="components-table-demo-nested"
            columns={columns}
            dataSource={roads}
          />
        </Spin>
      </BasicLayout>
    );
  }
}
export default withTranslation()(CrossesList);
