import { useCallback, useEffect } from 'react';
import { getNetwork } from '../../../../../../../utils/getNetwork';
import { API_ROOT } from '../../../../../../../config';
import { combineSum } from '../../../../../../GosService/utils';
import { useDispatch } from 'react-redux';
import { setValue } from '../../../../../../../redux/actions/reportView';

const useFiltersChart = ({
  formValues,
  isModalOpen,
  setReports,
  setCreatedReports,
  setFormValues,
  isListReport,
  setIsListReport,
  requestCreatedReadings,
  setLoading,
  setIsConsolidatedReport,
}) => {
  const dispatch = useDispatch();
  const withSummary = useCallback(
    (data) => {
      return combineSum(data, formValues.chartType).map((i) => ({
        ...i,
        summaryFiz:
          i.electronicalyThroughtInformationSystemsFiz +
          i.inPeperFormFiz +
          i.serviceCountByStateorgCompaniesFiz +
          i.serviceCountByStateorgPeopleFiz +
          i.throughTheServiceProviderFiz,
        summaryYr:
          i.electronicalyThroughtInformationSystemsYr +
          i.inPeperFormYr +
          i.serviceCountByStateorgCompaniesYr +
          i.serviceCountByStateorgPeopleYr +
          i.throughTheServiceProviderYr,
      }));
    },
    [formValues.chartType]
  );

  const handleClickResetButton = () => {
    setReports([]);
    setCreatedReports([]);
    setFormValues({
      chartType: null,
      org: null,
      district: null,
      year: null,
      month: null,
      quarter: null,
    });
    setIsListReport(true);
    requestCreatedReadings();
  };

  const handleChangeDateSelect = (name) => (value) => {
    setReports([]);
    setFormValues({
      ...formValues,
      month: name === 'quarter' ? null : formValues.month,
      [name]: value ? value : null,
    });
  };

  const handleChangeLocality = (value) => {
    setReports([]);
    setFormValues((prev) => ({
      ...prev,
      locality: value ? value : null,
    }));
  };

  const handleChangeOrg = (value) => {
    setReports([]);
    setFormValues((prev) => ({
      ...prev,
      org: value ? value : null,
    }));
  };

  const handleChangeChartType = (value) => {
    setReports([]);
    setFormValues((prev) => ({ ...prev, chartType: value }));
  };

  const handleChangeDistrict = (value) => {
    setReports([]);
    setFormValues((prev) => ({
      ...prev,
      district: value ? value : null,
    }));
  };

  const getChartData = useCallback(() => {
    if (!formValues.chartType) return;
    setLoading(true);

    getNetwork()
      .get(API_ROOT + `/edu-report/${formValues.recordId}`)
      .then((res = {}) => {
        if (res.status === 200) {
          const result = res.data;
          const data = result.data
          dispatch(setValue(result))
          setReports(data);
          setIsConsolidatedReport(result.consolidatedReport);
        }
      })
      .finally(() => {
        setLoading(false);
        setIsListReport(false);
      });
  }, [formValues, withSummary, setIsConsolidatedReport, setLoading, setIsListReport, setReports]);

  const getChartDataWithFilters = useCallback(() => {
    if (!formValues.chartType) return;
    setLoading(true);

    const { chartType, district, locality, org, year, month } = formValues;

    getNetwork()
      .get(
        `${API_ROOT}/edu-report/filter?reportKindIds=${chartType}&regionIds=${district.id}&localityIds=${locality}&organizationIds=${org}&yearIds=${year}&monthIds=${month}`
      )
      .then((res = {}) => {
        if (res.status === 200 && Array.isArray(res.data) && res.data.length > 0) {
          const reportId = res.data[0] && res.data[0].id;
          if (!reportId) {
            console.error("Ошибка: reportId отсутствует", res.data);
            return;
          }
          getNetwork()
            .get(`${API_ROOT}/edu-report/${reportId}`)
            .then((res) => {
              const result = res.data;
              if (!result || !result.data) {
                console.error("Ошибка: result.data отсутствует", result);
                return;
              }

              dispatch(setValue(result));
              setReports(result.data);
              setIsConsolidatedReport(result.consolidatedReport);
            })
            .catch((err) => console.error("Ошибка при загрузке отчета:", err));
        } else {
          console.error("Ошибка: res.data пустой или не массив", res);
        }
      })
      .catch((err) => console.error("Ошибка при фильтрации отчетов:", err))
      .finally(() => {
        setLoading(false);
        setIsListReport(false);
      });
  }, [formValues, withSummary, setIsConsolidatedReport, setLoading, setIsListReport, setReports]);


  useEffect(() => {
    if (formValues && isModalOpen) {
      getChartData();
      setIsListReport(true);
    }
  }, [isModalOpen, formValues, getChartData, setIsListReport]);
  return {
    getChartData,
    getChartDataWithFilters,
    handleChangeChartType,
    handleChangeDistrict,
    handleChangeOrg,
    handleChangeDateSelect,
    handleClickResetButton,
    handleChangeLocality,
  };
};

export default useFiltersChart;
