import { useTranslation } from 'react-i18next';


export const useTableColumnsEventsReport = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('governmentServiceEducation.serialNumber'),
      dataIndex: 'uniqId',
      width: 110,
    },
    {
      title: t('governmentServiceEducation.activities'),
      dataIndex: 'name',
      width: 500,
    },
    {
      title: t('governmentServiceEducation.quantity'),
      dataIndex: 'count',
      align: 'center',
      width: 110,
    },
  ];
};