import React, { Component } from 'react';
import BasicLayout from '../../components/layout/BasicLayout';
import { API_ROOT } from '../../config';
import {
  Button,
  DatePicker,
  Row,
  Col,
  Select,
  Tabs,
  TimePicker,
  Pagination,
  InputNumber,
  message,
  Modal,
  Table
} from 'antd';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import * as XLSX from 'sheetjs-style';
import { generateExcelExport } from '../../utils/xlsExport';
import { CreateDirectoryRouteTab } from './components/tabs/CreateDirectoryRouteTab/CreateDirectoryRouteTab';
import { CreateDirectoryInterpreterTab } from './components/tabs/CreateDirectoryInterpreterTab/CreateDirectoryInterpreterTab';
import { getNetwork } from '../../utils/getNetwork';
import styles from './PassengerTransport.module.scss';
import { generateHex } from '../../utils/generateHex';
import { getViaIndexed } from '../../utils/getViaIndexed';
import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { AkmolaCoords } from '../../constants';

const { Option } = Select;
const { RangePicker } = DatePicker;

const timeFormat = 'HH:mm';

const emptyRoute = (key) => ({
  key: key,
  locality: '',
  distance: 0,
  speed: 0,
  parkingTime: 0,
  arrival: null,
  departure: null,
  movementTime: 0,
});

const lastKey = 10;

const alphabet = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');
class PassengerTransport extends Component {
  constructor() {
    super();

    const routesData = [emptyRoute(0), emptyRoute(lastKey)];

    this.state = {
      destinationLocality: null,
      arrivalLocality: null,
      routeDate: null,
      transporters: [],
      departureTimeFromStart: null,
      departureTimeFromEnd: null,
      routesData: routesData,
      reverseRoutesData: [...routesData].reverse(),
      localities: [],
      searchDataLocalities: [],
      localitiesDepartureFiltered: [],
      localitiesArrivalFiltered: [],
      searchValueLocalities: '',
      districtData: [],
      districtDepartureSelect: null,
      districtArrivalSelect: null,
      exportList: [],
      exportListFiltered: [],
      currentPage: 0,
      totalPages: 0,
      isModal: false,
      isModalView: false,
      transporterData: [],
      searchValue: '',
      searchData: [],
      map: null,
      periods: {
        distancePeriod: [],
        speedPeriod: []
      },
      filters: {
        rangePicker: [],
        settlementsSelect: [],
        transportersSelect: []
      }
    };
  }

  exportListTab = () => {
    const columns = [
      {
        title: '№',
        dataIndex: 'number',
      },
      {
        title: 'Наименование',
        render: (_, record) => (
          record.arrivalLocality && record.destinationLocality ? (
            <span>
              {record.destinationLocality + ' -> ' + record.arrivalLocality}
            </span>
          ) : '—'
        )
      },
      {
        title: 'Дата',
        render: (_, record) => (
          <span>
            {record.routeDate ? moment(record.routeDate).format('DD.MM.YYYY') : '—'}
          </span>
        )
      },
      {
        title: 'Перевозчик',
        render: (_, record) => (
          record.transporters.length ? record.transporters.map((item) => (
            <>
              <span key={item.id}>
                {item.transporter}
              </span>
              <br />
            </>
          )) : '—'
        )
      },
      {
        title: 'Карта',
        align: 'center',
        render: (_, record) => (
          <Button
            bordered={false}
            onClick={handleClickOpenMap(record)}
            disabled={!record.coordinates}
            type="link"
          >
            Открыть
          </Button>
        )
      },
      {
        title: 'Экспорт',
        align: 'center',
        render: (_, record) => (
          <Button
            bordered={false}
            onClick={exportToExcel(record)}
            download
            type="link"
          >
            Экспорт в Excel
          </Button>
        )
      },
      {
        title: 'Действие',
        align: 'center',
        render: (_, record) => (
          <Button
            bordered={false}
            onClick={deleteRow(record)}
            download
            type="link"
          >
            Удаление
          </Button>
        )
      },
    ];

    const exportToExcel = (record) => async () => {
      let exportData = [];
      let exportDate = '';
      let exportBus = '';
      let exportDepTimeStart = '';
      let exportDepTimeEnd = '';
      const xlsx = await getNetwork().get('/xlsx/RouteExcelTemplate.xlsx', {
        responseType: 'arraybuffer',
        headers: {
          Accept:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      });
      const workbook = XLSX.read(new Uint8Array(xlsx.data), {
        bookType: 'xlsx',
        type: 'array',
      });

      let dataGrid = (
        await getNetwork().get(API_ROOT + `/passangers/list/${record.id}`)
      ).data;
      dataGrid.routesData.sort((a, b) =>
        moment(a.departure).diff(b.departure, 'minutes')
      );
      dataGrid.reverseRoutesData.sort((a, b) =>
        moment(b.departure).diff(a.departure, 'minutes')
      );
      dataGrid.routesData.forEach((item, index) => {
        const mediumTechnicalSpeedValue = Math.round((60 / item.movementTime) * item.distance);
        item.distanceFromStart =
          index === 0
            ? item.distance
            : item.distance +
            dataGrid.routesData[index - 1].distanceFromStart;
        item.mediumTechnicalSpeed =
          index === 0 ? 0 : isNaN(mediumTechnicalSpeedValue) ? 0 : mediumTechnicalSpeedValue;
      });
      dataGrid.routesData[0].locality = dataGrid.destinationLocality;
      dataGrid.routesData.at(-1).locality =
        dataGrid.arrivalLocality;

      function minutesToHHMM(mins, twentyFour = true) {
        let h = Math.floor(mins / 60);
        let m = (mins % 60).toString().padStart(2, '0');

        if (twentyFour) {
          h = h.toString().padStart(2, '0');
          return `${h}:${m}`;
        } else {
          let a = 'am';
          if (h >= 12) a = 'pm';
          if (h > 12) h = h - 12;
          return `${h}:${m} ${a}`;
        }
      }

      const raceTime = moment(dataGrid.routesData.at(-1).arrival).diff(
        dataGrid.routesData[0].departure,
        'minutes'
      );

      const fullDistance = dataGrid.routesData.at(-1).distanceFromStart;
      const movementTime = dataGrid.routesData.reduce((acc, cur) => acc += cur.movementTime, 0);

      const operatingSpeed = fullDistance / (
        (raceTime + dataGrid.routesData[0].parkingTime + dataGrid.routesData.at(-1).parkingTime) / 60
      );
      const codesToReplace = {
        arrivalTimeFromStart: {
          code: '$DEPARTURE_TIME_FROM_START',
          newValue: record.departureTimeFromStart && moment(record.departureTimeFromStart).format(timeFormat),
        },
        departureTimeFromEnd: {
          code: '$DEPARTURE_TIME_FROM_END',
          newValue: record.departureTimeFromEnd && moment(record.departureTimeFromEnd).format(timeFormat),
        },
        routeData: {
          code: '$DATA_GRID',
          newValue: dataGrid,
        },
        routeName: {
          code: '$BUS_ROUTE_NAME',
          newValue: `${record.arrivalLocality} - ${record.destinationLocality}`,
        },
        movementTime: {
          code: '$MOVEMENT_TIME',
          newValue: movementTime && minutesToHHMM(movementTime),
        },
        raceTime: {
          code: '$RACE_TIME',
          newValue: raceTime && minutesToHHMM(raceTime),
        },
        mediumTechnicalSpeed: {
          code: '$MEDIUM_TECHNICAL_SPEED',
          newValue: (fullDistance / (movementTime / 60)).toFixed(2),
        },
        messageRate: {
          code: '$MESSAGE_RATE',
          newValue: (fullDistance / (raceTime / 60)).toFixed(2),
        },
        operatingSpeed: {
          code: '$OPERATING_SPEED',
          newValue: (operatingSpeed).toFixed(2),
        },
        transporters: {
          code: '$TRANSPORTERS',
          newValue: Array.isArray(record.transporters) ? record.transporters.map((item) => item.transporter).join(', ') : '',
        },
        date: {
          code: '$DATE',
          newValue: moment(record.routeDate).format('DD/MM/YYYY'),
        },
      };

      exportBus = record.arrivalLocality && record.destinationLocality
        ? `${record.destinationLocality} - ${record.arrivalLocality}` : '';
      exportDate = record.routeDate && moment(record.routeDate).format('DD/MM/YYYY');
      exportDepTimeStart = record.departureTimeFromStart && moment(record.departureTimeFromStart).format(
        timeFormat
      );
      exportDepTimeEnd = record.departureTimeFromEnd && moment(record.departureTimeFromEnd).format(
        timeFormat
      );
      //new
      let sheet = workbook.Sheets[workbook.SheetNames[0]];

      let range = XLSX.utils.decode_range(sheet['!ref']);
      for (let R = range.s.r; R <= range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
          let cellref = XLSX.utils.encode_cell({ c: C, r: R });
          if (!sheet[cellref]) continue;
          let cell = sheet[cellref];
          //add border style

          if (!(cell.t === 's' || cell.t === 'str')) continue;
          for (const [, value] of Object.entries(codesToReplace)) {
            if (cell.v === value.code) {
              if (value.code === '$DATA_GRID') {
                const routesData = value.newValue.routesData;

                routesData.forEach((item, index, array) => {
                  const reversedRouteData =
                    value.newValue.reverseRoutesData[index];

                  const excelRow = {
                    arrival: index !== 0 ? item.arrival && moment(item.arrival).format(timeFormat) : null,
                    parkingTime: item.parkingTime,
                    departure: array.length - 1 !== index
                      ? item.departure && moment(item.departure).format(timeFormat) : null,
                    mediumTechnicalSpeed: index !== 0 ? item.mediumTechnicalSpeed ? item.mediumTechnicalSpeed : 0 : null,
                    movementTime: index !== 0 ? item.movementTime ? item.movementTime : 0 : null,
                    distance: index !== 0 ? item.distance : null,
                    distanceFromStart: index !== 0 ? item.distanceFromStart : null,
                    routeLabel: item.locality,
                    reversedArrival: index !== array.length - 1 ? reversedRouteData.arrival && moment(
                      reversedRouteData.arrival
                    ).format(timeFormat) : null,
                    reversedParkingTime: reversedRouteData.parkingTime,
                    reversedDeparture: index !== 0 ? reversedRouteData.departure && moment(
                      reversedRouteData.departure
                    ).format(timeFormat) : null,
                  };
                  let excelData = [];
                  for (let j = 0; j < Object.keys(excelRow).length; j++) {
                    //new
                    excelData.push(excelRow[Object.keys(excelRow)[j]]);
                    //new
                    const rowNumberForTableRendering = 19;
                    const cellIndex =
                      alphabet[j] + (rowNumberForTableRendering + index);

                    !sheet[cellIndex] &&
                      excelRow[Object.keys(excelRow)[j]] !== null
                      ? (sheet[cellIndex] = {
                        v: excelRow[Object.keys(excelRow)[j]],
                        s: {
                          border: {
                            top: { style: 'thin', color: { rgb: '000' } },
                            right: { style: 'thin', color: { rgb: '000' } },
                            bottom: { style: 'thin', color: { rgb: '000' } },
                            left: { style: 'thin', color: { rgb: '000' } },
                          },
                        },
                      })
                      : (sheet[cellIndex] = {});
                  }
                  exportData.push(excelData);
                });
              } else {
                cell.v = value.newValue;
              }
            }
          }
        }
      }

      let xlsData = [
        {
          key: 'H7',
          data: exportDate,
        },
        {
          key: 'G13',
          data: exportBus,
        },
        {
          key: 'J14',
          data: exportDepTimeStart,
        },
        {
          key: 'J15',
          data: exportDepTimeEnd,
        },
        {
          key: 'G38',
          data: codesToReplace.movementTime.newValue,
        },
        {
          key: 'G39',
          data: codesToReplace.raceTime.newValue,
        },
        {
          key: 'G40',
          data: codesToReplace.mediumTechnicalSpeed.newValue,
        },
        {
          key: 'G41',
          data: codesToReplace.messageRate.newValue,
        },
        {
          key: 'G42',
          data: codesToReplace.operatingSpeed.newValue,
        },
        {
          key: 'G43',
          data: codesToReplace.transporters.newValue,
        },
        {
          key: 'A19',
          data: exportData,
        },
      ];
      const generateNameReport = () => {
        if (record.arrivalLocality && record.destinationLocality && record.routeDate) {
          return `${record.destinationLocality} — ${record.arrivalLocality} от ${moment(record.routeDate).format('DD.MM.YYYY')}`;
        }
        return 'Отчет';
      };

      generateExcelExport(
        '/xlsx/RouteExcelTemplate.xlsx',
        generateNameReport(),
        xlsData
      );
    };

    const setPage = (pageNumber) => {
      getNetwork().get(
        API_ROOT + '/passangers/list?size=10&page=' + (pageNumber - 1)
      ).then((res) => {
        this.setState({
          exportList: res.data.routes,
          exportListFiltered: res.data.routes,
          totalPages: res.data.total,
          currentPage: pageNumber - 1,
        });
      });
    };

    const deleteRow = (row) => async () => {
      const resultDelete = await getNetwork().delete(API_ROOT + '/passangers/delete/' + row.id);

      if (resultDelete.status === 200) {
        const resultGet = await getNetwork().get(API_ROOT + '/passangers/list?size=10&page=' + this.state.currentPage);

        if (resultGet.status === 200) {
          this.setState({
            exportList: resultGet.data.routes,
            exportListFiltered: resultGet.data.routes,
            totalPages: resultGet.data.total,
          });
          message.success('Маршрут успешно удален');
        }
      }

      if (resultDelete.status >= 500) {
        message.error('Сервер временно недоступен, попробуйте позже');
      }
    };

    const initMapViewRoute = (row) => {
      const hex = generateHex();
      const multiRoute = new window.ymaps.multiRouter.MultiRoute({
        referencePoints: row.coordinates,
        params: {
          results: 1,
          viaIndexes: getViaIndexed(row.coordinates.length),
        },
      }, {
        boundsAutoApply: true,
        zoomMargin: 30,
        balloonPanelMaxMapArea: 0,
        routeActiveStrokeColor: hex,
        pinActiveIconFillColor: hex,
        wayPointStartIconColor: hex,
        wayPointStartIconFillColor: hex,
        wayPointFinishIconColor: hex,
        wayPointFinishIconFillColor: hex,
        viaPointIconFillColor: hex,
        viaPointActiveIconFillColor: hex,
      });

      const polygon = new window.ymaps.GeoObject(
        {
          geometry: {
            type: 'LineString',
            coordinates: AkmolaCoords,
          },
        },
        {
          strokeColor: '#000000',
          strokeWidth: 3,
        }
      );

      const ymaps = new window.ymaps.Map('YMapsViewRoute', {
        center: row.coordinates[0],
        zoom: 7,
      },
      { buttonMaxWidth: 300 });

      this.setState({ map: ymaps });
      ymaps.geoObjects.add(multiRoute).add(polygon);
    };

    const handleClickOpenMap = (row) => () => {
      this.setState({ isModalViewMap: true });
      window.ymaps.ready(() => initMapViewRoute(row));
    };

    const handleCancel = () => {
      this.state.map.destroy();
      this.setState({ isModalViewMap: false, map: null });
    };

    const onChangeRangePicker = (value) => {
      if (value) {
        this.setState((state) => ({
          ...state,
          filters: {
            ...state.filters,
            rangePicker: value,
          },
        }));
      } else {
        this.setState((state) => ({
          filters: {
            ...state.filters,
            rangePicker: [],
          },
        }));
      }
    };

    const onChangeSettlementSelect = (value) => {
      this.setState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          settlementsSelect: value,
        }
      }));
    };

    const onSearchSettlementSelect = (value) => {
      this.setState({ searchValueLocalities: value });
      this.setState({
        searchDataLocalities: this.state.localities.filter(
          (item) => item.settlements.toLowerCase().includes(value.toLowerCase()))
      });
    };

    const clearSearchSettlementState = () => {
      this.setState({ searchValueLocalities: '' });
      this.setState({ searchDataLocalities: this.state.localities });
    };

    const onChangeTransporterSelect = (value) => {
      this.setState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          transportersSelect: value,
        }
      }));
    };

    const onSearchTransporterSelect = (value) => {
      this.setState({ searchValue: value });
      this.setState({
        searchData: this.state.transporterData.filter((item) => item.transporter.toLowerCase().includes(value.toLowerCase()))
      });
    };

    const clearSearchState = () => {
      this.setState({ searchValue: '' });
      this.setState({ searchData: this.state.transporterData });
    };

    const handleClickResetButton = () => {
      this.setState({
        filters: {
          rangePicker: [],
          settlementsSelect: [],
          transportersSelect: []
        }
      });
    };

    const exportTable = (
      <>
        <div className={styles.box}>
          <RangePicker
            className={styles.rangePicker}
            locale={locale}
            onChange={onChangeRangePicker}
            value={this.state.filters.rangePicker}
            ranges={{
              'За этот год': [moment().startOf('year'), moment().endOf('year')],
              'За прошлый год': [moment().startOf('year').subtract(1, 'year'), moment().endOf('year').subtract(1, 'year')],
            }}
          />
          <Select
            className={styles.select}
            value={this.state.filters.settlementsSelect}
            mode="multiple"
            filterOption={false}
            placeholder="Выберете населенный пункт"
            onChange={onChangeSettlementSelect}
            onSearch={onSearchSettlementSelect}
            onBlur={clearSearchSettlementState}
            onDeselect={clearSearchSettlementState}
          >
            {this.state.searchDataLocalities.map((item) => (
              <Option key={item.id} value={item.settlements}>{item.settlements}</Option>
            ))}
          </Select>
          <Select
            className={styles.select}
            value={this.state.filters.transportersSelect}
            mode="multiple"
            filterOption={false}
            placeholder="Выберете перевозчика"
            onChange={onChangeTransporterSelect}
            onSearch={onSearchTransporterSelect}
            onBlur={clearSearchState}
            onDeselect={clearSearchState}
          >
            {this.state.searchData.map((item) => (
              <Option key={item.id} value={item.id}>{item.transporter}</Option>
            ))}
          </Select>
          <Button
            onClick={handleClickResetButton}
          >
            Сбросить
          </Button>
        </div>
        <Table dataSource={this.state.exportListFiltered} columns={columns} pagination={false} />
        {this.state.totalPages > 10 ?
          <Pagination
            className={styles.pagination}
            onChange={(e) => setPage(e)}
            total={this.state.totalPages}
          /> : null}
        <Modal
          title="Маршрут"
          open={this.state.isModalViewMap}
          onCancel={handleCancel}
          width={1000}
          footer={false}
        >
          <div id="YMapsViewRoute" style={{ height: 400 }} />
        </Modal>
      </>
    );

    return exportTable;
  };

  createRouteTab = () => {
    let { t } = this.props;

    const handleClickResetButton = () => {
      this.setState(() => ({
        ...this.state,
        destinationLocality: null,
        arrivalLocality: null,
        routeDate: null,
        transporters: [],
        districtDepartureSelect: null,
        districtArrivalSelect: null,
        localitiesDepartureFiltered: this.state.localities,
        localitiesArrivalFiltered: this.state.localities,
        departureTimeFromStart: null,
        departureTimeFromEnd: null,
        routesData: [emptyRoute(0), emptyRoute(lastKey)],
        reverseRoutesData: [...[emptyRoute(0), emptyRoute(lastKey)]].reverse(),
      }));
    };

    const onChangeDatePicker = (value) => {
      if (value) {
        this.setState({ routeDate: value });
      }
    };

    const onChangeTransporterSelect = (value) => {
      this.setState({ transporters: value });
    };

    const onSearchTransporterSelect = (value) => {
      this.setState({ searchValue: value });
      this.setState({
        searchData: this.state.transporterData.filter((item) => item.transporter.toLowerCase().includes(value.toLowerCase()))
      });
    };

    const clearSearchState = () => {
      this.setState({ searchValue: '' });
      this.setState({ searchData: this.state.transporterData });
    };

    const onChangeRoute = (nameField) => (value) => {
      this.setState({
        [nameField]: value.split(':')[0],
      });
    };

    const handleChangeDepartureFilter = (value) => {
      this.setState((state) => ({
        ...state,
        districtDepartureSelect: value,
        localitiesDepartureFiltered: value ? state.localities.filter((item) => item.district === value) : state.localities
      }));
    };

    const handleChangeArrivalFilter = (value) => {
      this.setState((state) => ({
        ...state,
        districtArrivalSelect: value,
        localitiesArrivalFiltered: value ? state.localities.filter((item) => item.district === value) : state.localities
      }));
    };

    const createRouteTabHeader = (
      <React.Fragment>
        <Row gutter={[48, 48]}>
          <Col span={6}>
            <h3>Маршрут</h3>
            <Row style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
              <Select
                placeholder="Фильтр по районам (отправление)"
                allowClear
                value={this.state.districtDepartureSelect}
                onChange={handleChangeDepartureFilter}
                options={this.state.districtData.map((item) => ({
                  label: item.district,
                  value: item.district
                }))}
              />
              <Select
                showSearch
                placeholder="Отправление"
                value={this.state.destinationLocality}
                onChange={onChangeRoute('destinationLocality')}
              >
                {this.state.localitiesDepartureFiltered.map((item) => (
                  <Option key={`${item.settlements}:${item.id}`}>
                    {item.settlements}
                  </Option>
                ))}
              </Select>
              <br />
              <Select
                placeholder="Фильтр по районам (прибытие)"
                allowClear
                value={this.state.districtArrivalSelect}
                onChange={handleChangeArrivalFilter}
                options={this.state.districtData.map((item) => ({
                  label: item.district,
                  value: item.district
                }))}
              />
              <Select
                showSearch
                placeholder="Прибытие"
                value={this.state.arrivalLocality}
                onChange={onChangeRoute('arrivalLocality')}
              >
                {this.state.localitiesArrivalFiltered.map((item) => (
                  <Option key={`${item.settlements}:${item.id}`}>
                    {item.settlements}
                  </Option>
                ))}
              </Select>
            </Row>
          </Col>
          <Col span={4}>
            <h3>Дата</h3>
            <DatePicker
              className={styles.datePicker}
              placeholder='Выберите дату'
              value={this.state.routeDate}
              onChange={onChangeDatePicker}
            />
          </Col>
          <Col span={6}>
            <h3>Наименование перевозчика</h3>
            <Row style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
              <Select
                value={this.state.transporters}
                mode="multiple"
                filterOption={false}
                placeholder="Выберете перевозчика"
                onChange={onChangeTransporterSelect}
                onSearch={onSearchTransporterSelect}
                onBlur={clearSearchState}
                onDeselect={clearSearchState}
              >
                {this.state.searchData.map((item) => (
                  <Option key={item.id} value={item.id}>{item.transporter}</Option>
                ))}
              </Select>
            </Row>
          </Col>
        </Row>
        <Button
          style={{ margin: '24px 0px 16px 0px' }}
          type='primary'
          onClick={handleClickResetButton}
        >
          Сбросить
        </Button>
        <Row>
          <Col span={8}>
            <Row style={{ display: 'flex', flexDirection: 'column' }}>
              <h3>
                Время отправления с начального пункта
              </h3>
              <TimePicker
                style={{ width: '40%' }}
                format={timeFormat}
                placeholder={'Выберите время'}
                value={this.state.departureTimeFromStart && moment(this.state.departureTimeFromStart, timeFormat)}
                onChange={(e) => {
                  if (e !== null) {
                    let newRoutesData = [...this.state.routesData];
                    let newReversedRoutesData = [];
                    newRoutesData.forEach((e) =>
                      newReversedRoutesData.unshift(Object.assign({}, e))
                    );
                    newRoutesData[0].departure = e.toDate();

                    recalculateAllRows(newRoutesData);
                    recalculateAllRows(newReversedRoutesData);

                    this.setState({
                      departureTimeFromStart: e.toDate(),
                      routesData: newRoutesData,
                      reverseRoutesData: newReversedRoutesData,
                    });
                  }
                }}
              />
            </Row>
          </Col>
          <Col span={8}>
            <Row style={{ display: 'flex', flexDirection: 'column' }}>
              <h3>
                Время отправления с конечного пункта
              </h3>
              <TimePicker
                style={{ width: '40%' }}
                format={timeFormat}
                placeholder={'Выберите время'}
                value={this.state.departureTimeFromEnd && moment(this.state.departureTimeFromEnd, timeFormat)}
                onChange={(e) => {
                  if (e !== null) {
                    let newRoutesData = [...this.state.reverseRoutesData];

                    newRoutesData[0].departure = e.toDate();

                    recalculateAllRows(newRoutesData);

                    this.setState({
                      departureTimeFromEnd: e.toDate(),
                      reverseRoutesData: newRoutesData,
                    });
                  }
                }}
              />
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );

    const tableHeader = (
      <tr className={styles.rowHead}>
        <td style={{ width: '20%', overflow: 'hidden', position: 'relative' }}>
          <h3 className={styles.subtitle}>{t('settingSchedule.locality')}</h3>
        </td>
        <td style={{ width: '5%' }}>
          <h3 className={styles.subtitle}>{t('settingSchedule.distance')}</h3>
        </td>
        <td style={{ width: '5%' }}>
          <h3 className={styles.subtitle}>{t('settingSchedule.speed')}</h3>
        </td>
        <td style={{ width: '5%' }}>
          <h3 className={styles.subtitle}>{t('settingSchedule.parking')}</h3>
        </td>
        <td style={{ width: '5%' }}>
          <h3 className={styles.subtitle}>{t('settingSchedule.arrival')}</h3>
        </td>
        <td style={{ width: '5%' }}>
          <h3 className={styles.subtitle}>{t('settingSchedule.departure')}</h3>
        </td>
        <td style={{ width: '10%' }}>
          <h3 className={styles.subtitle}>{t('settingSchedule.movementTime')}</h3>
        </td>
      </tr>
    );

    const recalculateAllRows = (newRoutesData) => {
      newRoutesData.forEach((row, i) => {
        if (i > 0) {
          row.movementTime = Math.round(
            (60 / row.speed) * row.distance
          );
          row.arrival = moment(newRoutesData[i - 1].departure)
            .add(row.movementTime, 'm')
            .toDate();
          row.departure = moment(newRoutesData[i].arrival)
            .add(row.parkingTime, 'm')
            .toDate();
        }
      });
    };

    const checkFirstOrLastRow = (row) => {
      switch (row.key) {
        case 0:
          return this.state.destinationLocality;
        case lastKey:
          return this.state.arrivalLocality;
        case this.state.routesData.length - 1:
          return this.state.arrivalLocality;
        default:
          return row.locality;
      }
    };

    const validateCellData = (cellData) =>
      !(
        cellData === 0 ||
        cellData === null ||
        isNaN(cellData) ||
        !isFinite(cellData) ||
        cellData === new Date(0) ||
        cellData === ''
      );

    const setTableState = (routesData, isEvent = false) => {
      recalculateAllRows(routesData);

      const newReversedRoutesData = [...routesData].reverse();

      newReversedRoutesData[0].departure = this.state.departureTimeFromEnd;
      recalculateAllRows(newReversedRoutesData);

      if (isEvent) {
        this.setState({
          routesData: routesData.map((item) => ({
            ...item,
            distance: 0,
            speed: 0,
            movementTime: 0,
            parkingTime: 0,
            arrival: item.key === 0 ? this.state.departureTimeFromEnd : null,
            departure: item.key === 0 ? this.state.departureTimeFromStart : null,
          })),
          reverseRoutesData: newReversedRoutesData.map((item) => ({
            ...item,
            distance: 0,
            speed: 0,
            movementTime: 0,
            parkingTime: 0,
            arrival: item.key === 10 ? this.state.departureTimeFromStart : null,
            departure: item.key === 10 ? this.state.departureTimeFromEnd : null,
          })),
          periods: {
            distancePeriod: [],
            speedPeriod: [],
          }
        });
      } else {
        this.setState({
          routesData: routesData,
          reverseRoutesData: newReversedRoutesData,
          periods: {
            distancePeriod: [],
            speedPeriod: [],
          }
        });
      }
    };

    const onChangeCellWithPeriods = (row, nameField) => (value) => {
      const index = this.state.routesData.findIndex((item) => item.key === row.key);
      const startElem = this.state.routesData[index - 1];
      const period = this.state.periods[`${nameField}Period`].find(
        (item) => item.settlements.join(':') === `${startElem.key}:${row.key}`
      );

      const newRoutesData = this.state.routesData.map((item) => {
        if (item.key === row.key) {
          return {
            ...item,
            [nameField]: value
          };
        }
        return item;
      });

      const newReverseRoutesData = this.state.reverseRoutesData.map((item) => {
        if (item.key === startElem.key) {
          return {
            ...item,
            [nameField]: value
          };
        }
        return item;
      });

      this.setState((state) => ({
        ...state,
        routesData: newRoutesData,
        reverseRoutesData: newReverseRoutesData,
        periods: {
          ...state.periods,
          [`${nameField}Period`]: period
            ? state.periods[`${nameField}Period`].reduce((acc, cur) => {
              if (cur.settlements.join(':') === period.settlements.join(':')) {
                cur.value = value;
              }

              return acc;
            }, [])
            : [...state.periods[`${nameField}Period`], { settlements: [startElem.key, row.key], value }]
        }
      }));

      recalculateAllRows(newRoutesData);
      recalculateAllRows(newReverseRoutesData);
    };

    const getValue = (name, value) => {
      if (name === 'parkingTime' && value) {
        return value.hours() * 60 + value.minutes();
      }

      if (name === 'locality') {
        return value.split(':')[0];
      }

      return value;
    };

    const onChangeCell = (row, nameField) => (value) => {
      const newValue = getValue(nameField, value);
      const start = this.state.routesData.find((item) => item.key === row.key);

      const newRoutesData = this.state.routesData.map((item) => {
        if (item.key === row.key) {
          return nameField === 'locality' ? {
            ...item,
            [nameField]: newValue,
            localityId: +value.split(':')[1]
          } : {
            ...item,
            [nameField]: newValue
          };
        }
        return item;
      });

      if (nameField !== 'parkingTime') {
        const newReverseRoutesData = this.state.reverseRoutesData.map((item) => {
          if (item.key === start.key) {
            return nameField === 'locality' ? {
              ...item,
              [nameField]: newValue,
              localityId: +value.split(':')[1]
            } : {
              ...item,
              [nameField]: newValue
            };
          }
          return item;
        });
        this.setState({
          reverseRoutesData: newReverseRoutesData,
        });
        recalculateAllRows(newReverseRoutesData);
      }

      this.setState({
        routesData: newRoutesData,
      });

      recalculateAllRows(newRoutesData);
    };

    const getParkingTimeValue = (value) => {
      if (value > 60) {
        const hours = Math.floor(value / 60);
        const minutes = value - hours * 60;

        if (hours && minutes) {
          return moment().hours(hours).minutes(minutes);
        }

        if (hours) {
          return moment().hours(hours).minutes(0);
        }

        return null;
      }

      return typeof value === 'number' && value ? moment().hours(0).minutes(value) : null;
    };

    const row = (row) => {
      return (
        <tr className={styles.row} key={row.key}>
          <td>
            {
              row.key === 0 ||
                row.key === this.state.routesData.length - 1 ||
                row.key === lastKey ? (
                  <p className={styles.subtitle}>{checkFirstOrLastRow(row)}</p>
                ) : (
                  <Select
                    className={styles.localitySelect}
                    showSearch
                    popupClassName={styles.popup}
                    dropdownMatchSelectWidth={false}
                    defaultValue={row.locality}
                    value={checkFirstOrLastRow(row)}
                    bordered={false}
                    onChange={onChangeCell(row, 'locality')}
                  >
                    {this.state.localities.map((item) => (
                      <Option key={`${item.settlements}:${item.id}`}>
                        {item.settlements} {`(${item.district})`}
                      </Option>
                    ))}
                  </Select>
                )}
          </td>
          <td>
            {row.key !== 0 && (
              <InputNumber
                type="number"
                placeholder="Введите число"
                controls={false}
                value={
                  row.key !== 0 && validateCellData(row.distance)
                    ? row.distance
                    : ''
                }
                bordered={false}
                onChange={onChangeCellWithPeriods(row, 'distance')}
              />
            )}
          </td>
          <td>
            {row.key !== 0 && (
              <InputNumber
                type="number"
                placeholder="Введите число"
                controls={false}
                value={
                  row.key !== 0 && validateCellData(row.speed)
                    ? row.speed
                    : ''
                }
                bordered={false}
                onChange={onChangeCellWithPeriods(row, 'speed')}
              />
            )}
          </td>
          <td>
            <TimePicker
              value={
                validateCellData(row.parkingTime)
                  ? getParkingTimeValue(row.parkingTime)
                  : ''
              }
              bordered={false}
              placeholder="Выберите время"
              format="HH:mm"
              onChange={onChangeCell(row, 'parkingTime')}
            />
          </td>
          <td>
            <p className={styles.subtitle}>
              {row.key !== 0 && validateCellData(row.arrival)
                ? moment(row.arrival).format(timeFormat)
                : ''}
            </p>
          </td>
          <td>
            <p className={styles.subtitle}>
              {row.key !== lastKey && validateCellData(row.departure)
                ? moment(row.departure).format(timeFormat)
                : ''}
            </p>
          </td>
          <td>
            <p className={styles.subtitle}>
              {row.key !== 0 && validateCellData(row.movementTime)
                ? row.movementTime
                : ''}
            </p>
          </td>
        </tr>
      );
    };

    const routesTable = (
      <div className={styles.wrapRoutesTable}>
        <h3>{t('settingSchedule.directDirection')}</h3>
        <table border="1" width="70%">
          <thead>{tableHeader}</thead>
          <tbody>{this.state.routesData.map((e) => row(e))}</tbody>
        </table>
        <div style={{ display: 'flex', gap: 8 }}>
          <Button className={styles.button} onClick={() => addRow()}>
            Добавить
          </Button>
          <Button className={styles.button} onClick={() => deleteRow()}>
            Удалить
          </Button>
        </div>
      </div>
    );

    const onChangeCellReverse = (row, nameField) => (value) => {
      const newValue = getValue(nameField, value);
      const start = this.state.routesData.find((item) => item.key === row.key);

      const newReverseRoutesData = this.state.reverseRoutesData.map((item) => {
        if (item.key === start.key) {
          return nameField === 'locality' ? {
            ...item,
            [nameField]: newValue,
            localityId: +value.split(':')[1]
          } : {
            ...item,
            [nameField]: newValue
          };
        }
        return item;
      });
      this.setState({
        reverseRoutesData: newReverseRoutesData,
      });
      recalculateAllRows(newReverseRoutesData);
    };

    const reversedRow = (row, index) => {
      const checkValue = (row, nameField) => row.key !== this.state.routesData.length - 1 &&
        index !== 0 &&
        validateCellData(row[nameField])
        ? row[nameField]
        : '';
      const checkDateValue = (row, nameField) => row.key !== this.state.routesData.length - 1 &&
        index !== 0 &&
        validateCellData(row[nameField])
        ? moment(row[nameField]).format(timeFormat)
        : '';
      return (
        <tr className={styles.row} key={row.key}>
          <td>
            <p className={styles.subtitle}>{checkFirstOrLastRow(row)}</p>
          </td>
          <td>
            <p className={styles.subtitle}>
              {checkValue(row, 'distance')}
            </p>
          </td>
          <td>
            <p className={styles.subtitle}>
              {checkValue(row, 'speed')}
            </p>
          </td>
          <td>
            <TimePicker
              value={
                validateCellData(row.parkingTime)
                  ? getParkingTimeValue(row.parkingTime)
                  : ''
              }
              bordered={false}
              placeholder="Выберите время"
              format="HH:mm"
              onChange={onChangeCellReverse(row, 'parkingTime')}
            />
          </td>
          <td>
            <p className={styles.subtitle}>
              {checkDateValue(row, 'arrival')}
            </p>
          </td>
          <td>
            <p className={styles.subtitle}>
              {validateCellData(row.departure) && row.key !== 0
                ? moment(row.departure).format(timeFormat)
                : ''}
            </p>
          </td>
          <td>
            <p className={styles.subtitle}>
              {validateCellData(row.movementTime) ? row.movementTime : ''}
            </p>
          </td>
        </tr>
      );
    };

    const reversedRoutesTable = (
      <div className={styles.wrapRoutesTable}>
        <h3>{t('settingSchedule.reverseDirection')}</h3>
        <table border="1" width="70%">
          <thead>{tableHeader}</thead>
          <tbody>
            {this.state.reverseRoutesData.map((item, index) => reversedRow(item, index))}
          </tbody>
        </table>
      </div>
    );

    const addRow = () => {
      let newRoutesData = [...this.state.routesData];

      newRoutesData.pop();
      newRoutesData.push(emptyRoute(Math.random()));

      let lastRoute = this.state.routesData[this.state.routesData.length - 1];
      lastRoute.key = lastKey;
      newRoutesData.push(lastRoute);

      setTableState(newRoutesData, 'add');
    };

    const deleteRow = () => {
      let newRoutesData = [...this.state.routesData];

      if (newRoutesData.length > 2) {
        newRoutesData.pop();

        newRoutesData[newRoutesData.length - 1] = this.state.routesData[
          this.state.routesData.length - 1
        ];
        newRoutesData[newRoutesData.length - 1].key = lastKey;

        setTableState(newRoutesData, 'delete');
      }
    };

    const dateToISO = (d) =>
      moment(d)
        .utcOffset(0, true)
        .format();

    const save = () => {
      const destinationLocalityValue = this.state.localities.find((item) => item.settlements === this.state.destinationLocality);
      const arrivalLocalityIdValue = this.state.localities.find((item) => item.settlements === this.state.arrivalLocality);

      let obj = {
        destinationLocality: this.state.destinationLocality,
        arrivalLocality: this.state.arrivalLocality,
        destinationLocalityId: destinationLocalityValue ? destinationLocalityValue.id : null,
        arrivalLocalityId: arrivalLocalityIdValue ? arrivalLocalityIdValue.id : null,
        routeDate: this.state.routeDate ? dateToISO(this.state.routeDate.toDate()) : null,
        transporters: this.state.transporterData.filter((item) => this.state.transporters.includes(item.id)),
        departureTimeFromStart: this.state.departureTimeFromStart ? dateToISO(this.state.departureTimeFromStart) : null,
        departureTimeFromEnd: this.state.departureTimeFromEnd ? dateToISO(this.state.departureTimeFromEnd) : null,
        routesData: this.state.routesData.map((item) => ({
          ...item,
          arrival: item.arrival ? dateToISO(item.arrival) : null,
          departure: item.departure ? dateToISO(item.departure) : null,
        })),
        reverseRoutesData: this.state.reverseRoutesData.map((item) => ({
          ...item,
          arrival: item.arrival ? dateToISO(item.arrival) : null,
          departure: item.departure ? dateToISO(item.departure) : null,
        })),
      };

      getNetwork().post(API_ROOT + '/passangers/save', JSON.stringify(obj), {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        if (res.status === 200) {
          getNetwork().get(API_ROOT + '/passangers/list?size=10&page=0').then(
            (res) => {
              const emptyData = [emptyRoute(0), emptyRoute(lastKey)];
              message.success('Расписание успешно создано');
              handleClickResetButton();
              this.setState({
                exportList: res.data.routes,
                exportListFiltered: res.data.routes,
                departureTimeFromStart: null,
                departureTimeFromEnd: null,
                periods: {
                  distancePeriod: [],
                  speedPeriod: [],
                },
                routesData: emptyData,
                reverseRoutesData: [...emptyData].reverse(),
                totalPages: res.data.total,
              });
            }
          );
        } else {
          message.error('Расписание не создано');
        }
      });
    };

    const onClickSaveButton = () => {
      message.info('Сделайте снимок карты перед сохранением маршрута', 5);
      this.setState({ isModal: true });
      window.ymaps.ready(initMapCreateRoute);
    };

    const handleOk = async () => {
      this.state.map.destroy();
      this.setState({ isModal: false, map: null });
      save();
    };

    const handleCancel = () => {
      this.state.map.destroy();
      this.setState({ isModal: false, map: null });
    };

    const getPoints = () => {
      const arrSettlementsTitle = [
        this.state.destinationLocality,
        ...this.state.routesData.reduce((acc, cur) => {
          if (cur.locality) {
            return acc.concat(cur.locality);
          }

          return acc;
        }, []),
        this.state.arrivalLocality,
      ];

      const arrCoordinate = arrSettlementsTitle.map((item) => {
        const result = this.state.localities.find((elem) => elem.settlements === item);

        return result ? [result.longitude, result.latitude] : null;
      });

      return arrCoordinate.filter(Boolean);
    };

    const initMapCreateRoute = () => {
      const points = getPoints();
      const hex = generateHex();
      const multiRoute = new window.ymaps.multiRouter.MultiRoute({
        referencePoints: points,
        params: {
          results: 1,
          viaIndexes: getViaIndexed(points.length),
        }
      }, {
        boundsAutoApply: true,
        zoomMargin: 30,
        balloonPanelMaxMapArea: 0,
        routeActiveStrokeColor: hex,
        pinActiveIconFillColor: hex,
        wayPointStartIconColor: hex,
        wayPointStartIconFillColor: hex,
        wayPointFinishIconColor: hex,
        wayPointFinishIconFillColor: hex,
        viaPointIconFillColor: hex,
        viaPointActiveIconFillColor: hex,
      });

      const polygon = new window.ymaps.GeoObject(
        {
          geometry: {
            type: 'LineString',
            coordinates: AkmolaCoords,
          },
        },
        {
          strokeColor: '#000000',
          strokeWidth: 3,
        }
      );

      const ymaps = new window.ymaps.Map('YMapsCreateRoute', {
        center: [51.960768, 69.914122],
        zoom: 7,
        boundsAutoApply: true
      },
      { boundsAutoApply: true });


      this.setState({ map: ymaps });
      ymaps.geoObjects.add(multiRoute).add(polygon);
    };

    return (
      <>
        {createRouteTabHeader}
        {routesTable}
        {reversedRoutesTable}
        <Button
          type='primary'
          onClick={onClickSaveButton}
          disabled={!(this.state.destinationLocality && this.state.arrivalLocality)}
        >
          Сохранить
        </Button>
        <Modal
          title="Маршрут"
          open={this.state.isModal}
          onCancel={handleCancel}
          width={1000}
          footer={[
            <Button key="confirm" type="primary" onClick={handleOk}>
              Подтвердить
            </Button>,
            <Button key="back" onClick={handleCancel}>
              Отменить
            </Button>,
          ]}
        >
          <div id="YMapsCreateRoute" style={{ height: 400 }} />
        </Modal>
      </>
    );
  };

  requestGetDistrict = async () => {
    const result = await getNetwork().get(API_ROOT + '/passangers/regions');

    if (result.status === 200) {
      this.setState({ districtData: result.data });
    }
  };

  requestGetSettlements = async () => {
    const result = await getNetwork().get(API_ROOT + '/passangers/settlements');

    if (result.status === 200) {
      this.setState({
        localities: result.data,
        searchDataLocalities: result.data,
        localitiesArrivalFiltered: result.data,
        localitiesDepartureFiltered: result.data
      });
    }
  }

  componentDidMount() {
    if (this.state.localities.length === 0) {
      this.requestGetSettlements();
    }
    if (this.state.districtData.length === 0) {
      this.requestGetDistrict();
    }
    if (this.state.exportList.length === 0) {
      getNetwork().get(API_ROOT + '/passangers/list?size=10&page=0').then((res) =>
        this.setState({
          exportList: res.data.routes,
          exportListFiltered: res.data.routes,
          totalPages: res.data.total,
        })
      );
    }
    this.requestTransporters();
  }

  componentDidUpdate(prevProps, prevState) {
    let result = this.state.exportList;

    if (prevState.filters !== this.state.filters) {
      if (this.state.filters.rangePicker.length) {
        result = result.filter((item) => item.routeDate
          && this.state.filters.rangePicker[0].startOf('day') < moment(item.routeDate)
          && moment(item.routeDate) < this.state.filters.rangePicker[1].endOf('day')
        );
      }

      if (this.state.filters.settlementsSelect.length) {
        result = result.filter(
          (item) => (
            this.state.filters.settlementsSelect.includes(item.arrivalLocality)
            || this.state.filters.settlementsSelect.includes(item.destinationLocality))
        );
      }

      if (this.state.filters.transportersSelect.length) {
        result = result.filter(
          (item) => item.transporters.find((elem) => this.state.filters.transportersSelect.includes(elem.id))
        );
      }

      this.setState({
        exportListFiltered: result
      });
    }
  }

  requestTransporters = async () => {
    const result = await getNetwork().get(API_ROOT + '/passangers/transporters');

    if (result.status === 200) {
      this.setState({ transporterData: result.data });
      this.setState({ searchData: result.data });
    }
  };

  render() {
    const { TabPane } = Tabs;

    return (
      <BasicLayout>
        <Tabs>
          <TabPane tab="Просмотр маршрутов" key="1">
            {this.exportListTab()}
          </TabPane>
          <TabPane tab="Создать маршрут" key="2">
            {this.createRouteTab()}
          </TabPane>
          <TabPane tab="Населенные пункты" key="3">
            <CreateDirectoryRouteTab request={this.requestGetSettlements} />
          </TabPane>
          <TabPane tab="Перевозчики" key="4">
            <CreateDirectoryInterpreterTab request={this.requestTransporters} />
          </TabPane>
        </Tabs>
      </BasicLayout>
    );
  }
}

export default withTranslation()(PassengerTransport);
