import React from 'react';
import { DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';


export const RangePicker = ({selectedKeys, setSelectedKeys}) => {
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;

  const disabledDate = current => {
    return current && current > moment().endOf('day');
  };

  return (
    <RangePicker
      value={selectedKeys}
      onChange={setSelectedKeys}
      disabledDate={disabledDate}
      placeholder={[t('userActions.startDate'), t('userActions.endDate')]}
    />
  )
};

