import { Button, Input, message, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { API_ROOT } from '../../../../config';
import { getNetwork } from '../../../../utils/getNetwork';

export const ServiceForm = ({ editData, onCancel }) => {
  const [formValues, setFormValues] = useState({
    id: null,
    serviceCode: null,
    nameOfService: null,
    organizationId: null,
    availableEdit: null,
    modelOrganizationId: null,
  });
  const [formData, setFormData] = useState({
    models: [],
    orgs: [],
    forms: [],
  });

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (editData) setFormValues(editData);
    return () => {
      setFormValues({
        id: null,
        serviceCode: null,
        nameOfService: null,
        organizationId: null,
        availableEdit: null,
        modelOrganizationId: null,
      });
    };
  }, [editData]);

  const getData = () => {
    Promise.all([
      getNetwork().get(API_ROOT + '/reestruslug/get/organization/id'),
      getNetwork().get(API_ROOT + '/reestruslug/get/model/organization'),
      getNetwork().get(API_ROOT + '/reestruslug/getForm'),
    ])
      .then((resArr) => resArr.map((i) => i.data))
      .then(([orgs, models, forms]) => setFormData({ models, orgs, forms }));
  };

  const handleChange = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const postData = () => {
    if (editData) {
      getNetwork()
        .post(API_ROOT + '/reestruslug/edit', formValues)
        .then((res) => {
          if (res)
            if (res.status === 200) {
              message.success('Услуга успешно изменена!');
              onCancel();
            }
        });
    } else
      getNetwork()
        .post(API_ROOT + '/reestruslug/create/newService', {
          ...formValues,
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              message.success('Услуга успешно создана!');
              onCancel();
            } else {
              message.error('У вас нет прав на создание услуг!');
              onCancel();
            }
          } else message.error('Код услуги уже существует');
        })
        .catch((err) => console.log('err', err));
  };

  return (
    <div>
      <div style={{ marginBottom: '10px' }}>
        <Select
          style={{ width: '100%' }}
          placeholder="Модель"
          value={formValues.modelOrganizationId}
          onChange={(val) => handleChange('modelOrganizationId', val)}
        >
          {formData.models.map((e) => (
            <Select.Option key={e.id} value={e.id}>
              {e.modelOrganization}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <Select
          style={{ width: '100%' }}
          placeholder="Услугодатель"
          onChange={(val) => handleChange('organizationId', val)}
        >
          {formData.orgs.map((e) => (
            <Select.Option key={e.id} value={e.id}>
              {e.organization}
            </Select.Option>
          ))}
        </Select>
      </div>

      <div style={{ marginBottom: '10px' }}>
        <Input
          placeholder="Код услуги"
          value={formValues.serviceCode}
          onChange={(e) => handleChange('serviceCode', e.target.value)}
        />
      </div>
      <div style={{ marginBottom: '10px' }}>
        <Input
          placeholder="Наименование услуги"
          value={formValues.nameOfService}
          onChange={(e) => handleChange('nameOfService', e.target.value)}
        />
      </div>
      <div style={{ marginBottom: '10px' }}>
        <Select
          style={{ width: '100%' }}
          placeholder="Форма оказания услуги"
          key="description"
          onChange={(val) => handleChange('formOfRendering', val)}
        >
          {formData.forms.map((el) => (
            <Select.Option key={el} value={el}>
              {el}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <Input
          type="number"
          placeholder="Стоимость оказания услуги"
          value={formValues.paidFree}
          onChange={(e) => handleChange('paidFree', e.target.value)}
        />
      </div>
      <div style={{ display: 'flex' }}>
        <Button type="primary" onClick={postData} style={{ marginRight: 15 }}>
          {editData ? 'Изменить' : 'Создать'}
        </Button>
        <Button onClick={onCancel}>Отмена</Button>
      </div>
    </div>
  );
};
