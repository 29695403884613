import React from 'react';
import { Select, Button } from 'antd';
import useFiltersChart from './hooks/useFiltersChart';
import styles from './FiltersChart.module.scss';

const { Option } = Select;

export const FiltersChart = ({
  formValues,
  formData,
  setReports,
  setCreatedReports,
  setFormValues,
  isListReport,
  setIsListReport,
  setLoading,
  setIsConsolidatedReport,
  isModalOpen,
  requestCreatedReadings,
}) => {
  const {
    handleChangeChartType,
    handleChangeDistrict,
    handleChangeOrg,
    handleChangeDateSelect,
    getChartData,
    handleClickResetButton,
  } = useFiltersChart({
    formValues,
    isModalOpen,
    setReports,
    setCreatedReports,
    setFormValues,
    isListReport,
    setIsListReport,
    requestCreatedReadings,
    setLoading,
    setIsConsolidatedReport,
  });

  return (
    <div className={styles.container}>
      <Select
        className={`${styles.select} ${styles.maxSelect}`}
        placeholder="Наименование отчёта"
        value={formValues.chartType}
        onChange={handleChangeChartType}
        allowClear
      >
        {formData.chartTypes.map(e => (
          <Option key={e.id} value={e.id}>
            {e.reportTitle}
          </Option>
        ))}
      </Select>
      <Select
        className={`${styles.select} ${styles.maxSelect}`}
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        placeholder="Район"
        value={formValues.district}
        onChange={handleChangeDistrict}
        allowClear
      >
        {formData.district.map(el => (
          <Option key={el} value={el}>
            {el}
          </Option>
        ))}
      </Select>
      <Select
        className={`${styles.select} ${styles.maxSelect}`}
        placeholder="Наименование организации"
        value={formValues.org}
        onChange={handleChangeOrg}
        allowClear
      >
        {formData.orgs.map(elem => (
          <Option key={elem.id} value={elem.id}>
            {elem.organization}
          </Option>
        ))}
      </Select>

      <Select
        className={`${styles.select} ${styles.minSelect}`}
        placeholder="Год"
        value={formValues.year}
        onChange={handleChangeDateSelect('year')}
        allowClear
      >
        {formData.years.map(el => (
          <Option key={el.id} value={el.id}>
            {el.year}
          </Option>
        ))}
      </Select>
      <Select
        className={`${styles.select} ${styles.minSelect}`}
        placeholder="Квартал"
        value={formValues.quarter}
        onChange={handleChangeDateSelect('quarter')}
        allowClear
      >
        {formData.quarter.map(el => (
          <Option key={el} value={el}>
            {el}
          </Option>
        ))}
      </Select>
      <Select
        className={`${styles.select} ${styles.minSelect}`}
        placeholder="Месяц"
        value={formValues.month}
        onChange={handleChangeDateSelect('month')}
        allowClear
      >
        {formData.months.map(elem => {
          if (!formValues.quarter || formValues.quarter === elem.quarter) {
            return (
              <Option key={elem.id} value={elem.id}>
                {elem.month}
              </Option>
            );
          }
        })}
      </Select>
      <Button
        className={styles.searchButton}
        type="primary"
        onClick={getChartData}
        disabled={!formValues.chartType || !formValues.year || !formValues.month}
      >
        Найти
      </Button>
      <Button onClick={handleClickResetButton}>Сбросить</Button>
    </div>
  );
};
