import React, { useEffect, useState, useCallback } from 'react';
import { Spin, Table } from 'antd';
import { API_ROOT } from '../../../../../config';
import { getNetwork } from '../../../../../utils/getNetwork';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/ru';
import styles from './ChartStatus.module.scss';
import { FilterChartStatus } from './components/FilterChartStatus';

const MONTHS = moment.months();

export const ChartStatus = ({ isAction }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [formValues, setFormValues] = useState({
    reportType: null,
    model: null,
    year: null,
  });
  const [formData, setFormData] = useState({
    reportTypes: [],
    models: [],
    years: [],
  });
  const [statusData, setStatusData] = useState([]);
  const { t } = useTranslation();
  
  const getData = useCallback(() => {
    const paths = [
      '/myReport/getTitle',
      '/myReport/getYear',
      '/reestruslug/get/model/organization',
    ];
    Promise.all(paths.map(path => getNetwork().get(`${API_ROOT}${path}`)))
      .then(responses => responses.map(response => response.data))
      .then(([reportTypes, years, models]) =>
        setFormData(prev => ({ ...prev, reportTypes, years, models }))
      )
      .finally(() => setIsLoading(false));
    }, []);
  
  const getBackgroundColor = (record, index) => {
    return record[MONTHS[index]] ? '#3BC14A' : '#DE3C4B';
  }

  const COLUMNS = [
    {
      title: '№',
      dataIndex: 'key',
      align: 'center',
    },
    {
      title: t('chartStatus.organization'),
      width: '28%',
      dataIndex: 'organization',
    },
    {
      title: MONTHS[0],
      dataIndex: 'months',
      render(record) {
        return {
          props: {
            style: { background: getBackgroundColor(record, 0) },
          },
        };
      },
    },
    {
      title: MONTHS[1],
      dataIndex: 'months',
      render(record) {
        return {
          props: {
            style: { background: getBackgroundColor(record, 1) },
          },
        };
      },
    },
    {
      title: MONTHS[2],
      dataIndex: 'months',
      render(record) {
        return {
          props: {
            style: { background: getBackgroundColor(record, 2) },
          },
        };
      },
    },
    {
      title: MONTHS[3],
      dataIndex: 'months',
      render(record) {
        return {
          props: {
            style: { background: getBackgroundColor(record, 3) },
          },
        };
      },
    },
    {
      title: MONTHS[4],
      dataIndex: 'months',
      render(record) {
        return {
          props: {
            style: { background: getBackgroundColor(record, 4) },
          },
        };
      },
    },
    {
      title: MONTHS[5],
      dataIndex: 'months',
      render(record) {
        return {
          props: {
            style: { background: getBackgroundColor(record, 5) },
          },
        };
      },
    },
    {
      title: MONTHS[6],
      dataIndex: 'months',
      render(record) {
        return {
          props: {
            style: { background: getBackgroundColor(record, 6) },
          },
        };
      },
    },
    {
      title: MONTHS[7],
      dataIndex: 'months',
      render(record) {
        return {
          props: {
            style: { background: getBackgroundColor(record, 7) },
          },
        };
      },
    },
    {
      title: MONTHS[8],
      dataIndex: 'months',
      render(record) {
        return {
          props: {
            style: { background: getBackgroundColor(record, 8) },
          },
        };
      },
    },
    {
      title: MONTHS[9],
      dataIndex: 'months',
      render(record) {
        return {
          props: {
            style: { background: getBackgroundColor(record, 9) },
          },
        };
      },
    },
    {
      title: MONTHS[10],
      dataIndex: 'months',
      render(record) {
        return {
          props: {
            style: { background: getBackgroundColor(record, 10) },
          },
        };
      },
    },
    {
      title: MONTHS[11],
      dataIndex: 'months',
      render(record) {
        return {
          props: {
            style: { background: getBackgroundColor(record, 11) },
          },
        };
      },
    },
  ];

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (formData.reportTypes.length && formData.years.length) {
      setFormValues({
        reportType: formData.reportTypes[0].id,
        model: null,
        year: formData.years.find(y => y.year === moment().format('YYYY')).id,
      });
    }
  }, [formData])

  useEffect(() => {
    if (statusData) {
      setIsLoading(false);
    }
  }, [statusData])

  return isAction ? (
    <div>
      <FilterChartStatus
        formValues={formValues}
        formData={formData}
        setFormValues={setFormValues}
        setStatusData={setStatusData}
        setIsLoading={setIsLoading}
      />
      <Spin tip={t('intersections.loadingPleaseWait') + '...'} spinning={isLoading}>
        <Table
          key="table"
          columns={COLUMNS}
          dataSource={statusData.map((data, index) => ({ ...data, key: index + 1 }))}
          className={styles.table}
          bordered
          pagination={false}
        />
      </Spin>
    </div>
  ) : (
    <div className={styles.errorTitle}>
      <h1>{t('error.doNotHaveAccessToViewThisModule')}</h1>
    </div>
  );
};
