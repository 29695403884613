import { getNetwork } from '../utils/getNetwork';
import { API_ROOT } from '../config';

export const fetchGovernmentServices = () => getNetwork().get(`${API_ROOT}/edu-service-registry/report-creation`);
export const fetchEventServices = () => getNetwork().get(`${API_ROOT}/edu-activity/report-creation`);
export const fetchSaveReportCreation = (data) => getNetwork().post(`${API_ROOT}/edu-report`, data);
export const fetchUploadFile = (file) => {
  const formData = new FormData();
  formData.append('file', file);

  return getNetwork().post(
    `${API_ROOT}/files/edu-activity-report`,
    formData,
  );
};

export const fetchFileById = (id) => getNetwork().get(`${API_ROOT}/files/${id}`);
export const fetchRemoveFileById = (id) => getNetwork().delete(`${API_ROOT}/files/${id}`);