import React, { useEffect } from 'react';
import { Table as AntdTable, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { useSelector } from 'react-redux';
import { useTableColumnsGovernmentsReport } from '../../../hooks/ReportSummary/useTableColumnsGovernmentsReport';
import { useTableColumnsServiceProvidersReport } from '../../../hooks/ReportSummary/useTableColumnsServiceProvidersReport';
import { useTableColumnsEventsReport } from '../../../hooks/ReportSummary/useTableColumnsEventsReport';
import { ExcelButton } from '../ExcelButton';
import { SummaryGovernmentServicesTable } from '../../../components/SummaryGovernmentServicesTable';

const getRowKey = (record) => record.uniqId;

export const Table = () => {
  const { t } = useTranslation();
  const tableReportData = useSelector((state) => state.reportSummary.tableReportData);
  const columnsGovernmentsReport = useTableColumnsGovernmentsReport();
  const columnsServiceProvidersReport = useTableColumnsServiceProvidersReport();
  const columnsEventsReport = useTableColumnsEventsReport();
  const formSelectorsReportData = useSelector((state) => state.reportSummary.formSelectorsReportData);
  const isSelectedFilters = useSelector((state) => state.reportSummary.isSelectedFilters);
  const isLoading = useSelector((state) => state.reportSummary.isLoading);
  const isGovernmentsTable = formSelectorsReportData.reportType === 'EDU_SERVICE_REPORT';
  const isEventsTable = formSelectorsReportData.reportType === 'EDU_ACTIVITY_REPORT';

  const columnsMap = {
    EDU_SERVICE_REPORT: columnsGovernmentsReport,
    EDU_ACTIVITY_REPORT: columnsEventsReport,
    EDU_PROVIDER_REPORT: columnsServiceProvidersReport,
  };

  useEffect(() => {
    if (!isSelectedFilters || !isGovernmentsTable) return;
    const listTh = document.querySelectorAll('th');
    listTh.forEach((th) => {
      if (!th.innerHTML) {
        th.remove();
      }
    });
  }, [isSelectedFilters, isGovernmentsTable]);

  return (
    <div className='container-table'>
      {isSelectedFilters && (
        <>
          <ExcelButton
            reports={tableReportData}
          />
          <Spin
            tip={t('intersections.loadingPleaseWait') + '...'}
            spinning={isLoading}
          >
            <AntdTable
              className={`table-report ${isEventsTable ? 'table-events' : ''}`}
              rowKey={(record) => getRowKey(record)}
              columns={columnsMap[formSelectorsReportData.reportType] || []}
              dataSource={tableReportData}
              pagination={tableReportData.length > 10}
              bordered
              scroll={{ x: isGovernmentsTable ? 1700 : undefined }}
              summary={
                isGovernmentsTable
                  ? (pageData) => <SummaryGovernmentServicesTable pageData={pageData} />
                  : null
              }
            />
          </Spin>
        </>
      )}
    </div>
  );
};