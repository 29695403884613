export const SET_ACTIVE_TAB = '@@REPORT_SUMMARY_DICTIONARIES/SET_ACTIVE_TAB';
export const SET_SELECTORS = '@@REPORT_SUMMARY_DICTIONARIES/SET_SELECTORS';
export const SET_REPORT_NAMES = '@@REPORT_SUMMARY_DICTIONARIES/SET_REPORT_NAMES';
export const SET_DISTRICT = '@@REPORT_SUMMARY_DICTIONARIES/SET_DISTRICT';
export const SET_LOCALITIES = '@@REPORT_SUMMARY_DICTIONARIES/SET_LOCALITIES';
export const SET_ORGANIZATIONS = '@@REPORT_SUMMARY_DICTIONARIES/SET_ORGANIZATIONS';
export const SET_ORGANIZATION_TYPES = '@@REPORT_SUMMARY_DICTIONARIES/SET_ORGANIZATION_TYPES';
export const SET_YEAR = '@@REPORT_SUMMARY_DICTIONARIES/SET_YEAR';
export const SET_MONTH = '@@REPORT_SUMMARY_DICTIONARIES/SET_MONTH';
export const LOAD_DICTIONARIES_START = '@@REPORT_SUMMARY_DICTIONARIES/LOAD_DICTIONARIES_START';
export const LOAD_DICTIONARIES_SUCCESS = '@@REPORT_SUMMARY_DICTIONARIES/LOAD_DICTIONARIES_SUCCESS';
export const LOAD_DICTIONARIES_ERROR = '@@REPORT_SUMMARY_DICTIONARIES/LOAD_DICTIONARIES_ERROR';