import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Divider, message, Spin, Table } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { API_AUTH, API_ROOT } from '../../../../config';
import { getNetwork } from '../../../../utils/getNetwork';
import { checkRole } from '../../../../utils/CheckRoleWithToken';
import { FiltersChart } from './components/FiltersChart/FiltersChart';
import { ExcelButton } from './components/ExcelButton/ExcelButton';
import { EditChart } from './components/EditChart';
import { testData } from './utils/test';
import { getColumns, REPORTS_TABLE } from './constants';
import styles from './Chart.module.scss';
import TextArea from 'antd/es/input/TextArea';

const roles = ['all', 'akm'];
const { Group: CheckboxGroup } = Checkbox;

export const Chart = ({ isAction }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(null);
  const [modalApprove, setModalApprove] = useState(false);
  const [modalFinalize, setModalFinalize] = useState(false);
  const [comment, setComment] = useState('');
  const [isFinalizeModal, setIsFinalizeModal] = useState(false);
  const [recordForFinalize, setRecordForFinalize] = useState([]);
  const [commentId, setCommentId] = useState(null);
  const [error, setError] = useState(false);
  const [createdReports, setCreatedReports] = useState([]);
  const [isListReport, setIsListReport] = useState(true);
  const [isConsolidatedReport, setIsConsolidatedReport] = useState(false);
  const [reports, setReports] = useState([]);
  const ref = useRef(null);
  const [formValues, setFormValues] = useState(
    JSON.parse(localStorage.getItem('formReport')) || {
      chartType: null,
      org: null,
      district: null,
      year: null,
      quarter: null,
      month: null,
      locality: null,
    },
  );

  const [formData, setFormData] = useState({
    chartTypes: [],
    orgs: [],
    years: [],
    months: [],
    quarter: [1, 2, 3, 4],
    district: [],
    locality: [],
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditAllowed, setEditAllowed] = useState(false);

  const handleClickReportDelete = (record) => async () => {
    const report = formData.chartTypes.find((item) => item.name.includes(record.reportName));
    if (!report) {
      message.error('Отчет не найден для удаления.');
      return;
    }
    const result = await getNetwork().delete(`${API_ROOT}/edu-report/${record.id}`);
    if (result.status === 200) {
      const organizationName = record.organization.nameRu || 'неизвестной организации';
      const monthName = record.month.nameRu || 'неизвестного месяца';
      const yearValue = record.year.year || 'неизвестного года';
      message.success(`Отчет от ${organizationName} за ${monthName} ${yearValue} успешно удален!`);
      requestCreatedReadings();
    } else {
      message.error('Вы не имеете права удалять данный отчет!');
    }
  };

  const handleClickReportApprove = (record) => async () => {
    try {
      await getNetwork().post(`${API_ROOT}/edu-report/${record.id}/approve`);
      message.success(t('gosServiceList.successApprove'));
      requestCreatedReadings();
    } catch (error) {
      console.error('Ошибка при удалении отчета:', error);
    }
  };

  const handleClickReportEdit = (record) => async () => {
    const { chartTypes, orgs, years, months } = formData;
    const { reportName, organization, district, year, month, id: recordId } = record;
    const result = await getNetwork().get(`${API_ROOT}/edu-report/${record.id}/upd-period-check`);
    if (result && result.status === 200 && result.data.isEnabled) {
      const chartType = chartTypes.find((chart) => chart.name.includes(reportName)).id;
      const org = orgs.find((orgObj) => orgObj.nameRu.includes(organization.nameRu)).id;
      const selectedYear = years.find((yearObj) => yearObj.year.includes(year.year)).id;
      const selectedMonth = months.find((monthObj) => monthObj.nameRu.includes(month.nameRu)).id;

      setIsModalOpen(true);
      setFormValues((prev) => ({
        ...prev,
        chartType,
        org,
        district,
        year: selectedYear,
        month: selectedMonth,
        recordId,
      }));
    } else {
      message.error('Срок редактирования данного отчета истёк');
    }
  };

  const getColumnSearchProps = (name, getValue) => ({
    filterDropdown: ({ selectedKeys, setSelectedKeys, clearFilters, confirm, close }) => (
      <div className={styles.box}>
        <div>
          <Checkbox.Group
            className={styles.checkboxGroup}
            options={createdReports.reduce((acc, cur) => {
              const value = getValue(cur); // Получаем значение через функцию
              if (!acc.includes(value)) {
                acc.push(value);
              }
              return acc;
            }, [])}
            value={selectedKeys}
            onChange={setSelectedKeys}
          />
        </div>
        <Divider className={styles.divider} />
        <div className={styles.wrapButton}>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
          >
            {t('roadRepairPlan.reset')}
          </Button>
          <Button
            onClick={() => {
              close();
              confirm();
            }}
            type="primary"
          >
            {t('userActions.choose')}
          </Button>
        </div>
      </div>
    ),
    onFilter: (value, record) => getValue(record) === value,
  });

  const getData = useCallback(async () => {
    try {
      const authPaths = ['/get/locality', '/get/organization', '/get/regions'];
      const dataPaths = [
        '/edu-report/kind',
        '/edu-report/year',
        '/edu-report/month',
      ];

      const [authResponses, dataResponses] = await Promise.all([
        Promise.all(authPaths.map((path) => getNetwork().get(`${API_AUTH}${path}`))),
        Promise.all(dataPaths.map((path) => getNetwork().get(`${API_ROOT}${path}`))),
      ]);

      const [locality, orgs, district] = authResponses.map((response) => response.data);
      const [chartTypes, years, months] = dataResponses.map((response) => response.data);

      setFormData((prev) => ({
        ...prev,
        locality,
        orgs,
        chartTypes,
        years,
        months,
        district,
      }));
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    }
  }, []);

  const requestCreatedReadings = useCallback(async () => {
    setLoading(true);
    try {
      const result = await getNetwork().get(API_ROOT + '/edu-report/filter');
      if (result && result.data) {
        const transformedData = result.data.reduce((acc, item) => {
          const categoryName = item.kind && item.kind.name;
          let key = null;
          if (!acc.reportOne.category) {
            key = 'reportOne';
          } else if (!acc.reportTwo.category && acc.reportOne.category !== categoryName) {
            key = 'reportTwo';
          } else if (!acc.reportThree.category && acc.reportOne.category !== categoryName && acc.reportTwo.category !== categoryName) {
            key = 'reportThree';
          }
          if (key) {
            acc[key] = {
              category: categoryName || '',
              data: [],
            };
          }
          const reportKey = ['reportOne', 'reportTwo', 'reportThree'].find((k) => acc[k] && acc[k].category === categoryName);
          if (reportKey) {
            acc[reportKey].data.push(item);
          }
          return acc;
        }, {
          reportOne: { category: '', data: [] },
          reportTwo: { category: '', data: [] },
          reportThree: { category: '', data: [] },
        });
        if (result.status === 200) {
          setCreatedReports(changeCreatedReportsData(transformedData));
        }
      }
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const changeCreatedReportsData = (dataReports) => {
    return Object.values(dataReports).flatMap(({ data, category }) =>
      data.map(({ region, organization, year, month, createdAt, locality, status, id }) => ({
        key: uuidv4(),
        id,
        reportName: category,
        district: region ? region.nameRu : '',
        locality: locality || '',
        status: status || '',
        organization: organization || '',
        year: year || '',
        month: month || '',
        createdAt: createdAt ? moment(createdAt).format('DD.MM.YYYY') : '',
      })),
    );
  };

  const handleRowClick = (record) => async () => {
    setLoading(true);

    const chartType = formData.chartTypes.find((chart) => chart.name.includes(record.reportName));
    const org = formData.orgs.find((orgObj) => orgObj.nameRu.includes(record.organization.nameRu));
    const year = formData.years.find((yearObj) => yearObj.year.includes(record.year.year));
    const month = formData.months.find((monthObj) => monthObj.nameRu.includes(record.month.nameRu));
    const district = formData.district.find((distObj) => distObj.nameRu.includes(record.district));

    setFormValues((prev) => ({
      ...prev,
      chartType: chartType && chartType.id ? chartType.id : null,
      org: org && org.id ? org.id : null,
      district: district || null,
      locality: record && record.locality && record.locality.id ? record.locality.id : null,
      year: year && year.id ? year.id : null,
      month: month && month.id ? month.id : null,
    }));

    try {
      const response = await getNetwork().get(`${API_ROOT}/edu-report/${record.id}`);
      if (response.status === 200) {
        const result = response.data;
        setReports(result.data);
        setIsConsolidatedReport(result.consolidatedReport);
      }
    } catch (error) {
      console.error('Error fetching report data:', error);
    } finally {
      setLoading(false);
      setIsListReport(false);
    }
  };

  const getComment = async (id) => {
    const { data } = await getNetwork().get(`${API_ROOT}/edu-report/${id}`);
    setComment(data.rejectedComment);
    setReports(data.data);
  };

  const openFinalizeModal = () => {
    const { chartTypes, orgs, years, months } = formData;
    const { reportName, organization, district, year, month, id: recordId } = recordForFinalize;
    const chartType = chartTypes.find((chart) => chart.name.includes(reportName)).id;
    const org = orgs.find((orgObj) => orgObj.nameRu.includes(organization.nameRu)).id;
    const selectedYear = years.find((yearObj) => yearObj.year.includes(year.year)).id;
    const selectedMonth = months.find((monthObj) => monthObj.nameRu.includes(month.nameRu)).id;

    setFormValues((prev) => ({
      ...prev,
      chartType,
      org,
      district,
      year: selectedYear,
      month: selectedMonth,
      recordId,
    }));

    setIsModalOpen(true);
    setIsFinalizeModal(true);
  };

  const downloadFile = (id) => {
    setLoadingFile(id);
    getNetwork()
      .get(`${API_ROOT}/files/${id}`, { responseType: 'blob' })
      .then((res) => {
        const blob = new Blob([res.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);

        const contentDisposition = res.headers['content-disposition'];
        let fileName = 'download.pdf';

        if (contentDisposition) {
          const match = contentDisposition.match(/filename="?([^"]+)"?/);
          if (match) fileName = match[1];
        }

        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
      })
      .catch((error) => {
        console.error('Ошибка при скачивании файла:', error);
      })
      .finally(() => {
        setLoadingFile(null);
      });
  };


  const handleCancelModal = () => {
    setIsModalOpen(false);
    setModalApprove(false);
    setComment('');
    setRecordForFinalize([]);
    setCommentId(null);
    setModalFinalize(false);
    setIsFinalizeModal(false);
    setReports([]);
    setFormValues({
      chartType: null,
      org: null,
      year: null,
      month: null,
      district: null,
    });
  };

  const handleCommentOk = async () => {
    if (!comment.trim()) {
      setError(true);
      return;
    }
    await getNetwork().post(`${API_ROOT}/edu-report/${commentId}/reject`, {
      comment: comment,
    });
    setModalApprove(false);
    requestCreatedReadings();
  };

  useEffect(() => {
    if (formValues.chartType === 1) {
      const listTh = document.querySelectorAll('th');
      listTh.forEach((th) => {
        if (!th.innerHTML) {
          th.remove();
        }
      });
    }
    localStorage.setItem('formReport', JSON.stringify(formValues));
  }, [formValues]);

  useEffect(() => {
    getData();
    requestCreatedReadings();
  }, [getData, requestCreatedReadings]);

  useEffect(() => {
    const roleList = checkRole();
    const result = roleList.filter((role) => roles.includes(role));
    if (result.length) {
      setEditAllowed(true);
    }
  }, []);

  useEffect(() => {
    if (!isListReport && ref.current) {
      const headers = ref.current.querySelectorAll('th');
      headers.forEach(th => {
        if (!th.textContent.trim()) {
          th.remove();
        }
      });
    }
  }, [isListReport]);

  console.log(reports, 'REPORTS IN CHART');

  return (
    <div>
      <FiltersChart
        formValues={formValues}
        formData={formData}
        setReports={setReports}
        setCreatedReports={setCreatedReports}
        setFormValues={setFormValues}
        isListReport={isListReport}
        setIsListReport={setIsListReport}
        setLoading={setLoading}
        setIsConsolidatedReport={setIsConsolidatedReport}
        isModalOpen={isModalOpen}
        requestCreatedReadings={requestCreatedReadings}
      />
      {formData.chartTypes.filter(i => i.id === formValues.chartType)[0] && (
        <ExcelButton
          formData={formData}
          formValues={formValues}
          reports={reports}
          isConsolidatedReport={isConsolidatedReport}
        />
      )}
      <Spin tip={t('intersections.loadingPleaseWait') + '...'} spinning={loading}>
        {isListReport ? (
          <Table
            bordered
            size="middle"
            columns={REPORTS_TABLE({
              handleRowClick,
              handleClickReportEdit,
              handleClickReportDelete,
              handleClickReportApprove,
              getComment,
              setCommentId,
              setRecordForFinalize,
              setModalApprove,
              setModalFinalize,
              getColumnSearchProps,
              t,
              styles,
            })}
            dataSource={createdReports}
          />

        ) : (
          <>
            <Table
              key="table"
              rowKey={(record) => (formValues.chartType === 2 ? record.uniqId : record.serviceCode || record.id)}
              ref={ref}
              columns={getColumns(formValues.chartType, t, downloadFile, loadingFile) || []}
              dataSource={formValues.chartType === 2 ? testData(reports) : reports}
              className={`table-${formValues.chartType} ${styles.table}`}
              bordered
              pagination={false}
              scroll={{
                x: formValues.chartType === 1 ? 1700 : null,
                y: window.innerHeight - 100,
              }}
            />
          </>
        )}
      </Spin>
      <Modal
        title={t(`addRoadRepairPlan.comment`)}
        open={modalApprove}
        width="50%"
        onOk={handleCommentOk}
        onCancel={handleCancelModal}
        cancelText={t('intersections.cancellation')}
        okText={t('governmentServiceEducation.save')}
      >
        <TextArea
          placeholder={t('governmentServiceEducation.placeholderComment')}
          value={comment}
          onChange={(e) => {
            setComment(e.target.value);
            setError(false);
          }}
          autoSize={{
            minRows: 3,
            maxRows: 6,
          }}
        />
        {error && <div style={{ color: 'red', marginTop: 5 }}>{t('error.ERROR01')}</div>}
      </Modal>
      <Modal
        title={null}
        footer={false}
        open={modalFinalize}
        width="30%"
        onOk={handleCommentOk}
        onCancel={handleCancelModal}
        cancelText={t('intersections.cancellation')}
        okText={t('governmentServiceEducation.save')}
      >
        <div className={styles.modalStyles}>
          <div className={styles.payAttentionHeaderText}>{t('intersections.pleasPayAttention')}</div>
          <div className={styles.payAttentionText}>
            {comment ? (
              `${t('intersections.reportWasSentBack')}: ${comment}`
            ) : (
              <Spin />
            )}
          </div>
          <Button onClick={openFinalizeModal}>Ознакомлен</Button>
        </div>
      </Modal>
      <Modal
        title={`Редактирование отчета - ${formValues.district}`}
        open={isModalOpen}
        width={'100%'}
        onOk={() => setIsModalOpen(true)}
        onCancel={handleCancelModal}
        footer={false}
      >
        <EditChart
          reports={reports}
          setReports={setReports}
          formValues={formValues}
          handleCancelModal={handleCancelModal}
          finalize={isFinalizeModal}
        />
      </Modal>
    </div>
  );
};
