export const AkmolaCoords = [
  [51.792558, 65.351348],
  [51.795417, 65.517836],
  [51.872330999999996, 65.759708],
  [51.920218, 65.767253],
  [52.000043, 65.820245],
  [52.079865999999996, 65.848944],
  [52.082547999999996, 65.86684699999999],
  [52.093114, 65.853019],
  [52.140632, 65.869506],
  [52.202087999999996, 65.99719999999999],
  [52.435855, 66.00778799999999],
  [52.453198, 66.07101999999999],
  [52.334773, 66.232965],
  [52.363267, 66.35876999999999],
  [52.376283, 66.476698],
  [52.365679, 66.533176],
  [52.312439, 66.552815],
  [52.28391, 66.545799],
  [52.278231, 66.779089],
  [52.247184, 66.924662],
  [52.253403999999996, 66.982783],
  [52.242670999999994, 67.04360299999999],
  [52.261798999999996, 67.091634],
  [52.208189, 67.298368],
  [52.428990999999996, 67.29553899999999],
  [52.430479999999996, 67.536766],
  [52.372420999999996, 67.604924],
  [52.363125, 67.707368],
  [52.277187999999995, 67.855223],
  [52.328047, 67.902104],
  [52.339974999999995, 67.883009],
  [52.377376999999996, 67.91672299999999],
  [52.353719999999996, 67.967261],
  [52.359562999999994, 67.97779799999999],
  [52.402336, 67.995903],
  [52.462696, 68.050386],
  [52.484179, 68.037032],
  [52.485366, 67.995167],
  [52.544796, 67.993968],
  [52.548061999999994, 68.13648599999999],
  [52.596419999999995, 68.151478],
  [52.597865999999996, 68.184018],
  [52.588578, 68.193721],
  [52.607904999999995, 68.223969],
  [52.61143, 68.337186],
  [52.658947999999995, 68.361778],
  [52.657381, 68.385155],
  [52.731685999999996, 68.438417],
  [52.759716, 68.490372],
  [52.740398, 68.529988],
  [52.785270999999995, 68.573657],
  [52.805876, 68.64159699999999],
  [52.795747999999996, 68.66375],
  [52.802558999999995, 68.682251],
  [52.859652, 68.641447],
  [52.881448999999996, 68.650126],
  [52.984868999999996, 68.618652],
  [53.05254, 68.768601],
  [53.082910999999996, 68.786553],
  [53.118958, 68.714826],
  [53.125127, 68.763345],
  [53.19968, 68.76588199999999],
  [53.209306999999995, 68.69475899999999],
  [53.263147, 68.700974],
  [53.262888, 68.593796],
  [53.328357, 68.545829],
  [53.330013, 68.526827],
  [53.366789999999995, 68.52554099999999],
  [53.395651, 68.494486],
  [53.405257, 68.508378],
  [53.458591999999996, 68.473231],
  [53.462319, 68.545945],
  [53.480630999999995, 68.578582],
  [53.525301, 68.607202],
  [53.521, 68.643819],
  [53.558808, 68.650531],
  [53.558057, 68.756897],
  [53.64566, 68.744745],
  [53.641662, 68.816429],
  [53.659594999999996, 68.81751],
  [53.647051, 68.887847],
  [53.619833, 68.902879],
  [53.619915999999996, 69.02367199999999],
  [53.683049999999994, 69.015822],
  [53.690462999999994, 69.12231299999999],
  [53.607425, 69.117712],
  [53.602565999999996, 69.23696799999999],
  [53.57333, 69.237507],
  [53.569787999999996, 69.275671],
  [53.546219, 69.277669],
  [53.547309, 69.30303599999999],
  [53.501104, 69.305398],
  [53.495011, 69.402163],
  [53.517765999999995, 69.427202],
  [53.550760999999994, 69.423569],
  [53.561823999999994, 69.371039],
  [53.604281, 69.37356799999999],
  [53.608306999999996, 69.42594],
  [53.632873, 69.435135],
  [53.651916, 69.476226],
  [53.652975999999995, 69.504842],
  [53.672782, 69.50460199999999],
  [53.67172, 69.811714],
  [53.63588, 69.810298],
  [53.636869999999995, 69.848449],
  [53.530291, 69.844115],
  [53.527494, 69.875841],
  [53.53787, 69.877393],
  [53.528472, 69.929754],
  [53.534141999999996, 69.974149],
  [53.510555, 70.02648099999999],
  [53.516189999999995, 70.070869],
  [53.446396, 70.072366],
  [53.412472, 70.01697399999999],
  [53.37569, 70.01381599999999],
  [53.358717, 69.990127],
  [53.353829999999995, 70.18595599999999],
  [53.367020999999994, 70.193906],
  [53.366032999999995, 70.217597],
  [53.301711999999995, 70.29932],
  [53.230886, 70.331929],
  [53.226971, 70.377561],
  [53.239895999999995, 70.454844],
  [53.318185, 70.45248099999999],
  [53.345883, 70.585388],
  [53.425115, 70.583276],
  [53.422081, 71.02452699999999],
  [53.411736999999995, 71.02112],
  [53.413228999999994, 71.065434],
  [53.330647, 71.01921899999999],
  [53.329530999999996, 71.14071899999999],
  [53.319052, 71.151489],
  [53.308541999999996, 71.256902],
  [53.327999999999996, 71.289029],
  [53.356908, 71.400435],
  [53.386792, 71.42510899999999],
  [53.402639, 71.515746],
  [53.329206, 71.577882],
  [53.213701, 71.74368199999999],
  [53.129597, 71.69004699999999],
  [53.068977, 71.70809799999999],
  [53.051759, 71.658816],
  [53.007366999999995, 71.663403],
  [52.946186999999995, 71.720531],
  [52.91892, 72.013363],
  [52.91652, 72.250863],
  [52.989594999999994, 72.325085],
  [52.987373, 72.525392],
  [53.018263999999995, 72.66507299999999],
  [53.003867, 72.71745299999999],
  [53.021038, 72.749865],
  [53.024432, 72.845681],
  [52.933834, 72.847599],
  [52.911113, 72.889889],
  [52.717099999999995, 72.91606],
  [52.748121999999995, 73.069172],
  [52.724719, 73.204514],
  [52.705344, 73.25756],
  [52.712306, 73.312538],
  [52.701408, 73.397284],
  [52.65784, 73.435892],
  [52.66598, 73.480051],
  [52.607668, 73.537556],
  [52.595586999999995, 73.919952],
  [52.532213, 73.920448],
  [52.530010999999995, 74.001048],
  [51.924405, 74.00643199999999],
  [51.923527, 74.02539399999999],
  [51.891189, 74.019181],
  [51.883306, 73.965542],
  [51.798113, 73.81947],
  [51.730886, 73.78843599999999],
  [51.676511999999995, 73.803623],
  [51.639233, 73.79355199999999],
  [51.617650999999995, 73.81093],
  [51.623324999999994, 73.946975],
  [51.638346, 73.99705],
  [51.633021, 74.01928199999999],
  [51.599371, 74.046972],
  [51.551548, 74.23539199999999],
  [51.522873999999995, 74.251983],
  [51.52559, 74.161926],
  [51.501490999999994, 74.103719],
  [51.495259, 73.99426799999999],
  [51.427713999999995, 73.805557],
  [51.380964999999996, 73.79864599999999],
  [51.343705, 73.698764],
  [51.275665, 73.668576],
  [51.298584999999996, 73.51571299999999],
  [51.312557, 73.48769],
  [51.27281, 73.449828],
  [51.274522, 73.416246],
  [51.307066, 73.4012],
  [51.305023999999996, 73.370807],
  [51.28758, 73.368375],
  [51.279571, 73.342218],
  [51.273098999999995, 73.287122],
  [51.307418, 73.23372499999999],
  [51.286615999999995, 73.220647],
  [51.275779, 73.162937],
  [51.211883, 73.20501999999999],
  [51.222989999999996, 73.25222699999999],
  [51.203913, 73.282228],
  [51.122273, 73.33093099999999],
  [51.124626, 73.35079499999999],
  [51.165803999999994, 73.365354],
  [51.161555, 73.411427],
  [51.174476999999996, 73.443691],
  [51.115719, 73.578356],
  [51.087509, 73.49746999999999],
  [51.029978, 73.419169],
  [50.998763, 73.206602],
  [50.985552, 73.182597],
  [50.922878999999995, 73.179509],
  [50.885403, 73.023684],
  [50.859359, 72.986306],
  [50.885191, 72.79661399999999],
  [50.835589999999996, 72.73380999999999],
  [50.748262, 72.65982699999999],
  [50.733681999999995, 72.52689699999999],
  [50.708776, 72.53131499999999],
  [50.70691, 72.516291],
  [50.743091, 72.49637299999999],
  [50.669944, 72.399614],
  [50.622718, 72.460279],
  [50.594657, 72.476018],
  [50.564498, 72.452709],
  [50.556455, 72.382485],
  [50.593576, 72.276664],
  [50.55789, 72.23491299999999],
  [50.537732, 72.181349],
  [50.452773, 72.198061],
  [50.443884, 72.125725],
  [50.413905, 72.12897099999999],
  [50.455487, 72.047435],
  [50.467670999999996, 71.974842],
  [50.465948999999995, 71.80913799999999],
  [50.428875, 71.799765],
  [50.430926, 71.710807],
  [50.446956, 71.712514],
  [50.455341999999995, 71.737935],
  [50.494895, 71.777074],
  [50.561873, 71.78755199999999],
  [50.587727, 71.814852],
  [50.614852, 71.806708],
  [50.630756999999996, 71.768332],
  [50.674994, 71.83421299999999],
  [50.688815999999996, 71.83703799999999],
  [50.705214999999995, 71.814705],
  [50.694556, 71.795935],
  [50.754624, 71.731529],
  [50.753426, 71.662528],
  [50.790167999999994, 71.644252],
  [50.77465, 71.550584],
  [50.791754999999995, 71.525832],
  [50.760301, 71.474254],
  [50.726203, 71.334223],
  [50.654711999999996, 71.33225],
  [50.653588, 71.233588],
  [50.612251, 71.20847499999999],
  [50.614442999999994, 71.134044],
  [50.558239, 71.05605299999999],
  [50.512982, 71.05736499999999],
  [50.473076999999996, 70.942255],
  [50.478851999999996, 70.853237],
  [50.449256999999996, 70.805909],
  [50.442184999999995, 70.771541],
  [50.256232, 70.74821899999999],
  [50.238864, 70.724086],
  [50.294737999999995, 70.65203699999999],
  [50.236464, 70.46711599999999],
  [50.152936, 70.546955],
  [50.141343, 70.527545],
  [50.074847999999996, 70.338785],
  [50.040909, 70.12683799999999],
  [50.057711, 70.105345],
  [50.082504, 70.135975],
  [50.110989, 70.109948],
  [50.194872, 70.166862],
  [50.221903, 70.141927],
  [50.207319, 70.12712599999999],
  [50.203699, 70.08053699999999],
  [50.23435, 70.08851899999999],
  [50.259178, 70.051007],
  [50.238001, 69.977098],
  [50.20986, 69.719184],
  [50.192992, 69.690899],
  [50.198491, 69.589745],
  [50.275124, 69.58788],
  [50.306317, 69.53976],
  [50.299625, 69.511366],
  [50.321438, 69.492898],
  [50.335052999999995, 69.44032899999999],
  [50.350494, 69.462931],
  [50.386981999999996, 69.461347],
  [50.401441999999996, 69.433787],
  [50.366448999999996, 69.44221399999999],
  [50.34227, 69.424285],
  [50.403977999999995, 69.36300899999999],
  [50.38489, 69.34389],
  [50.369854, 69.273414],
  [50.354392999999995, 69.253145],
  [50.301857999999996, 69.25628999999999],
  [50.302780999999996, 69.285879],
  [50.267754, 69.287572],
  [50.255503999999995, 69.31279099999999],
  [50.244471, 69.299312],
  [50.24641, 69.26061299999999],
  [50.232265, 69.21991299999999],
  [50.237925999999995, 69.19479799999999],
  [50.250383, 69.201402],
  [50.240009, 69.17997799999999],
  [50.276426, 69.169079],
  [50.259485999999995, 69.148909],
  [50.274283999999994, 69.11236699999999],
  [50.254377, 69.149006],
  [50.188610999999995, 69.14000899999999],
  [50.207091, 69.077157],
  [50.185907, 69.075313],
  [50.197299, 69.04099599999999],
  [50.188911999999995, 68.920754],
  [50.209312999999995, 68.84299299999999],
  [50.196718, 68.82512899999999],
  [50.218787, 68.77227599999999],
  [50.217759, 68.745021],
  [50.237338, 68.733117],
  [50.24507, 68.706746],
  [50.249313, 68.632688],
  [50.259584, 68.63693599999999],
  [50.268083, 68.676515],
  [50.316053, 68.721846],
  [50.357547999999994, 68.711576],
  [50.406558999999994, 68.720477],
  [50.433186, 68.751711],
  [50.463724, 68.740599],
  [50.544211, 68.757849],
  [50.582051, 68.81524399999999],
  [50.799495, 68.807289],
  [50.794475999999996, 68.503635],
  [50.762631, 68.46902399999999],
  [50.726251, 68.320855],
  [50.661677, 68.249764],
  [50.621075999999995, 68.12861699999999],
  [50.641306, 68.071591],
  [50.664322999999996, 68.05112],
  [50.663891, 67.901939],
  [50.611174999999996, 67.93413199999999],
  [50.554592, 67.933345],
  [50.497389, 67.83145],
  [50.485552999999996, 67.78434],
  [50.481964999999995, 67.64926299999999],
  [50.492675999999996, 67.56371],
  [50.469496, 67.478655],
  [50.451083, 67.49344099999999],
  [50.383561, 67.131256],
  [50.530155, 67.063951],
  [50.545437, 67.09387],
  [50.620669, 67.108676],
  [50.658733, 66.991674],
  [50.649789999999996, 66.86434799999999],
  [50.613378999999995, 66.72519199999999],
  [50.637498, 66.666265],
  [50.6284, 66.64091499999999],
  [50.669613999999996, 66.50298099999999],
  [50.710059, 66.43285999999999],
  [50.685688999999996, 66.314517],
  [50.82723, 66.239627],
  [50.826428, 66.200138],
  [50.804254, 66.180607],
  [50.81218, 66.16036799999999],
  [50.789099, 66.13532099999999],
  [50.799672, 66.109977],
  [50.814997, 66.091244],
  [50.823065, 66.102383],
  [50.83379, 66.075026],
  [50.855019999999996, 66.070071],
  [50.871683999999995, 66.088264],
  [50.885914, 66.056586],
  [50.892131, 66.07423],
  [50.928103, 66.00879499999999],
  [65.99381699999999],
  [50.949926999999995, 65.949666, 50.960169],
  [50.943202, 65.94165699999999],
  [50.965865, 65.863585],
  [50.866035, 65.813144],
  [50.849706, 65.788033],
  [50.847328, 65.661789],
  [50.826744999999995, 65.61911599999999],
  [50.895018, 65.578576],
  [50.999809, 65.611414],
  [51.054437, 65.60933899999999],
  [51.083551, 65.63089099999999],
  [51.135435, 65.559943],
  [51.204677, 65.424628],
  [51.242756, 65.479826],
  [51.302434999999996, 65.401636],
  [51.327799999999996, 65.386883],
  [51.403330999999994, 65.545705],
  [51.440568, 65.459729],
  [51.513796, 65.53672],
  [51.62811, 65.578323],
  [51.682840999999996, 65.523124],
  [51.68466, 65.384006],
];