import React from 'react';
import ExportExcel from '../ExportExcel';
import styles from './ExcelButton.module.scss';
import { useSelector } from 'react-redux';

const dictionaryChartType = {
  'EDU_SERVICE_REPORT': 1,
  'EDU_PROVIDER_REPORT': 3,
};

const dictionaryTitle = {
  'EDU_SERVICE_REPORT': 'Отчет по внутреннему контролю за качеством оказания государственных услуг',
  'EDU_ACTIVITY_REPORT': 'Отчет о выполнении мероприятий по госуслугам',
  'EDU_PROVIDER_REPORT': 'Отчет по услугодателям',
};

export const ExcelButton = ({ reports }) => {
  const formValues = useSelector((state) => state.reportSummary.formSelectorsReportData);

  return (
    <div className={styles.container}>
      <h3 className={styles.subtitle}>
        {dictionaryTitle[formValues.reportType]}
      </h3>
      <ExportExcel
        fileName={dictionaryTitle[formValues.reportType]}
        disabled={
          formValues.reportType === 'EDU_ACTIVITY_REPORT'
        }
        chartType={dictionaryChartType[formValues.reportType]}
        data={reports}
      />
    </div>
  );
};
