import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { withRouter } from 'react-router-dom';
import HeaderComponent from './HeaderComponent';
import SidebarComponent from './SidebarComponent';
import { useDispatch } from 'react-redux';
import { loadCurrentUser } from '../../redux/asyncActions/currentUser';
const { Content } = Layout;

function BasicLayout(props) {
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useDispatch();

  const handleCollapseMenu = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    dispatch(loadCurrentUser());
  }, [dispatch]);

  return (
    <Layout>
      <HeaderComponent
        collapsed={collapsed}
        handleCollapseMenu={handleCollapseMenu}
      />
      <Layout>
        <SidebarComponent route={props.location} collapsed={collapsed} />
        <Layout>
          <Content
            style={{
              background: '#fff',
              padding: 24,
              margin: 0,
              // minHeight: "250%",
            }}
          >
            {props.children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default withRouter(BasicLayout);
