import { useDispatch, useSelector } from 'react-redux';
import { InputNumber } from 'antd';
import React from 'react';
import { setTableReportData } from '../../../../redux/actions/reportCreation';
import { physFieldsToSum, jurFieldsToSum } from '../../constants';
import { useTranslation } from 'react-i18next';

export const useTableColumnsGovernmentsReport = (governmentServices) => {
  const dispatch = useDispatch();
  const tableReportData = useSelector((state) => state.reportCreation.tableReportData);
  const { t } = useTranslation();

  const generateFieldForm = (record, field) => {
    const handleChangeInputNumber = (value) => {
      const updatedTableData = tableReportData.map((item) => {
        if (item.serviceId === record.serviceId) {
          const updatedItem = {
            ...item,
            [field]: value !== null ? value : 0,
          };

          const totalPhys = physFieldsToSum.reduce((acc, col) => {
            return acc + (updatedItem[col] || 0);
          }, 0);

          const totalJur = jurFieldsToSum.reduce((acc, col) => {
            return acc + (updatedItem[col] || 0);
          }, 0);

          updatedItem.totalGovServicesProvidedPhys = totalPhys;
          updatedItem.totalGovServicesProvidedJur = totalJur;

          return updatedItem;
        }
        return item;
      });

      dispatch(setTableReportData(updatedTableData));
    };

    return (
      <InputNumber
        min={0}
        value={record[field]}
        onChange={handleChangeInputNumber}
      />
    );
  };

  return [
    {
      title: t('governmentServiceEducation.serialNumber'),
      align: 'center',
      width: 100,
      render: (_text, _record, index) => index + 1,
    },
    {
      title: t('governmentServiceEducation.serviceCode'),
      dataIndex: 'serviceId',
      align: 'center',
      render: (text, record) => {
        const service = governmentServices.find((service) => service.id === record.serviceId);

        return service ? service.code : text;
      }
    },
    {
      title: t('governmentServiceEducation.governmentServicesName'),
      key: 'nameOfService',
      width: 200,
      align: 'center',
      render: (_, record) => {
        const service = governmentServices.find((service) => service.id === record.serviceId);

        return service ? service.name : '';
      }
    },
    {
      title: t('governmentServiceEducation.totalServicesProvided'),
      dataIndex: 'summary',
      key: 'summary',
      rowSpan: 3,
      children: [
        {
          children: [
            {
              children: [
                {
                  title: t('governmentServiceEducation.individuals'),
                  dataIndex: 'totalGovServicesProvidedPhys',
                  align: 'center',
                  className: 'fizColumn summaryColumn',
                },
                {
                  title: t('governmentServiceEducation.legalEntities'),
                  dataIndex: 'totalGovServicesProvidedJur',
                  align: 'center',
                  className: 'yrColumn summaryColumn',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: t('governmentServiceEducation.providedThrough'),
      children: [
        {
          title: t('governmentServiceEducation.governmentCorporation'),
          rowSpan: 2,
          children: [
            {
              children: [
                {
                  title: t('governmentServiceEducation.individuals'),
                  dataIndex: 'governmentForCitizenPhys',
                  className: 'fizColumn',
                  align: 'center',
                  render: (_, record) => generateFieldForm(record, 'governmentForCitizenPhys')
                },
                {
                  title: t('governmentServiceEducation.legalEntities'),
                  dataIndex: 'governmentForCitizenJur',
                  className: 'yrColumn',
                  align: 'center',
                  render: (_, record) => generateFieldForm(record, 'governmentForCitizenJur')
                },
              ],
            },
          ],
        },
        {
          title: t('governmentServiceEducation.eGovernmentPortal'),
          rowSpan: 2,
          children: [
            {
              children: [
                {
                  title: t('governmentServiceEducation.individuals'),
                  dataIndex: 'eGovernmentPortalPhys',
                  className: 'fizColumn',
                  align: 'center',
                  render: (_, record) => generateFieldForm(record, 'eGovernmentPortalPhys')
                },
                {
                  title: t('governmentServiceEducation.legalEntities'),
                  dataIndex: 'eGovernmentPortalJur',
                  className: 'yrColumn',
                  align: 'center',
                  render: (_, record) => generateFieldForm(record, 'eGovernmentPortalJur')
                },
              ],
            },
          ],
        },
        {
          title: t('governmentServiceEducation.governmentAgency'),
          children: [
            {
              title: t('governmentServiceEducation.paperForm'),
              children: [
                {
                  title: t('governmentServiceEducation.individuals'),
                  dataIndex: 'paperFormPhys',
                  className: 'fizColumn',
                  align: 'center',
                  render: (_, record) => generateFieldForm(record, 'paperFormPhys')
                },
                {
                  title: t('governmentServiceEducation.legalEntities'),
                  dataIndex: 'paperFormJur',
                  className: 'yrColumn',
                  align: 'center',
                  render: (_, record) => generateFieldForm(record, 'paperFormJur')
                },
              ],
            },
            {
              title: t('governmentServiceEducation.eServiceNoContact'),
              children: [
                {
                  title: t('governmentServiceEducation.individuals'),
                  dataIndex: 'eServiceProviderInfoNoContactPhys',
                  className: 'fizColumn',
                  align: 'center',
                  render: (_, record) => generateFieldForm(record, 'eServiceProviderInfoNoContactPhys')
                },
                {
                  title: t('governmentServiceEducation.legalEntities'),
                  dataIndex: 'eServiceProviderInfoNoContactJur',
                  className: 'yrColumn',
                  align: 'center',
                  render: (_, record) => generateFieldForm(record, 'eServiceProviderInfoNoContactJur')
                },
              ],
            },
            {
              title: t('governmentServiceEducation.eServiceWithContact'),
              children: [
                {
                  title: t('governmentServiceEducation.individuals'),
                  dataIndex: 'eFormServiceProviderInfoDirContactManualPhys',
                  className: 'fizColumn',
                  align: 'center',
                  render: (_, record) => generateFieldForm(record, 'eFormServiceProviderInfoDirContactManualPhys')
                },
                {
                  title: t('governmentServiceEducation.legalEntities'),
                  dataIndex: 'eFormServiceProviderInfoDirContactManualJur',
                  className: 'yrColumn',
                  align: 'center',
                  render: (_, record) => generateFieldForm(record, 'eFormServiceProviderInfoDirContactManualJur')
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: t('governmentServiceEducation.justifiedRefusals'),
      key: 'justifiedRefusals',
      rowSpan: 2,
      className: 'refusalColumn',
      children: [
        {
          children: [
            {
              title: t('governmentServiceEducation.electronicForm'),
              dataIndex: 'eJustifiedRefusalCount',
              align: 'center',
              className: 'refusalColumn summaryColumn',
              rowSpan: 2,
              render: (_, record) => generateFieldForm(record, 'eJustifiedRefusalCount')
            },
            {
              title: t('governmentServiceEducation.paperFormShort'),
              dataIndex: 'paperJustifiedRefusalCount',
              align: 'center',
              className: 'refusalColumn summaryColumn',
              rowSpan: 2,
              render: (_, record) => generateFieldForm(record, 'paperJustifiedRefusalCount')
            },
          ],
        },
      ],
    },
    {
      title: t('governmentServiceEducation.unjustifiedRefusals'),
      key: 'unjustifiedRefusals',
      rowSpan: 2,
      className: 'refusalColumn',
      children: [
        {
          children: [
            {
              title: t('governmentServiceEducation.electronicForm'),
              dataIndex: 'eUnjustifiedRefusalCount',
              align: 'center',
              className: 'refusalColumn summaryColumn',
              rowSpan: 2,
              render: (_, record) => generateFieldForm(record, 'eUnjustifiedRefusalCount')
            },
            {
              title: t('governmentServiceEducation.paperFormShort'),
              dataIndex: 'paperUnjustifiedRefusalCount',
              align: 'center',
              className: 'refusalColumn summaryColumn',
              rowSpan: 2,
              render: (_, record) => generateFieldForm(record, 'paperUnjustifiedRefusalCount')
            },
          ],
        },
      ],
    },
  ];
};