import React, { Component } from 'react';
import { YMaps, Map } from 'react-yandex-maps';
const mapState = {
  center: [43.239543, 76.916644],
  zoom: 9
};

class HomePage extends Component {

  onApiAvaliable(ymaps) {
    this.ymaps = ymaps;
  }

  render() {
    return (
      <div className="map-wrapper">
        <YMaps
          onApiAvaliable={(ymaps) => this.onApiAvaliable(ymaps)}
        >
          <Map
            state={mapState}
            width='100%'
            height='400'
            instanceRef={(ref) => this.map = ref}
          />
        </YMaps>
      </div>
    );
  }
}
export default HomePage;