import { chain, isArray } from 'lodash';

export const combineWithNumbers = (data, parentNumber = '') => {
  return data.map((obj, index) => {
    const number = `${parentNumber ? parentNumber + '.' : ''}${index + 1}`;
    if (isArray(obj.children)) {
      return {
        ...obj,
        uniqId: number,
        children: combineWithNumbers(obj.children, number),
      };
    }
    return {
      ...obj,
      uniqId: number,
      count: obj.count || 0,
    };
  });
};

export const getGroupedData = (data, groupType, topLevel) =>
  chain(data)
    .groupBy((item) => {
      if (groupType === 'chapter') {
        return item.chapter && item.chapter.name;
      }
      if (groupType === 'category') {
        return item.category && item.category.name;
      }
      if (groupType === 'subCategory') {
        return item.subCategory && item.subCategory.name;
      }
      return undefined;
    })
    .map((groupItems, groupKey) => {
      const [first] = groupItems;
      const countTotal = groupItems.reduce(
        (sum, cur) => sum + (cur.count || 0),
        0
      );

      if (groupType === 'category') {
        const additionalFieldsForCategory = topLevel === 'category' ? {
          isAttachmentRequired: first.category.isAttachmentRequired,
          isDeleted: first.isDeleted,
          isServiceCodeRequired: first.isServiceCodeRequired,
          isUrlRequired: first.isUrlRequired,
          urls: [],
          codes: [],
        } : {};
        return {
          count: countTotal,
          id: first.category && first.category.id ? first.category.id : null,
          type: groupType,
          isAttachmentRequired: first.category.isAttachmentRequired,
          name: groupKey,
          files: [],
          eventIds: groupItems.map((item) => item.id),
          ...additionalFieldsForCategory,
          children: groupItems.some((item) => item.subCategory)
            ? groupItems
            : null,
        };
      }

      if (groupType === 'subCategory') {
        if (!first.subCategory) return null;
        return {
          count: countTotal,
          id: first.subCategory && first.subCategory.id || null,
          type: groupType,
          name: groupKey,
          eventIds: groupItems.map((item) => item.id),
          isAttachmentRequired: first.subCategory.isAttachmentRequired,
          isDeleted: first.isDeleted,
          isServiceCodeRequired: first.isServiceCodeRequired,
          isUrlRequired: first.isUrlRequired,
          urls: [],
          codes: [],
          files: [],
        };
      }

      if (!first.chapter) {
        return null;
      }
      return {
        count: countTotal,
        id: first.chapter.id,
        isAttachmentRequired: first.chapter.isAttachmentRequired,
        type: groupType,
        name: groupKey,
        files: [],
        children: groupItems,
      };
    })
    .filter(Boolean)
    .value();

export const groupData = (data) => {
  const filteredData = data.filter((item) => item.nameOfService !== 'Итого');

  const withChapter = filteredData.filter((item) => item.chapter);
  const withoutChapter = filteredData.filter((item) => !item.chapter);

  const groupedChapter = getGroupedData(withChapter, 'chapter', 'chapter');
  const groupedCategory = groupedChapter.map((chapter) => ({
    ...chapter,
    children: chapter.children
      ? getGroupedData(chapter.children, 'category', 'chapter')
      : null,
  }));
  const groupedSubcategory = groupedCategory.map((chapter) => ({
    ...chapter,
    children: chapter.children
      ? chapter.children.map((category) => ({
        ...category,
        children: category.children
          ? getGroupedData(category.children, 'subCategory', 'chapter')
          : null,
      }))
      : null,
  }));

  const topLevelCategories = getGroupedData(withoutChapter, 'category', 'category');
  const topLevelSubcategories = topLevelCategories.map((category) => ({
    ...category,
    children: category.children
      ? getGroupedData(category.children, 'subCategory', 'category')
      : null,
  }));

  const merged = [...groupedSubcategory, ...topLevelSubcategories];

  return combineWithNumbers(merged);
};
