import React, { Component } from 'react';
import { connect } from 'react-redux';

import BasicLayout from '../../components/layout/BasicLayout';
import { API_AUTH } from '../../config';
import {
  Spin,
  Table,
  Modal,
  Input,
  message,
  Button,
  Divider,
  Form,
  Select,
} from 'antd';
import { withTranslation } from 'react-i18next';
import { getNetwork } from '../../utils/getNetwork';

class UsersList extends Component {
  constructor() {
    super();
    this.state = {
      users: [],
      roles: [],
      regions: [],
      loading: true,
      showModal: false,
      selected: '',
      inputValue: '',
      showAddModal: false,
      login: '',
      password: '',
      fullname: '',
      phone: '',
      role: {},
      districts: {},
      homePage: '',
      userChange: '',
      example: {},
      tableData: [],
      organisations: [],
      organizations: null,
    };
    this.openModal = this.openModal.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.changeInputValue = this.changeInputValue.bind(this);
    this.changeInputValue = this.changeInputValue.bind(this);
    this.openAddModal = this.openAddModal.bind(this);
    this.checkAddUserInput = this.checkAddUserInput.bind(this);
    this.onUserChangeRole = this.onUserChangeRole.bind(this);
    this.onUserChangeRegion = this.onUserChangeRegion.bind(this);
    this.onUserAddRole = this.onUserAddRole.bind(this);
    this.onUserAddRegion = this.onUserAddRegion.bind(this);
    this.handleChangeOrgs = this.handleChangeOrgs.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    getNetwork().get(API_AUTH + '/get/users').then((r) => {
      this.setState({
        users: r.data,
        loading: false,
        tableData: r.data.map((el) => ({
          login: el.login,
          fullname: el.fullname,
          description: el.role ? el.role.description : null,
          nameRu: el.districts[0] ? el.districts[0].nameRu : null,
        })),
      });
    });

    getNetwork().get(API_AUTH + '/get/roles').then((r) => {
      this.setState({
        roles: r,
        loading: false,
      });
    });

    getNetwork().get(API_AUTH + '/get/regions').then((r) => {
      this.setState({
        regions: r,
        loading: false,
      });
    });
  }

  openModal(record) {
    let user = this.state.users.find((e) => e.login == record.login);
    this.setState({
      showModal: true,
      selected: record,
      userChange: {
        login: user.login,
        fullname: user.fullname,
        password: user.password,
        phone: user.phone,
        role: user.role,
        districts: user.districts,
      },
    });
  }

  handleOk() {
    let { t } = this.props;
    const { userChange, users, selected } = this.state;
    let payload = users.find((e) => e.login == selected.login);
    delete payload.description;

    Object.keys(userChange).map(function (key) {
      payload[key] = userChange[key];
    });

    getNetwork()
      .put(API_AUTH + '/edit/user', JSON.stringify(payload), {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        message.success(t('configuringUsers.savedBy'));
        this.getData();
      });
    this.setState({
      showModal: false,
    });
  }

  handleCancel() {
    this.setState({
      showModal: false,
      showAddModal: false,
    });
  }

  changeInputValue(e, key) {
    this.setState({
      [key]: e.target.value,
    });
  }

  changeUserInputValue(e, key) {
    this.setState({
      userChange: { ...this.state.userChange, [key]: e.target.value },
    });
  }

  openAddModal() {
    this.setState({
      showAddModal: true,
      login: '',
      fullname: '',
      password: '',
      phone: '',
      role: '',
    });
  }

  checkAddUserInput() {
    let { t } = this.props;
    let {
      login,
      password,
      fullname,
      phone,
      role,
      districts,
      users,
      organizations
    } = this.state;
    login = login.trim();
    if (login && password && fullname && phone && role && districts && organizations) {
      let bool = !users.some((user) => user.login.trim() === login);

      if (bool) {
        this.addUser();
      } else {
        message.warning(
          t('configuringUsers.thisUserAlreadyExistsPleaseEnterAnother')
        );
      }
    } else {
      message.error(t('configuringUsers.pleaseFillInAllFields'));
    }
  }

  addUser() {
    let { t } = this.props;
    let payload = {
      login: this.state.login,
      password: this.state.password,
      fullname: this.state.fullname,
      phone: this.state.phone,
      role: this.state.role,
      districts: this.state.districts,
      organizations: this.state.organizations
    };

    let header = { headers: { 'Content-Type': 'application/json' } };
    getNetwork()
      .post(API_AUTH + '/add/user', JSON.stringify(payload), header)
      .then(() => {
        message.success(
          t('configuringUsers.user') +
          ' ' +
          this.state.login +
          ' ' +
          t('configuringUsers.established')
        );
        this.setState({
          showAddModal: false,
          login: '',
          password: '',
          fullname: '',
          phone: '',
          role: {},
          districts: {},
          organizations: null
        });
        this.getData();
      })
      .catch((err) => {
        message.error(err);
      });
  }
  onUserChangeRole(val) {
    this.setState({
      userChange: {
        ...this.state.userChange,
        role: this.state.roles.data.find((e) => e.description == val),
      },
    });
  }

  onUserChangeRegion(val) {
    this.setState({
      userChange: {
        ...this.state.userChange,
        districts: this.state.regions.data.find((e) => e.nameRu == val),
      },
    });
  }

  onUserAddRole(val) {
    this.setState({
      role: this.state.roles.data.find((e) => e.description == val),
    });
  }

  async onUserAddRegion(val) {
    const districts = this.state.regions.data.find((e) => e.nameRu === val);
    this.setState({ districts });
    if (districts) {
      const result = await getNetwork().get(API_AUTH + `/get/organizationss/${districts.id}`);
      if (result.status === 200) {
        this.setState({ organisations: result.data });
      }
    }
  }

  handleChangeOrgs(val) {
    const organizations = this.state.organisations.find((e) => e.id === val);
    this.setState({ organizations });
  }

  deleteUser(val) {
    let { t } = this.props;
    let payload = this.state.users.find((e) => e.login == val.login);
    let header = { headers: { 'Content-Type': 'application/json' } };

    getNetwork()
      .post(API_AUTH + '/delete/user', JSON.stringify(payload), header)
      .then(() => {
        message.success(t('configuringUsers.removed'));
        this.getData();
      })
      .catch((err) => {
        message.error(err);
      });
  }

  render() {
    let { t } = this.props;
    const { roles, regions, organisations } = this.state;

    console.log(this.state.tableData);

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    const config = {
      pagination: {
        pageSizeOptions: ['10', '20', '30', '60', '80', '100'],
        showSizeChanger: true,
        defaultPageSize: 100,
      },
    };

    const columns = [
      {
        title: t('configuringUsers.fullName'),
        dataIndex: 'fullname',
        key: 'fullname',
      },
      {
        title: t('configuringUsers.login'),
        dataIndex: 'login',
        key: 'login',
      },
      {
        title: t('configuringUsers.role'),
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: t('configuringUsers.region'),
        dataIndex: 'nameRu',
        key: 'nameRu',
      },
      {
        title: t('configuringUsers.action'),
        key: 'action',
        render: (text, record) => (
          <span>
            <span className="link" onClick={() => this.openModal(record)}>
              {t('configuringUsers.edit')}
            </span>
            <Divider type="vertical" />
            <span className="link" onClick={() => this.deleteUser(record)}>
              {t('configuringUsers.delete')}
            </span>
          </span>
        ),
      },
    ];

    return (
      <BasicLayout>
        <h3 style={{ float: 'left' }}>{t('configuringUsers.allUsers')}</h3>
        <Button
          type="primary"
          style={{ float: 'right', marginBottom: '20px', zIndex: 12 }}
          onClick={() => this.openAddModal()}
        >
          {t('configuringUsers.addUser')}
        </Button>
        <Spin
          tip={t('configuringUsers.loadingPleaseWait') + '...'}
          spinning={this.state.loading}
        >
          <Table
            rowKey={(record) => record.login}
            className="components-table-demo-nested"
            columns={columns}
            dataSource={this.state.tableData}
            {...config}
          />
          <Modal
            title={t('configuringUsers.editUser')}
            visible={this.state.showModal}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            <div>
              <Form {...layout} name="basic" initialValues={{ remember: true }}>
                <div style={{ marginBottom: '10px' }}>
                  <Input
                    placeholder={t('configuringUsers.login')}
                    value={this.state.userChange.login}
                    onChange={(e) => this.changeUserInputValue(e, 'login')}
                  />
                </div>
                <div style={{ marginBottom: '10px' }}>
                  <Input
                    placeholder={t('configuringUsers.password')}
                    value={this.state.userChange.password}
                    onChange={(e) => this.changeUserInputValue(e, 'password')}
                  />
                </div>
                <div style={{ marginBottom: '10px' }}>
                  <Input
                    placeholder={t('configuringUsers.fullName')}
                    value={this.state.userChange.fullname}
                    onChange={(e) => this.changeUserInputValue(e, 'fullname')}
                  />
                </div>
                <div style={{ marginBottom: '10px' }}>
                  <Input
                    placeholder={t('configuringUsers.phoneNumber')}
                    value={this.state.userChange.phone}
                    onChange={(e) => this.changeUserInputValue(e, 'phone')}
                  />
                </div>
                <div style={{ marginBottom: '10px' }}>
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder={t('configuringUsers.roles')}
                    optionFilterProp="children"
                    onChange={this.onUserChangeRole}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {typeof roles.data !== 'undefined'
                      ? roles.data.map((e) => (
                        <Select.Option key={e.id} value={e.description}>
                          {e.description}
                        </Select.Option>
                      ))
                      : null}
                  </Select>
                </div>
                <div style={{ marginBottom: '10px' }}>
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder={t('configuringUsers.regions')}
                    optionFilterProp="children"
                    onChange={this.onUserChangeRegion}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {typeof regions.data !== 'undefined'
                      ? regions.data.map((e) => (
                        <Select.Option key={e.id} value={e.nameRu}>
                          {e.nameRu}
                        </Select.Option>
                      ))
                      : null}
                  </Select>
                </div>
              </Form>
            </div>
          </Modal>
          <Modal
            title={t('configuringUsers.addingNewUser')}
            visible={this.state.showAddModal}
            onOk={this.checkAddUserInput}
            onCancel={this.handleCancel}
          >
            <div style={{ marginBottom: '10px' }}>
              <Input
                placeholder={t('configuringUsers.login')}
                value={this.state.login}
                onChange={(e) => this.changeInputValue(e, 'login')}
              />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <Input
                placeholder={t('configuringUsers.password')}
                value={this.state.password}
                onChange={(e) => this.changeInputValue(e, 'password')}
              />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <Input
                placeholder={t('configuringUsers.fullName')}
                value={this.state.fullname}
                onChange={(e) => this.changeInputValue(e, 'fullname')}
              />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <Input
                placeholder={t('configuringUsers.phoneNumber')}
                value={this.state.phone}
                onChange={(e) => this.changeInputValue(e, 'phone')}
              />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder={t('configuringUsers.roles')}
                optionFilterProp="children"
                onChange={this.onUserAddRole}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {typeof roles.data !== 'undefined'
                  ? roles.data.map((e) => (
                    <Select.Option key={e.id} value={e.description}>
                      {e.description}
                    </Select.Option>
                  ))
                  : null}
              </Select>
            </div>
            <div style={{ marginBottom: '10px' }}>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder={t('configuringUsers.regions')}
                optionFilterProp="children"
                onChange={this.onUserAddRegion}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {typeof regions.data !== 'undefined'
                  ? regions.data.map((e) => (
                    <Select.Option key={e.id} value={e.nameRu}>
                      {e.nameRu}
                    </Select.Option>
                  ))
                  : null}
              </Select>
            </div>
            <div style={{ marginBottom: '10px' }}>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder='Организации'
                onChange={this.handleChangeOrgs}
                disabled={!this.state.organisations.length}
              >
                {organisations.length > 0
                  ? organisations.map((e) => (
                    <Select.Option key={e.id} value={e.id}>
                      {e.nameRu}
                    </Select.Option>
                  ))
                  : null}
              </Select>
            </div>
          </Modal>
        </Spin>
      </BasicLayout>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return {
    user,
  };
}

export default withTranslation()(connect(mapStateToProps)(UsersList));
