import React, { Component } from 'react';
import { connect } from 'react-redux';
import BasicLayout from '../../components/layout/BasicLayout';
import { API_ROOT } from '../../config';
import { Table, Divider, Popconfirm, message, Spin, Modal } from 'antd';
import moment from 'moment';
import Department from '../../components/DeparmentsList';
import { withTranslation } from 'react-i18next';
import { getNetwork } from '../../utils/getNetwork';

class RoadList extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      roads: [],
      eventNameDictionary: [],
      workCategoryDictionary: [],
      builderDictionary: [],
      searchText: '',
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        minIndex: 0,
        maxIndex: 10
      }
    };
    this.getData = this.getData.bind(this);
    this.getFilterDictionary = this.getFilterDictionary.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  async getData() {
    let userAsJSON;
    if (localStorage.getItem('ccAdmin.currentUser'))
      userAsJSON = localStorage.getItem('ccAdmin.currentUser');
    const currentUser = JSON.parse(userAsJSON);
    this.setState({ loading: true });
    if (currentUser.token) {
      const result = await getNetwork().get(API_ROOT + '/api/v1/roadway/get/0/999', {
        headers: {
          Authorization : `Bearer ${currentUser.token}`
        },
      });

      if (result.status === 200) {
        this.setState((state) => ({
          ...state,
          roads: result.data.content.filter((road) => !road.isCross),
          pagination: {
            ...state.pagination,
            total: result.data.totalElements
          }
        }), () => this.getFilterDictionary());
      }
    } else {
      let str = Department(currentUser);
      if (str) {
        getNetwork()
          .get(API_ROOT + '/api/v1/roadway/searcheventname?q=' + str)
          .then((r) => {
            if (r && r.status === 200) {
              let roads = r.data.sort((a, b) => {
                return a.id - b.id;
              });
              roads = roads.filter((road) => !road.isCross);
              this.setState({ roads }, () => this.getFilterDictionary());
            }
          });
      }
    }
    this.setState({ loading: false });
  }

  getFilterDictionary() {
    const { roads } = this.state;
    let dic = roads.map((e) => e.eventName.trim());
    let cat = roads.map((e) => e.workCategory.trim());
    let builder = roads.map((e) => e.builder.trim());
    let eventNameDictionary = [...new Set(dic)];
    let workCategoryDictionary = [...new Set(cat)];
    let builderDictionary = [...new Set(builder)];
    workCategoryDictionary = workCategoryDictionary.filter((e) => e !== '');
    builderDictionary = builderDictionary.filter((e) => e !== '');
    eventNameDictionary = eventNameDictionary.map((item) => {
      return {
        text: item,
        value: item,
      };
    });
    workCategoryDictionary = workCategoryDictionary.map((item) => {
      return {
        text: item,
        value: item,
      };
    });
    builderDictionary = builderDictionary.map((item) => {
      return {
        text: item,
        value: item,
      };
    });
    this.setState({
      eventNameDictionary,
      workCategoryDictionary,
      builderDictionary,
      loading: false,
    });
  }

  goToRoad(record) {
    this.props.history.push('/list/add?id=' + record.id);
  }

  confirmDelete(id) {
    let { t } = this.props;
    getNetwork().post(API_ROOT + '/api/v1/roadway/delete/' + id).then(() => {
      message.success(t('roadRepairPlan.removed') + ' !');
      this.getData();
    });
  }

  handleChangeRoadTable = ({ current, pageSize }) => {
    this.setState((state) => ({
      pagination: {
        ...state.pagination,
        current,
        pageSize,
        minIndex: (current - 1) * pageSize,
        maxIndex: current * pageSize
      }
    }));
  }

  render() {
    const { t } = this.props;
    const {
      eventNameDictionary,
      workCategoryDictionary,
      builderDictionary,
      loading,
      roads,
      // pagination,
    } = this.state;

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title: t('roadRepairPlan.responsible'),
        dataIndex: 'eventName',
        filters: eventNameDictionary,
        onFilter: (value, record) => record.eventName.indexOf(value) === 0,
      },
      {
        title: t('roadRepairPlan.categoryOfWork'),
        dataIndex: 'workCategory',
        filters: workCategoryDictionary,
        onFilter: (value, record) => record.workCategory.indexOf(value) === 0,
      },
      {
        title: t('roadRepairPlan.plot'),
        dataIndex: 'site',
      },
      {
        title: t('roadRepairPlan.street'),
        dataIndex: 'street',
      },
      {
        title: t('roadRepairPlan.contractor'),
        dataIndex: 'builder',
        filters: builderDictionary,
        onFilter: (value, record) => record.builder.indexOf(value) === 0,
      },
      {
        title: t('roadRepairPlan.hiddenWork'),
        dataIndex: 'isHidden',
        render: (text) => <span>{text ? 'Да' : 'Нет'}</span>,
      },
      {
        title: t('roadRepairPlan.start'),
        dataIndex: 'workplanStart',
        render: (text) => (
          <span>{text ? moment(text).format('DD.MM.YYYY') : ''}</span>
        ),
      },
      {
        title: t('roadRepairPlan.end'),
        dataIndex: 'workplanEnd',
        render: (text) => (
          <span>{text ? moment(text).format('DD.MM.YYYY') : ''}</span>
        ),
      },
      {
        title: t('roadRepairPlan.action'),
        key: 'action',
        render: (text, record) => (
          <span>
            <span className="link" onClick={() => this.goToRoad(record)}>
              {t('roadRepairPlan.edit')}
            </span>
            <Divider type="vertical" />
            <Popconfirm
              title={t('roadRepairPlan.exactlyWantToDelete') + '?'}
              onConfirm={() => this.confirmDelete(record.id)}
              onCancel={this.cancel}
              okText={t('roadRepairPlan.yes')}
              cancelText={t('roadRepairPlan.no')}
            >
              <a href="#">{t('roadRepairPlan.delete')}</a>
            </Popconfirm>
          </span>
        ),
      },
    ];

    return (
      <BasicLayout>
        <h3>{t('roadRepairPlan.allRoadRepairPlans')}</h3>
        <Spin
          tip={t('roadRepairPlan.loadingPleaseWait') + '...'}
          spinning={loading}
        >
          <Table
            scroll={{ x: 300 }}
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={roads}
            size="small"
            onChange={this.handleChangeRoadTable}
            // pagination={{
            //   current: pagination.current,
            //   total: pagination.total,
            //   pageSize: pagination.pageSize,
            // }}
          />
          {/* <Modal
            title="Уведомления АРМ Госуслуги"
            open={isModalOpen}
            width={'100%'}
            onCancel={() => {
              setModalOpen(false);
            }}
            footer={false}
          /> */}
        </Spin>
      </BasicLayout>
    );
  }
}
function mapStateToProps(state) {
  const { user } = state;
  return {
    user,
  };
}
export default withTranslation()(connect(mapStateToProps)(RoadList));
