import React from 'react';
import { Form, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { ReportNameSelect } from './ReportNameSelect';
import { DistrictSelect } from './DistrictSelect';
import { LocalitySelect } from './LocalitySelect';
import { OrganizationSelect } from './OrganizationSelect';
import { OrganizationTypesSelect } from './OrganizationTypesSelect';
import { YearSelect } from './YearSelect';
import { MonthSelect } from './MonthSelect';
import { useDispatch, useSelector } from 'react-redux';
import { setFormSelectorsReportData, resetState } from '../../../../../redux/actions/reportSummary';
import { loadReportSummary } from '../../../../../redux/asyncActions/reportSummary';

export const FormControls = ({ form }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reportNames = useSelector((state) => state.reportSummaryDictionaries.reportNames);

  const handleFinish = (values) => {
    const foundedReport = reportNames.find((item) => item.id === values.reportKindId);

    if (foundedReport) {
      const reportType = foundedReport.type;

      dispatch(setFormSelectorsReportData({ ...values, reportType }));
      dispatch(loadReportSummary(values));
    }
  };

  const handleClear = () => {
    form.resetFields();
    dispatch(resetState());
  };

  return (
    <Form
      form={form}
      onFinish={handleFinish}
      layout='vertical'
      className='container-selectors'
    >
      <ReportNameSelect />
      <DistrictSelect />
      <LocalitySelect />
      <OrganizationTypesSelect />
      <OrganizationSelect />
      <YearSelect />
      <MonthSelect />

      <Form.Item>
        <div className='container-buttons'>
          <Button type='primary' htmlType='submit'>
            {t('governmentServiceEducation.search')}
          </Button>
          <Button type='default' onClick={handleClear}>
            {t('governmentServiceEducation.clear')}
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
