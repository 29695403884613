import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import './styles.scss';

export const EditUrlsAndCodes = ({ record, handleUpdateServiceCode }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  // Локальные состояния для URL и кодов
  const [serviceUrls, setServiceUrls] = useState([]);
  const [serviceCodes, setServiceCodes] = useState([]);

  // При изменении record обновляем значения
  useEffect(() => {
    if (record && record.infos) {
      const urls = record.infos.filter(item => item.type === 'URL').map(item => item.info);
      const codes = record.infos.filter(item => item.type !== 'URL').map(item => item.info);

      setServiceUrls(urls);
      setServiceCodes(codes);

      form.setFieldsValue({
        ...Object.fromEntries(urls.map((url, index) => [`serviceUrl-${index}`, url])),
        ...Object.fromEntries(codes.map((code, index) => [`serviceCode-${index}`, code])),
      });
    }
  }, [record, form]);

  if (Array.isArray(record.children) && record.children.length) {
    return null;
  }

  const handleClick = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleFinish = (values) => {
    const updatedUrls = Object.keys(values)
      .filter((key) => key.startsWith('serviceUrl-'))
      .map((key) => values[key]);

    const updatedCodes = Object.keys(values)
      .filter((key) => key.startsWith('serviceCode-'))
      .map((key) => values[key]);

    handleUpdateServiceCode(updatedUrls, updatedCodes, record)

    setServiceUrls(updatedUrls);
    setServiceCodes(updatedCodes);
    setIsModalOpen(false);
  };

  const isVisible = record.isServiceCodeRequired || record.isUrlRequired;
  const isUrlsVisible = serviceUrls.length > 0;
  const isCodesVisible = serviceCodes.length > 0;

  return (
    <div>
      {(isUrlsVisible || isCodesVisible || (isVisible && record.count > 0)) && (
        <Button onClick={handleClick} type="link">
          <Trans i18nKey="governmentServiceEducation.buttonUrlsAndCodes" />
        </Button>
      )}
      <Modal
        title={t('governmentServiceEducation.formUrlsAndCodesTitle')}
        open={isModalOpen}
        onOk={() => form.submit()}
        onCancel={handleCancel}
        okText={t('governmentServiceEducation.save')}
      >
        <Form className='form' layout="vertical" form={form} onFinish={handleFinish}>
          {record.isUrlRequired && (
            <div className='form__group'>
              {Array.from({ length: record.count }).map((_, index) => (
                <Form.Item
                  key={index}
                  label="URL"
                  name={`serviceUrl-${index}`}
                  rules={[{ required: true, message: t('error.requiredField') }]}
                >
                  <Input placeholder={t('governmentServiceEducation.placeholderUrl')} />
                </Form.Item>
              ))}
            </div>
          )}
          {record.isServiceCodeRequired && (
            <div className='form__group'>
              {Array.from({ length: record.count }).map((_, index) => (
                <Form.Item
                  key={index}
                  label={t('governmentServiceEducation.serviceCode')}
                  name={`serviceCode-${index}`}
                  rules={[{ required: true, message: t('error.requiredField') }]}
                >
                  <Input placeholder={t('governmentServiceEducation.placeholderCode')} />
                </Form.Item>
              ))}
            </div>
          )}
        </Form>
      </Modal>
    </div>
  );
};
