export const SET_GOVERNMENT_SERVICES = '@@REPORT_CREATION/SET_GOVERNMENT_SERVICES';
export const SET_EVENTS = '@@REPORT_CREATION/SET_EVENTS';
export const SET_SERVICE_PROVIDERS = '@@REPORT_CREATION/SET_SERVICE_PROVIDERS';
export const SET_FORM_SELECTORS_REPORT_DATA = '@@REPORT_CREATION/SET_FORM_SELECTORS_REPORT_DATA';
export const SET_TABLE_REPORT_DATA = '@@REPORT_CREATION/SET_TABLE_REPORT_DATA';
export const RESET_STATE = '@@REPORT_CREATION/RESET_STATE';
export const ADD_FILE = '@@REPORT_CREATION/ADD_FILE';
export const REMOVE_FILE = '@@REPORT_CREATION/REMOVE_FILE';
export const ADD_URLS_AND_CODES = '@@REPORT_CREATION/ADD_URLS_AND_CODES';
export const SET_VALIDATION_ERRORS = '@@REPORT_CREATION/SET_VALIDATION_ERRORS';
export const CLEAR_VALIDATION_ERRORS = '@@REPORT_CREATION/CLEAR_VALIDATION_ERRORS';
export const INCREMENT_ACTIVE_OPERATIONS = '@@REPORT_CREATION/INCREMENT_ACTIVE_OPERATIONS';
export const DECREMENT_ACTIVE_OPERATIONS = '@@REPORT_CREATION/DECREMENT_ACTIVE_OPERATIONS';