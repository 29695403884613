import { message } from 'antd';
import i18n from '../../i18n';
import { fetchReportSummary } from '../../services/reportSummary';
import { setTableReportData, setIsLoading } from '../actions/reportSummary';
import { groupData } from '../../pages/GovernmentServiceEducation/utils/dataPreparationSummaryEvents';

export const loadReportSummary = (selectors) => async (dispatch) => {
  dispatch(setIsLoading(true));
  const params = new URLSearchParams();

  Object.entries(selectors).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      params.append(key, value.join(','));
    } else if (value !== undefined && value !== null) {
      params.append(key, value);
    }
  });

  try {
    const response = await fetchReportSummary(params.toString());
    const result = response.data;
    if (!result[0] || !result[0].data) return;

    const { reportKindId } = selectors;
    const data = result[0].data;
    const preparedData = (reportKindId === 2) ? groupData(data) : data;
    dispatch(setTableReportData(preparedData));
  } catch (error) {
    message.error(i18n.t('error.loadingData'));
  } finally {
    dispatch(setIsLoading(false));
  }
};