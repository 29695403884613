import React from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const OrganizationSelect = () => {
  const { t } = useTranslation();
  const organizations = useSelector((state) => state.reportCreationDictionaries.organizations);
  const currentUser = useSelector((state) => state.currentUser);

  const hasOrganizations = currentUser.organizations.length > 0;

  const userOrganizationOptions = currentUser.organizations.map((item) => ({
    label: item.nameRu,
    value: item.id
  }));

  const organizationOptions = organizations.map((item) => ({
    label: item.nameRu,
    value: item.id
  }));

  return (
    <Form.Item
      name='organizationId'
      rules={[{ required: true, message: t('error.requiredField') }]}
    >
      <Select placeholder={t('governmentServiceEducation.organization')} options={
        hasOrganizations ? userOrganizationOptions : organizationOptions
      } />
    </Form.Item>
  );
};