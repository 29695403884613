import {
  SET_ACTIVE_TAB,
  SET_REPORT_NAMES,
  SET_DISTRICT,
  SET_LOCALITIES,
  SET_MONTH,
  SET_ORGANIZATIONS,
  SET_YEAR,
  LOAD_DICTIONARIES_ERROR,
  LOAD_DICTIONARIES_SUCCESS,
  LOAD_DICTIONARIES_START
} from '../constants/reportCreationDictionaries';

const initialState = {
  reportNames: [],
  districts: [],
  localities: [],
  organizations: [],
  years: [],
  months: [],
  isLoading: false,
  error: null
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_ACTIVE_TAB:
      return { ...state, activeTab: payload };
    case SET_REPORT_NAMES:
      return { ...state, reportNames: payload };
    case SET_DISTRICT:
      return { ...state, districts: payload };
    case SET_LOCALITIES:
      return { ...state, localities: payload };
    case SET_ORGANIZATIONS:
      return { ...state, organizations: payload };
    case SET_YEAR:
      return { ...state, years: payload };
    case SET_MONTH:
      return { ...state, months: payload };
    case LOAD_DICTIONARIES_START:
      return {
        ...state,
        isLoading: true,
        error: null
      };

    case LOAD_DICTIONARIES_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: null
      };

    case LOAD_DICTIONARIES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
}