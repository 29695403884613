import React, { useEffect } from 'react';
import { FormControls } from './ReportFormControls';
import { useSelector, useDispatch } from 'react-redux';
import { Spin, Alert, Form } from 'antd';
import { loadAllDictionaryReportSummary } from '../../../../redux/asyncActions/reportSummaryDictionaries';
import { Table } from './ReportTable';

export const ReportSummary = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { isLoadingDictionaries, error } = useSelector((state) => ({
    isLoadingDictionaries: state.reportSummaryDictionaries.isLoadingDictionaries,
    error: state.reportSummaryDictionaries.error
  }));

  useEffect(() => {
    dispatch(loadAllDictionaryReportSummary());
  }, [dispatch]);

  if (isLoadingDictionaries) {
    return (
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <Spin size='large' />
      </div>
    );
  }

  if (error) {
    return (
      <Alert
        message="Ошибка"
        description="Не удалось загрузить справочники. Попробуйте обновить страницу."
        type="error"
        showIcon
      />
    );
  }

  return (
    <div>
      <FormControls form={form} />
      <Table />
    </div>
  );
};
