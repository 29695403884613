import React, { useCallback } from 'react';
import { Select, Button } from 'antd';
import { API_ROOT } from '../../../../../../config';
import { getNetwork } from '../../../../../../utils/getNetwork';
import { useTranslation } from 'react-i18next';
import styles from './FilterChartStatus.module.scss';

const { Option } = Select;

export const FilterChartStatus = ({
         formValues,
         setFormValues,
         formData,
         setStatusData,
         setIsLoading,
       }) => {
         const { t } = useTranslation();

         const handleChangeReportType = value => {
           setStatusData([]);
           setFormValues(prev => ({ ...prev, reportType: value }));
         };

         const handleChangeModel = value => {
           setStatusData([]);
           setFormValues(prev => ({ ...prev, model: value }));
         };

         const handleChangeYear = value => {
           setStatusData([]);
           setFormValues(prev => ({ ...prev, year: value }));
         };

         const handleClickResetButton = () => {
           setStatusData([]);
           setFormValues({
             reportType: null,
             modelId: null,
             year: null,
           });
         };

         const getChartStatusData = useCallback(() => {
           setIsLoading(true);
           const { reportType, model, year } = formValues;
           const queryFields = [
             { name: 'reportType', value: `REPORT_${reportType}` },
             { name: 'modelId', value: model },
             { name: 'yearId', value: year },
           ];
           const queryParam = new URLSearchParams();

           queryFields.forEach(query => {
             if (query.value) {
               queryParam.set(query.name, query.value);
             }
           });
           getNetwork()
             .get(API_ROOT + `/myReport/rep-status?${queryParam}`)
             .then((res = {}) => {
               if (res.status === 200) {
                 const result = res.data;
                 setStatusData(result);
               }
             })
             .finally(() => setIsLoading(false));
         }, [formValues, setStatusData]);

         return (
           <div className={styles.container}>
             <Select
               className={`${styles.select} ${styles.maxSelect}`}
               placeholder={t('chartStatus.reportTitle')}
               value={formValues.reportType}
               onChange={handleChangeReportType}
             >
               {formData.reportTypes.map(e => (
                 <Option key={e.id} value={e.id}>
                   {e.reportTitle}
                 </Option>
               ))}
             </Select>
             <Select
               className={`${styles.select}`}
               placeholder={t('chartStatus.modelOrganization')}
               value={formValues.model}
               onChange={handleChangeModel}
               allowClear
             >
               {formData.models.map(e => (
                 <Select.Option key={e.id} value={e.id}>
                   {e.modelOrganization}
                 </Select.Option>
               ))}
             </Select>
             <Select
               className={`${styles.select} ${styles.minSelect}`}
               placeholder={t('chartStatus.year')}
               value={formValues.year}
               onChange={handleChangeYear}
             >
               {formData.years.map(el => (
                 <Option key={el.id} value={el.id}>
                   {el.year}
                 </Option>
               ))}
             </Select>
             <Button
               className={styles.searchButton}
               type="primary"
               onClick={getChartStatusData}
               disabled={!formValues.reportType || !formValues.year}
             >
               {t('chartStatus.search')}
             </Button>
             <Button onClick={handleClickResetButton}>{t('chartStatus.reset')}</Button>
           </div>
         );
       };
