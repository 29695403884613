import { useCallback, useState } from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { getNetwork } from '../../../../../../../utils/getNetwork';
import { API_ROOT } from '../../../../../../../config';
import { EDITABLE_COLUMNS } from '../constants';
import { EditableColumns } from '../EditableColumns';
import { useSelector } from 'react-redux';

export const useEditChart = ({ formValues, reports, setReports, handleCancelModal, finalize }) => {
  const value = useSelector((state) => state.reportView.reportData);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const getSumValue = useCallback(
    (elem, name) =>
      EDITABLE_COLUMNS[formValues.chartType].reduce((acc, cur) => {
        if (cur.includes(name) && !['totalGovServicesProvidedJur', 'totalGovServicesProvidedPhys'].includes(cur)) {
          acc += elem[cur] || 0;
        }
        return acc;
      }, 0),
    [formValues.chartType],
  );

  const updateData = useCallback(
    (elem) => ({
      ...elem,
      totalGovServicesProvidedPhys: getSumValue(elem, 'Phys'),
      totalGovServicesProvidedJur: getSumValue(elem, 'Jur'),
    }),
    [getSumValue],
  );

  const checkModifyElement = useCallback(
    (name, isEdit) => {
      const fieldsYr = EDITABLE_COLUMNS[formValues.chartType].filter((title) => title.includes('Yr'));
      return fieldsYr.includes(name) && !isEdit;
    },
    [formValues.chartType],
  );

  const getTotalField = useCallback(
    (name) =>
      reports.reduce((acc, cur) => {
        if (cur[name] && cur.nameOfService !== 'Итого') {
          acc += cur[name];
        }
        return acc;
      }, 0),
    [reports],
  );

  const { columns, errors } = EditableColumns({
    formValues,
    getTotalField,
    checkModifyElement,
    setReports,
    updateData,
    reports,
  });

  const requestEditReport = async (body, finalize) => {
    const result = finalize ? await getNetwork().post(`${API_ROOT}/edu-report/${value.id}/refine`, body) : await getNetwork().put(`${API_ROOT}/edu-report/${value.id}`, body);
    if (result.status === 200) {
      setIsLoading(false);
      localStorage.removeItem('formReport');
      localStorage.removeItem('reportsStash');
      localStorage.removeItem('formCreateReport');
      handleCancelModal();
      message.success(t('gosServiceList.successSavingReport'));
    } else {
      message.error(t('error.errorSavingReport'));
    }
  };

  const handleClickSave = async () => {
    if (Object.keys(errors).length > 0) {
      message.error(t('error.pleaseFillInTheHighlightedFields'));
      return;
    }

    const newData = reports
      .filter((cur) => cur.nameOfService !== 'Итого')
      .map((cur) => ({
        ...cur,
        ...(formValues.chartType === 1 && {
          serviceId: cur.service.id,
        }),
        ...(formValues.chartType === 3 && {
          organizationId: cur.providerOrganization.id,
        }),
      }));

    if (formValues.chartType === 1) {
      return requestEditReport({
        reportType: 'EDU_SERVICE_REPORT',
        regionId: value.region.id,
        localityId: value.locality.id,
        organizationId: value.organization.id,
        monthId: value.month.id,
        yearId: value.year.id,
        data: newData,
      }, finalize);
    }
    if (formValues.chartType === 2) {

      const filteredData = reports.filter(item => item.eduActivity.chapter && item.eduActivity.chapter.name === 'Информация о результатах контрольных мероприятий (установленных по итогам внутреннего контроля)');

      const first = filteredData.filter(it => it.files && it.files.length > 0 || it.infos && it.infos.length > 0);


      const chapterFileIds = first && first[0] && first[0].files
        .filter(elem => elem.type === 'CHAPTER')
        .map(elem => elem.file.id) || [];

      const categoryFileIds = first && first[0] && first[0].files
        .filter(elem => elem.type === 'CATEGORY')
        .map(elem => elem.file.id) || [];

      const subCategoryFileIds = first && first[0] && first[0].files
        .filter(elem => elem.type === 'SUBCATEGORY')
        .map(elem => elem.file.id) || [];

      const urls = reports.filter(item => item.infos && item.infos.length > 0);

      const urlsMas = urls ? urls[0] && urls[0].infos.map(elem => elem.info) : [];

      console.log(chapterFileIds, categoryFileIds, subCategoryFileIds, urlsMas);

      const dataForActivity = reports.map(it => {
        return {
          eduActivityId: it.eduActivity.id,
          chapterCount: it.chapterCount,
          categoryCount: it.categoryCount,
          subCategoryCount: it.subCategoryCount,
          chapterFileIds: chapterFileIds,
          categoryFileIds: categoryFileIds,
          subCategoryFileIds: subCategoryFileIds,
          urls: it.infos.length > 0 ? urlsMas : [],
          serviceCodes: [],
          id: it.id,
        };
      });

      return requestEditReport({
        reportType: 'EDU_ACTIVITY_REPORT',
        regionId: value.region.id,
        localityId: value.locality.id,
        organizationId: value.organization.id,
        monthId: value.month.id,
        yearId: value.year.id,
        data: dataForActivity,
      }, finalize);
    }
    if (formValues.chartType === 3) {
      return requestEditReport({
        reportType: 'EDU_PROVIDER_REPORT',
        regionId: value.region.id,
        localityId: value.locality.id,
        organizationId: value.organization.id,
        monthId: value.month.id,
        yearId: value.year.id,
        data: newData,
      }, finalize);
    }
    requestEditReport(newData);
  };

  return { isLoading, columns, handleClickSave };
};
