import { Button, notification } from 'antd';
import React from 'react';
import * as XlsxPopulate from 'xlsx-populate';
import { applyFill, applyStyle, setDataToXls } from './utils/excelStyleUtils';
import { LISTS_SERVICE_PROVIDER_FOR_EXPORT } from './constants';

const MAIN_NAME_SHEET = 'СВОД';

const getFormatedSeparateData = (data) => {
  return data.reduce((acc, item) => {
    const organization = item[0] && item[0].organization;
    if (organization) {
      acc[organization] = item || [];
    }
    return acc;
  }, {});
};

export default function ExportExcel({ fileName = 'excel', data, separateData, chartType, disabled = false, isConsolidatedReport }) {
  const getWorkbook = async () => {
    const url = `/xlsx/gosEduTemplate${chartType}.xlsx`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Received a ${response.status} HTTP code.`);
      }
      const arrayBuffer = await response.arrayBuffer();
      return XlsxPopulate.fromDataAsync(arrayBuffer);
    } catch (error) {
      notification.error({ message: 'Ошибка загрузки шаблона', description: error.message });
      throw error;
    }
  };

  const generateDefaultReport = async (type) => {
    const workbook = await getWorkbook();
    const sheet = workbook.sheet(0).name(MAIN_NAME_SHEET);
    applyFill(sheet, data, chartType);
    setDataToXls(sheet, data, chartType);
    applyStyle(sheet, chartType);
    sheet.cell('A2').value(fileName);
    return workbook.outputAsync({ type });
  };

  const generateConsolidatedReport = async (type) => {
    const workbook = await getWorkbook();
    const sheet = workbook.sheet(0);
    let formatedSeparateData = getFormatedSeparateData(separateData);
    formatedSeparateData = { [MAIN_NAME_SHEET]: data, ...formatedSeparateData };
    const promises = Object.entries(formatedSeparateData).map(
      async ([organization, organizationData]) => {
        const sheetName = LISTS_SERVICE_PROVIDER_FOR_EXPORT[organization] || organization;
        const newSheet = workbook.cloneSheet(sheet, sheetName, 0);
        await applyFill(newSheet, organizationData, chartType);
        await setDataToXls(newSheet, organizationData, chartType);
        await applyStyle(newSheet, chartType);
        newSheet.cell('A2').value(fileName);
      }
    );
    await Promise.all(promises);
    workbook.deleteSheet(sheet.name());
    return workbook.outputAsync({ type });
  };

  const generate = async (type) => {
    return isConsolidatedReport ? generateConsolidatedReport(type) : generateDefaultReport(type);
  };

  const saveFile = (blob, fileName) => {
    try {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, `${fileName}.xlsx`);
      } else {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = url;
        a.download = `${fileName}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    } catch (err) {
      notification.error({ message: 'Ошибка при сохранении файла', description: err.message });
    }
  };

  const generateBlob = async () => {
    try {
      const blob = await generate();
      saveFile(blob, fileName);
    } catch (err) {
      notification.error({ message: 'Ошибка при экспорте в Excel', description: err.message });
    }
  };

  const handleExport = () => {
    generateBlob();
  };

  return (
    <Button onClick={handleExport} disabled={disabled}>
      Экспорт в Excel
    </Button>
  );
}
