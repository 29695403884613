import { Button, message, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { API_ROOT } from '../../../../config';
import { getNetwork } from '../../../../utils/getNetwork';

export const EventForm = ({ editData, onCancel, getEventData }) => {
  const [formValues, setFormValues] = useState({
    chapter: null,
    category: null,
    subcategory: null
  });
  const [formData, setFormData] = useState({
    chapterList: [],
    categoryList: [],
    subcategoryList: [],
  });


  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (editData) setFormValues(editData);
    return () => {
      setFormValues({});
    };
  }, [editData]);

  const getData = () => {
    Promise.all([
      getNetwork().get(API_ROOT + '/reestruslug/getChapter'),
      getNetwork().get(API_ROOT + '/reestruslug/getCategory'),
      getNetwork().get(API_ROOT + '/reestruslug/getSubcategory'),
    ])
      .then((resArr) => resArr.map((item) => item.data))
      .then(([chapterList, categoryList, subcategoryList]) =>
        setFormData({ chapterList, categoryList, subcategoryList })
      );
  };

  const handleChange = (name, value) => {
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const postData = () => {
    if (!isEmpty(editData)) {
      getNetwork().put(API_ROOT + '/reestruslug/event', formValues).then((res) => {
        if (res)
          if (res.status === 200) {
            message.success('Услуга успешно изменен!');
            onCancel();
            getEventData();
          }
      });
    } else {
      getNetwork().post(
        API_ROOT + '/reestruslug/create/event',
        {
          ...formValues,
        }
      ).then(
        (res) => {
          if (res)
            if (res.status === 200) {
              message.success('Мероприятие успешно создан!');
              onCancel();
              getEventData();
            }
            else {
              message.error('У вас нет прав на создание!');
              onCancel();
            }
        }
      );
    }
  };

  return (
    <div>
      <div style={{ marginBottom: '10px' }}>
        <Select
          style={{ width: '100%' }}
          placeholder="Раздел"
          value={formValues.chapter}
          onChange={(val) => handleChange('chapter', val)}
        >
          {formData.chapterList.map((el) => (
            <Select.Option key={el.id} value={el.chapter}>
              {el.chapter}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <Select
          style={{ width: '100%' }}
          placeholder="Категория"
          value={formValues.category}
          onChange={(val) => handleChange('category', val)}
        >
          {formData.categoryList.map((el) => (
            <Select.Option key={el.id} value={el.category}>
              {el.category}
            </Select.Option>
          ))}
        </Select>
      </div>

      <div style={{ marginBottom: '10px' }}>
        <Select
          style={{ width: '100%' }}
          placeholder="Подкатегория"
          key="description"
          value={formValues.subcategory}
          onChange={(val) => handleChange('subcategory', val)}
        >
          {formData.subcategoryList.map((el) => (
            <Select.Option key={el.id} value={el.subcategory}>
              {el.subcategory}
            </Select.Option>
          ))}
        </Select>
      </div>

      <div style={{ display: 'flex' }}>
        <Button type="primary" onClick={postData} style={{ marginRight: 15 }}>
          {isEmpty(editData) ? 'Создать' : 'Изменить'}
        </Button>
        <Button onClick={onCancel}>Отмена</Button>
      </div>
    </div>
  );
};
