import React from 'react';
import { Table as AntdTable } from 'antd';
import { useTranslation } from 'react-i18next';
import { getSummaryGovernmentTotals } from '../../utils/getSummaryGovernmentTotals';

export const SummaryGovernmentServicesTable = ({ pageData }) => {
  const { t } = useTranslation();

  const getCell = (value, className, index) => {
    return (
      <AntdTable.Summary.Cell index={index} className={className} align='center'>
        <b>{value}</b>
      </AntdTable.Summary.Cell>
    );
  };

  const totals = getSummaryGovernmentTotals(pageData);

  const listSummaryCells = [
    { value: totals.totalGovServicesProvidedPhys, className: 'fizColumn summaryColumn' },
    { value: totals.totalGovServicesProvidedJur, className: 'yrColumn summaryColumn' },
    { value: totals.governmentForCitizenPhys, className: 'fizColumn' },
    { value: totals.governmentForCitizenJur, className: 'yrColumn' },
    { value: totals.eGovernmentPortalPhys, className: 'fizColumn' },
    { value: totals.eGovernmentPortalJur, className: 'yrColumn' },
    { value: totals.paperFormPhys, className: 'fizColumn' },
    { value: totals.paperFormJur, className: 'yrColumn' },
    { value: totals.eServiceProviderInfoNoContactPhys, className: 'fizColumn' },
    { value: totals.eServiceProviderInfoNoContactJur, className: 'yrColumn' },
    { value: totals.eFormServiceProviderInfoDirContactManualPhys, className: 'fizColumn' },
    { value: totals.eFormServiceProviderInfoDirContactManualJur, className: 'yrColumn' },
    { value: totals.eJustifiedRefusalCount, className: 'refusalColumn summaryColumn' },
    { value: totals.paperJustifiedRefusalCount, className: 'refusalColumn summaryColumn' },
    { value: totals.eUnjustifiedRefusalCount, className: 'refusalColumn summaryColumn' },
    { value: totals.paperUnjustifiedRefusalCount, className: 'refusalColumn summaryColumn' }
  ];

  return (
    <AntdTable.Summary.Row>
      <AntdTable.Summary.Cell index={0} colSpan={3}>
        <b>{t('governmentServiceEducation.total')}</b>
      </AntdTable.Summary.Cell>
      {listSummaryCells.map((item, index) => getCell(item.value, item.className, index + 1))}
    </AntdTable.Summary.Row>
  );
};