import { useTranslation } from 'react-i18next';

export const useTableColumnsServiceProvidersReport = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('governmentServiceEducation.serialNumber'),
      align: 'center',
      width: 100,
      render: (_text, _record, index) => index + 1,
    },
    {
      title: t('governmentServiceEducation.serviceProviderName'),
      key: 'organizationId',
      align: 'center',
      render: (_, record) => record.providerOrganization && record.providerOrganization.nameRu,
    },
    {
      title: t('governmentServiceEducation.serviceTypesCount'),
      dataIndex: 'publicServiceTypeAmount',
      align: 'center',
    },
    {
      title: t('governmentServiceEducation.employeeFullInfo'),
      dataIndex: 'employeeFullName',
      align: 'center',
    },
    {
      title: t('governmentServiceEducation.qualificationYear'),
      dataIndex: 'academyQualificationYear',
      align: 'center',
    },
    {
      title: t('governmentServiceEducation.contactDetails'),
      dataIndex: 'employeeContactInfo',
      align: 'center',
    },
    {
      title: t('governmentServiceEducation.techSpecs'),
      key: 'technicalCriteria',
      children: [
        {
          title: t('governmentServiceEducation.baseClock'),
          dataIndex: 'baseClockFrequencyGHz',
          align: 'center',
        },
        {
          title: t('governmentServiceEducation.cores'),
          dataIndex: 'coreCountGHz',
          align: 'center',
        },
        {
          title: t('governmentServiceEducation.ram'),
          dataIndex: 'ramGb',
          align: 'center',
        },
      ],
    },
  ];
};
