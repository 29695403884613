import React from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DropdownRender } from '../../../components/DropdownRender';

export const MonthSelect = () => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const months = useSelector((state) => state.reportSummaryDictionaries.months);
  const monthIds = Form.useWatch('monthIds', form);

  const monthOptions = months.map((item) => ({
    label: item.nameRu,
    value: item.id
  })).sort((a, b) => a.value - b.value);

  const allMouthIds = months.map(item => item.id);

  const handleSelectAll = () => {
    const shouldSelectAll = !monthIds || monthIds.length !== allMouthIds.length;
    form.setFieldsValue({ monthIds: shouldSelectAll ? allMouthIds : [] });
  };

  return (
    <Form.Item
      name='monthIds'
      rules={[{ required: true, message: t('error.requiredField') }]}
    >
      <Select
        placeholder={t('governmentServiceEducation.month')}
        options={monthOptions}
        style={{ minWidth: 100 }}
        mode="multiple"
        maxTagCount="responsive"
        filterOption={(input, option) =>
          option.label.toLowerCase().includes(input.toLowerCase())
        }
        optionFilterProp="label"
        dropdownRender={(menu) => (
          <DropdownRender
            menu={menu}
            handleSelectAll={handleSelectAll}
            formIds={monthIds}
            dictionaryIds={allMouthIds}
          />
        )}
      />
    </Form.Item>
  );
};