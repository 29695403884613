import React, { useCallback, useState } from 'react';
import { Input, InputNumber } from 'antd';
import { EDITABLE_COLUMNS, EDITABLE_LIST_SERVICE_CODES } from './constants';
import { useTranslation } from 'react-i18next';
import styles from './EditChart.module.scss';
import { EditUploadFile } from './components/EditUploadFile';
import { EditUrlsAndCodes } from './components/EditUrlAndCodes';
import { chain } from 'lodash';

export const EditableColumns = ({
                                  formValues,
                                  getTotalField,
                                  checkModifyElement,
                                  setReports,
                                  updateData,
                                  reports,
                                }) => {
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();
  const width = 100;

  const validateField = useCallback(
    (id, value) => {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        if (value === null || value === '') {
          newErrors[id] = t('intersections.obligatoryField');
        } else {
          delete newErrors[id];
        }
        return newErrors;
      });
    },
    [t],
  );

  const updateServiceCode = (urls, codes, record) => {
    const formattedUrls = urls.map(info => ({ info, type: 'URL' }));

    setReports(prev =>
      prev.map(item => {
        if (formValues.chartType === 2 && item.id === record.mainId) {
          return {
            ...item,
            infos: formattedUrls,
          };
        }
        return item;
      }),
    );
  };

  const handleRemoveFile = (file, record) => {
    setReports((prev) => {
      const updatedReports = prev.map((item) => {
        if (formValues.chartType === 2 && item.id === record.mainId) {
          return {
            ...item,
            files: [...item.files.filter((it) => it.file.id !== file.id)], // Создаём новый массив
          };
        }
        return item;
      });
      return [...updatedReports]; // Создаём новый массив верхнего уровня
    });
  };


  const handleUpdateFiles = (uniqId, newFile, record) => {
    const formattedFile = {
      file: {
        id: newFile[0] ? newFile[0].id : '',
        name: newFile[0] ? newFile[0].name : '',
        uid: newFile[0] ? newFile[0].uid : '',
        uploadTime: newFile[0] ? new Date().toISOString().slice(0, -1) : '', // Это не обязательно для проверки
      },
      type: newFile[0] ? newFile[0].type : '',
    };

    setReports((prev) =>
      prev.map((item) => {
        if (formValues.chartType === 2 && item.id === record.mainId) {
          const fileExists = item.files.some(f => f.file.id === formattedFile.file.id);

          return {
            ...item,
            files: fileExists ? item.files : [...item.files, formattedFile],
          };
        }
        return item;
      }),
    );
  };


  const handleChangeInput = useCallback(
    (fieldTitle, elem, fieldId) => (value) => {
      const newVal = typeof value === 'number' ? value : (value && value.target ? value.target.value : null);

      const updatedField = {
        ...elem,
        [fieldTitle]: newVal,
      };

      setReports((prev) => {

          const chapter = prev.filter(it => it.eduActivity.id === updatedField.eduActivityId);

          const chapters = prev.filter(item => {
            const firstChapter = chapter[0];
            return (
              item.eduActivity &&
              item.eduActivity.chapter &&
              item.eduActivity.chapter.name &&
              firstChapter.eduActivity &&
              firstChapter.eduActivity.chapter &&
              firstChapter.eduActivity.chapter.name &&
              item.eduActivity.chapter.name === firstChapter.eduActivity.chapter.name
            );
          });

          const updatedChapters = chapters.map(item => {
            if (item.id === updatedField.mainId) {
              return { ...item, subCategoryCount: newVal };
            }
            return item;
          });

          const valueForCategorys = chain(updatedChapters).groupBy((item) => (item.eduActivity.category && item.eduActivity.category.name) || '').value();

          const getSubCategorySumsWithIds = (groupedData) => {
            return Object.values(groupedData).map(group => {
              const sum = group.reduce((sum, item) => sum + item.subCategoryCount, 0);
              const mainIds = group.map(item => item.id);
              return { count: sum, mainIds };
            });
          };

          const result = getSubCategorySumsWithIds(valueForCategorys);

          const getTotalCount = (result) => {
            return result.reduce((total, item) => total + item.count, 0);
          };

          const totalCount = getTotalCount(result);

          return prev.map((item) => {

            const matchedResult = result.find(it => it.mainIds.includes(item.id));

            if (matchedResult) {
              return {
                ...item,
                categoryCount: matchedResult.count,
                chapterCount: totalCount,
                subCategoryCount: item.id === updatedField.mainId && formValues.chartType === 2 ? newVal : item.subCategoryCount,
              };
            }

            if (formValues.chartType === 2 && item.id === updatedField.mainId) {
              return {
                ...item,
                subCategoryCount: newVal,
              };
            } else if (item.service && item.service.code === updatedField.service.code && updatedField.service.code) {
              return updateData(updatedField);
            }
            if (item.id === updatedField.id) {
              return updatedField;
            }
            return item;
          });
        },
      );

      validateField(fieldId, newVal);

      updateRecord();

    },
    [formValues.chartType, updateData, setReports, validateField],
  );

  const updateRecord = () => {

  };

  const generateFieldForm = useCallback(
    (name, elem, width) => {

      const fieldId = `${formValues.chartType === 2 ? elem.name : (elem.service && elem.service.code || elem.id)}__${name}`;
      return (
        <>
          {
            EDITABLE_COLUMNS[3].includes(name) ? (
              <Input
                className={errors[fieldId] ? styles['input-error'] : ''}
                style={{ width: '150px' }}
                min={0}
                disabled={
                  !EDITABLE_LIST_SERVICE_CODES.includes(elem.service && elem.service.code) &&
                  checkModifyElement(name, elem.isAvailableEdit)
                }
                defaultValue={elem[name] || 0}
                name={fieldId}
                onChange={handleChangeInput(name, elem, fieldId)}
              />
            ) : (
              <InputNumber
                className={errors[fieldId] ? styles['input-error'] : ''}
                style={{ width: `${width ? `${width}px` : `65px`}` }}
                min={0}
                disabled={
                  !EDITABLE_LIST_SERVICE_CODES.includes(elem.service && elem.service.code) &&
                  checkModifyElement(name, elem.isAvailableEdit)
                }
                defaultValue={elem[name] || 0}
                name={fieldId}
                onChange={handleChangeInput(name, elem, fieldId)}
              />
            )
          }
          {errors[fieldId] && <div className={styles.error}>{errors[fieldId]}</div>}
        </>
      );
    },
    [checkModifyElement, formValues.chartType, handleChangeInput, getTotalField, errors],
  );

  const COLUMNS = {
    1: [
      {
        title: t('userActions.serviceCode'),
        dataIndex: 'serviceCode',
        render: (_, record) => record.service && record.service.code || 0,
      },
      {
        title: t('governmentServiceEducation.governmentServicesName'),
        dataIndex: 'nameOfService',
        width: 200,
        render: (_, record) => record.service && record.service.name || '',
      },
      {
        title: t('governmentServiceEducation.totalServicesProvided'),
        dataIndex: 'summary',
        rowSpan: 3,
        children: [
          {
            children: [
              {
                children: [
                  {
                    title: t('governmentServiceEducation.individuals'),
                    dataIndex: 'totalGovServicesProvidedPhys',
                    align: 'center',
                    className: 'fizColumn summaryColumn',
                  },
                  {
                    title: t('governmentServiceEducation.legalEntities'),
                    dataIndex: 'totalGovServicesProvidedJur',
                    align: 'center',
                    className: 'yrColumn summaryColumn',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: t('governmentServiceEducation.providedThrough'),
        children: [
          {
            title: t('governmentServiceEducation.governmentCorporation'),
            rowSpan: 2,
            children: [
              {
                children: [
                  {
                    title: t('governmentServiceEducation.individuals'),
                    dataIndex: 'governmentForCitizenPhys',
                    className: 'fizColumn',
                    align: 'center',
                    render: (_, record) =>
                      generateFieldForm('governmentForCitizenPhys', record),
                  },
                  {
                    title: t('governmentServiceEducation.legalEntities'),
                    dataIndex: 'governmentForCitizenJur',
                    className: 'yrColumn',
                    align: 'center',
                    render: (_, record) =>
                      generateFieldForm('governmentForCitizenJur', record),
                  },
                ],
              },
            ],
          },
          {
            title: t('governmentServiceEducation.eGovPortal'),
            rowSpan: 2,
            children: [
              {
                children: [
                  {
                    title: t('governmentServiceEducation.individuals'),
                    dataIndex: 'eGovernmentPortalPhys',
                    className: 'fizColumn',
                    align: 'center',
                    render: (_, record) =>
                      generateFieldForm('eGovernmentPortalPhys', record),
                  },
                  {
                    title: t('governmentServiceEducation.legalEntities'),
                    dataIndex: 'eGovernmentPortalJur',
                    className: 'yrColumn',
                    align: 'center',
                    render: (_, record) =>
                      generateFieldForm('eGovernmentPortalJur', record),
                  },
                ],
              },
            ],
          },
          {
            title: t('governmentServiceEducation.governmentAgency'),
            children: [
              {
                title: t('governmentServiceEducation.paperForm'),
                children: [
                  {
                    title: t('governmentServiceEducation.individuals'),
                    dataIndex: 'paperFormPhys',
                    className: 'fizColumn',
                    align: 'center',
                    render: (_, record) => generateFieldForm('paperFormPhys', record),
                  },
                  {
                    title: t('governmentServiceEducation.legalEntities'),
                    dataIndex: 'paperFormJur',
                    className: 'yrColumn',
                    align: 'center',
                    render: (_, record) => generateFieldForm('paperFormJur', record),
                  },
                ],
              },
              {
                title: t('governmentServiceEducation.eServiceNoContact'),
                children: [
                  {
                    title: t('governmentServiceEducation.individuals'),
                    dataIndex: 'eServiceProviderInfoNoContactPhys',
                    className: 'fizColumn',
                    align: 'center',
                    render: (_, record) =>
                      generateFieldForm('eServiceProviderInfoNoContactPhys', record),
                  },
                  {
                    title: t('governmentServiceEducation.legalEntities'),
                    dataIndex: 'eServiceProviderInfoNoContactJur',
                    className: 'yrColumn',
                    align: 'center',
                    render: (_, record) =>
                      generateFieldForm('eServiceProviderInfoNoContactJur', record),
                  },
                ],
              },
              {
                title: t('governmentServiceEducation.eServiceWithContact'),
                children: [
                  {
                    title: t('governmentServiceEducation.individuals'),
                    dataIndex: 'eFormServiceProviderInfoDirContactManualPhys',
                    className: 'fizColumn',
                    align: 'center',
                    render: (_, record) =>
                      generateFieldForm('eFormServiceProviderInfoDirContactManualPhys', record),
                  },
                  {
                    title: t('governmentServiceEducation.legalEntities'),
                    dataIndex: 'eFormServiceProviderInfoDirContactManualJur',
                    className: 'yrColumn',
                    align: 'center',
                    render: (_, record) => generateFieldForm('eFormServiceProviderInfoDirContactManualJur', record),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: t('governmentServiceEducation.justifiedRefusals'),
        key: 'test3',
        rowSpan: 2,
        className: 'refusalColumn',
        children: [
          {
            children: [
              {
                title: t('governmentServiceEducation.electronicForm'),
                dataIndex: 'eJustifiedRefusalCount',
                align: 'center',
                className: 'refusalColumn summaryColumn',
                rowSpan: 2,
                render: (_, record) => generateFieldForm('eJustifiedRefusalCount', record),
              },
              {
                title: t('governmentServiceEducation.paperFormShort'),
                dataIndex: 'paperJustifiedRefusalCount',
                align: 'center',
                className: 'refusalColumn summaryColumn',
                rowSpan: 2,
                render: (_, record) => generateFieldForm('paperJustifiedRefusalCount', record),
              },
            ],
          },
        ],
      },
      {
        title: t('governmentServiceEducation.unjustifiedRefusals'),
        key: 'test4',
        rowSpan: 2,
        className: 'refusalColumn',
        children: [
          {
            children: [
              {
                title: t('governmentServiceEducation.electronicForm'),
                dataIndex: 'eUnjustifiedRefusalCount',
                align: 'center',
                className: 'refusalColumn summaryColumn',
                rowSpan: 2,
                render: (_, record) => generateFieldForm('eUnjustifiedRefusalCount', record),
              },
              {
                title: t('governmentServiceEducation.paperFormShort'),
                dataIndex: 'paperUnjustifiedRefusalCount',
                align: 'center',
                className: 'refusalColumn summaryColumn',
                rowSpan: 2,
                render: (_, record) => generateFieldForm('paperUnjustifiedRefusalCount', record),
              },
            ],
          },
        ],
      },
    ],
    2: [
      {
        title: '№ п/п',
        dataIndex: 'uniqId',
        width: 110,
      },
      {
        title: t('governmentServiceEducation.activities'),
        dataIndex: 'name',
        width: 500,
      },
      {
        title: t('governmentServiceEducation.quantity'),
        dataIndex: 'count',
        align: 'center',
        width: 110,
        render: (_, record) => {
          return Array.isArray(record.children) && record.children.length
            ? record.count
            : generateFieldForm('count', record);
        },
      },
      {
        title: 'Файлы',
        dataIndex: 'file',
        key: 'file',
        align: 'center',
        width: 110,
        render: (_, record) => {
          {
            const isNotEditableLevel = Array.isArray(record.children) && record.children.length;
            if (isNotEditableLevel && record.isAttachmentRequired) {
              return <EditUploadFile record={record} onUpdate={handleUpdateFiles} onRemove={handleRemoveFile} />;
            }

            const isVisible = record.files && record.files.length > 0 || (record.isAttachmentRequired && record.count > 0);

            return isVisible &&
              <EditUploadFile record={record} onUpdate={handleUpdateFiles} onRemove={handleRemoveFile} />;
          }
        },
      },
      {
        title: 'Другое',
        dataIndex: 'infos',
        key: 'infos',
        align: 'center',
        width: 100,
        render: (_, record) => <EditUrlsAndCodes record={record} handleUpdateServiceCode={updateServiceCode} />,
      },
    ],
    3: [
      {
        title: '№',
        dataIndex: '№',
      },
      {
        title: t('governmentServiceEducation.serviceProviderName'),
        dataIndex: 'providerOrganization',
        width: 200,
        render: (_, record) => record.providerOrganization.nameRu || '',
      },
      {
        title: t('governmentServiceEducation.serviceTypesCount'),
        dataIndex: 'publicServiceTypeAmount',
        width: 200,
        render: (_, record) => generateFieldForm('publicServiceTypeAmount', record, width),
      },
      {
        title: t('governmentServiceEducation.employeeFullInfo'),
        dataIndex: 'employeeFullName',
        width: 200,
        render: (_, record) => generateFieldForm('employeeFullName', record),
      },
      {
        title: t('governmentServiceEducation.qualificationYear'),
        dataIndex: 'academyQualificationYear',
        width: 200,
        render: (_, record) => generateFieldForm('academyQualificationYear', record),
      },
      {
        title: t('governmentServiceEducation.contactDetails'),
        dataIndex: 'employeeContactInfo',
        width: 200,
        render: (_, record) => generateFieldForm('employeeContactInfo', record),
      },
      {
        title: t('governmentServiceEducation.techSpecs'),
        children: [
          {
            title: t('governmentServiceEducation.baseClock'),
            dataIndex: 'baseClockFrequencyGHz',
            width: 200,
            render: (_, record) => generateFieldForm('baseClockFrequencyGHz', record),
          },
          {
            title: t('governmentServiceEducation.cores'),
            dataIndex: 'coreCountGHz',
            width: 200,
            render: (_, record) => generateFieldForm('coreCountGHz', record, width),
          },
          {
            title: t('governmentServiceEducation.ram'),
            dataIndex: 'ramGb',
            width: 200,
            render: (_, record) => generateFieldForm('ramGb', record, width),
          },
        ],
      },
    ],
  };
  return {
    columns: COLUMNS[formValues.chartType],
    errors,
  };
};
