import React, { useEffect } from 'react';
import { Table as AntdTable, Button, Spin, message } from 'antd';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useTableColumnsGovernmentsReport } from '../../../hooks/ReportBuilder/useTableColumnsGovernmentsReport';
import { useTableColumnsEventsReport } from '../../../hooks/ReportBuilder/useTableColumnsEventsReport';
import { useTableColumnsServiceProvidersReport } from '../../../hooks/ReportBuilder/useTableColumnsServiceProvidersReport';
import { saveReportCreation } from '../../../../../redux/asyncActions/reportCreation';
import {
  resetState,
  setValidationErrors,
  setTableReportData,
} from '../../../../../redux/actions/reportCreation';
import { generateId } from '../../../../../utils/generateId';
import { SummaryGovernmentServicesTable } from '../../../components/SummaryGovernmentServicesTable';

const getRowKey = (record) => record.uniqId;

export const Table = ({ resetSelectorsForm }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tableReportData = useSelector((state) => state.reportCreation.tableReportData);
  const governmentServices = useSelector((state) => state.reportCreation.governmentServices);
  const events = useSelector((state) => state.reportCreation.events);
  const columnsGovernmentsReport = useTableColumnsGovernmentsReport(governmentServices);
  const columnsEventsReport = useTableColumnsEventsReport();
  const validationErrors = useSelector((state) => state.reportCreation.validationErrors);
  const formSelectorsReportData = useSelector(
    (state) => state.reportCreation.formSelectorsReportData
  );
  const isLoading = useSelector((state) => state.reportCreation.activeOperations > 0);
  const isSelectedFilters = useSelector((state) => state.reportCreation.isSelectedFilters);
  const isGovernmentsTable = formSelectorsReportData.reportType === 'EDU_SERVICE_REPORT';
  const isEventsTable = formSelectorsReportData.reportType === 'EDU_ACTIVITY_REPORT';
  const isServiceProvidersTable = formSelectorsReportData.reportType === 'EDU_PROVIDER_REPORT';

  const handleDeleteRow = (uniqId) => {
    const updatedData = tableReportData.filter((item) => item.uniqId !== uniqId);
    dispatch(setTableReportData(updatedData));

    const newErrors = Object.keys(validationErrors).reduce((acc, key) => {
      if (!key.startsWith(`${uniqId}-`)) {
        acc[key] = validationErrors[key];
      }
      return acc;
    }, {});
    dispatch(setValidationErrors(newErrors));
  };
  const columnsServiceProvidersReport = useTableColumnsServiceProvidersReport(handleDeleteRow);

  const getDataPreparationForEvents = () => {
    const errors = {};

    const validateRecord = (record) => {
      const recordErrors = [];

      if (record.isAttachmentRequired && record.files) {
        const requiredFileCount = record.count > 10 ? 10 : record.count;

        if (record.files.length !== requiredFileCount) {
          recordErrors.push('files');
        }
      }

      if (record.files && record.files.length > record.count) {
        recordErrors.push('excess_files');
      }

      if (record.isUrlRequired && record.urls && record.urls.length !== record.count) {
        recordErrors.push('urls');
      }

      if (record.isServiceCodeRequired && record.codes && record.codes.length !== record.count) {
        recordErrors.push('codes');
      }

      if (recordErrors.length > 0) {
        errors[record.uniqId] = recordErrors;
      }

      if (record.children) {
        record.children.forEach(validateRecord);
      }
    };

    tableReportData.forEach(validateRecord);
    dispatch(setValidationErrors(errors));

    if (Object.keys(errors).length > 0) {
      message.error(t('error.validatingData'));
      return;
    }
    const data = events
      .map((event) => {
        const nodes = findNodesForEvent(event.id, tableReportData);
        const chapter = nodes.chapter || {};
        const category = nodes.category || {};
        const subCategory = nodes.subCategory || {};

        const chapterFileIds = (chapter.files && chapter.files.map((file) => file.id)) || [];
        const categoryFileIds = (category.files && category.files.map((file) => file.id)) || [];
        const subCategoryFileIds =
          (subCategory.files && subCategory.files.map((file) => file.id)) || [];

        const urls = subCategory.urls || category.urls || chapter.urls || [];
        const serviceCodes = subCategory.codes || category.codes || chapter.codes || [];

        return {
          eduActivityId: event.id,
          chapterCount: (chapter && chapter.count) || 0,
          categoryCount: (category && category.count) || 0,
          subCategoryCount: (subCategory && subCategory.count) || 0,
          chapterFileIds,
          categoryFileIds,
          subCategoryFileIds,
          urls,
          serviceCodes,
        };
      })
      .filter((item) => item.eduActivityId);

    return data;
  };

  const getAndValidateDataPreparationServiceProviders = () => {
    const errors = {};
    const requiredNumberFields = ['publicServiceTypeAmount', 'coreCountGHz', 'ramGb'];
    const requiredTextFields = [
      'employeeFullName',
      'academyQualificationYear',
      'employeeContactInfo',
      'baseClockFrequencyGHz',
    ];

    tableReportData.forEach((record) => {
      requiredNumberFields.forEach((field) => {
        if (record[field] === null || record[field] === undefined || record[field] < 1) {
          const key = `${record.uniqId}-${field}`;
          errors[key] = [...(errors[key] || []), field];
        }
      });

      requiredTextFields.forEach((field) => {
        const key = `${record.uniqId}-${field}`;
        if (
          record[field] === null ||
          record[field] === undefined ||
          (typeof record[field] === 'string' && record[field].trim() === '')
        ) {
          errors[key] = [...(errors[key] || []), field];
        }
      });
    });

    dispatch(setValidationErrors(errors));

    if (Object.keys(errors).length > 0) {
      message.error(t('error.validatingData'));
      return;
    }

    const preparedData = tableReportData.map((record) => {
      const { uniqId, ...rest } = record;
      return rest;
    });

    return preparedData;
  };

  const columnsMap = {
    EDU_SERVICE_REPORT: columnsGovernmentsReport,
    EDU_ACTIVITY_REPORT: columnsEventsReport,
    EDU_PROVIDER_REPORT: columnsServiceProvidersReport,
  };

  const findNodesForEvent = (eventId, nodes, parentChapter = null, parentCategory = null) => {
    let result = { chapter: null, category: null, subCategory: null };

    for (const node of nodes) {
      let currentChapter = parentChapter;
      let currentCategory = parentCategory;

      if (node.type === 'chapter') {
        currentChapter = node;
        currentCategory = null;
      } else if (node.type === 'category') {
        currentCategory = node;
      }

      if (node.eventIds && node.eventIds.includes(eventId)) {
        switch (node.type) {
          case 'chapter':
            result.chapter = node;
            break;
          case 'category':
            result.category = node;
            result.chapter = currentChapter;
            break;
          case 'subCategory':
            result.subCategory = node;
            result.category = currentCategory;
            result.chapter = currentChapter;
            break;
          default:
            break;
        }
      }

      if (node.children) {
        const childResult = findNodesForEvent(
          eventId,
          node.children,
          currentChapter,
          currentCategory
        );
        if (childResult.subCategory) {
          result = { ...childResult };
        } else if (childResult.category) {
          result.category = childResult.category;
          result.chapter = childResult.chapter;
        } else if (childResult.chapter) {
          result.chapter = childResult.chapter;
        }
      }
    }

    return result;
  };

  const getDataForSave = () => {
    switch (formSelectorsReportData.reportType) {
      case 'EDU_SERVICE_REPORT':
        return tableReportData;
      case 'EDU_ACTIVITY_REPORT':
        return getDataPreparationForEvents();
      case 'EDU_PROVIDER_REPORT':
        return getAndValidateDataPreparationServiceProviders();
      default:
        return tableReportData;
    }
  };

  const handleClickSave = () => {
    const data = getDataForSave();

    if (!data) return;

    const requestBody = {
      ...formSelectorsReportData,
      data,
    };

    dispatch(saveReportCreation(requestBody))
      .then(() => {
        dispatch(resetState());
        resetSelectorsForm();
        message.success(t('governmentServiceEducation.dataSuccessSaved'));
      })
      .catch(() => {});
  };

  const handleAddRow = () => {
    const newRow = {
      uniqId: generateId(),
      publicServiceTypeAmount: 0,
      employeeFullName: null,
      academyQualificationYear: null,
      employeeContactInfo: null,
      baseClockFrequencyGHz: null,
      coreCountGHz: 0,
      ramGb: 0,
      organizationId: formSelectorsReportData.organizationId,
    };

    const updatedData = [...tableReportData, newRow];
    dispatch(setTableReportData(updatedData));
  };

  useEffect(() => {
    if (!isSelectedFilters || !isGovernmentsTable) return;
    const listTh = document.querySelectorAll('th');
    listTh.forEach((th) => {
      if (!th.innerHTML) {
        th.remove();
      }
    });
  }, [isSelectedFilters, isGovernmentsTable]);

  return (
    <div className="container-table">
      {isSelectedFilters && (
        <Spin tip={t('intersections.loadingPleaseWait') + '...'} spinning={isLoading}>
          <AntdTable
            className={`table-report ${isEventsTable ? 'table-events' : ''}`}
            rowKey={(record) => getRowKey(record)}
            columns={columnsMap[formSelectorsReportData.reportType] || []}
            dataSource={tableReportData}
            pagination={tableReportData.length > 10}
            bordered
            scroll={{ x: isGovernmentsTable ? 1700 : undefined }}
            summary={
              isGovernmentsTable
                ? (pageData) => <SummaryGovernmentServicesTable pageData={pageData} />
                : null
            }
          />
          <div className="wrapper-buttons">
            {isServiceProvidersTable && (
              <Button type="dashed" onClick={handleAddRow}>
                {t('governmentServiceEducation.addRow')}
              </Button>
            )}
            <Button className="button-save" type="primary" onClick={handleClickSave}>
              {t('governmentServiceEducation.save')}
            </Button>
          </div>
        </Spin>
      )}
    </div>
  );
};
