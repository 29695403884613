import React from 'react';
import { Button, Spin, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './EditChart.module.scss';
import { testData } from '../../utils/test';
import { summaryTable } from '../../../../../GosService/utils/SummaryTable';
import { useEditChart } from './hooks/useEditChart';

export const EditChart = ({ reports, setReports, formValues, handleCancelModal, finalize }) => {
  
  const { t } = useTranslation();
  const { isLoading, columns, handleClickSave } = useEditChart({
    formValues,
    reports,
    setReports,
    handleCancelModal,
    finalize,
  });

  return (
    <div>
      <Spin tip={t('intersections.loadingPleaseWait') + '...'} spinning={isLoading}>
        <Table
          rowKey={(record) => (formValues.chartType === 2 ? record.uniqId : record.serviceCode || record.id)}
          columns={columns}
          dataSource={formValues.chartType === 2 ? testData(reports) : reports}
          bordered
          scroll={{
            x: formValues.chartType === 1 ? 1700 : null,
            y: window.innerHeight - 100,
          }}
          className={`table-${formValues.chartType} ${styles.table}`}
        />
        <Button type="primary" onClick={handleClickSave} disabled={!formValues.chartType}>
          Сохранить
        </Button>
      </Spin>
    </div>
  );
};
