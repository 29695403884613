import { useTranslation } from 'react-i18next';

export const useTableColumnsGovernmentsReport = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('governmentServiceEducation.serialNumber'),
      align: 'center',
      width: 100,
      render: (_text, _record, index) => index + 1,
    },
    {
      title: t('governmentServiceEducation.serviceCode'),
      dataIndex: 'serviceId',
      align: 'center',
      render: (_text, record) => record.registryService && record.registryService.code
    },
    {
      title: t('governmentServiceEducation.governmentServicesName'),
      key: 'nameOfService',
      width: 200,
      align: 'center',
      render: (_text, record) => record.registryService && record.registryService.name
    },
    {
      title: t('governmentServiceEducation.totalServicesProvided'),
      dataIndex: 'summary',
      key: 'summary',
      rowSpan: 3,
      children: [
        {
          children: [
            {
              children: [
                {
                  title: t('governmentServiceEducation.individuals'),
                  dataIndex: 'totalGovServicesProvidedPhys',
                  align: 'center',
                  className: 'fizColumn summaryColumn',
                },
                {
                  title: t('governmentServiceEducation.legalEntities'),
                  dataIndex: 'totalGovServicesProvidedJur',
                  align: 'center',
                  className: 'yrColumn summaryColumn',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: t('governmentServiceEducation.providedThrough'),
      children: [
        {
          title: t('governmentServiceEducation.governmentCorporation'),
          rowSpan: 2,
          children: [
            {
              children: [
                {
                  title: t('governmentServiceEducation.individuals'),
                  dataIndex: 'governmentForCitizenPhys',
                  className: 'fizColumn',
                  align: 'center',
                },
                {
                  title: t('governmentServiceEducation.legalEntities'),
                  dataIndex: 'governmentForCitizenJur',
                  className: 'yrColumn',
                  align: 'center',
                },
              ],
            },
          ],
        },
        {
          title: t('governmentServiceEducation.eGovernmentPortal'),
          rowSpan: 2,
          children: [
            {
              children: [
                {
                  title: t('governmentServiceEducation.individuals'),
                  dataIndex: 'eGovernmentPortalPhys',
                  className: 'fizColumn',
                  align: 'center',
                },
                {
                  title: t('governmentServiceEducation.legalEntities'),
                  dataIndex: 'eGovernmentPortalJur',
                  className: 'yrColumn',
                  align: 'center',
                },
              ],
            },
          ],
        },
        {
          title: t('governmentServiceEducation.governmentAgency'),
          children: [
            {
              title: t('governmentServiceEducation.paperForm'),
              children: [
                {
                  title: t('governmentServiceEducation.individuals'),
                  dataIndex: 'paperFormPhys',
                  className: 'fizColumn',
                  align: 'center',
                },
                {
                  title: t('governmentServiceEducation.legalEntities'),
                  dataIndex: 'paperFormJur',
                  className: 'yrColumn',
                  align: 'center',
                },
              ],
            },
            {
              title: t('governmentServiceEducation.eServiceNoContact'),
              children: [
                {
                  title: t('governmentServiceEducation.individuals'),
                  dataIndex: 'eServiceProviderInfoNoContactPhys',
                  className: 'fizColumn',
                  align: 'center',
                },
                {
                  title: t('governmentServiceEducation.legalEntities'),
                  dataIndex: 'eServiceProviderInfoNoContactJur',
                  className: 'yrColumn',
                  align: 'center',
                },
              ],
            },
            {
              title: t('governmentServiceEducation.eServiceWithContact'),
              children: [
                {
                  title: t('governmentServiceEducation.individuals'),
                  dataIndex: 'eFormServiceProviderInfoDirContactManualPhys',
                  className: 'fizColumn',
                  align: 'center',
                },
                {
                  title: t('governmentServiceEducation.legalEntities'),
                  dataIndex: 'eFormServiceProviderInfoDirContactManualJur',
                  className: 'yrColumn',
                  align: 'center',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: t('governmentServiceEducation.justifiedRefusals'),
      key: 'justifiedRefusals',
      rowSpan: 2,
      className: 'refusalColumn',
      children: [
        {
          children: [
            {
              title: t('governmentServiceEducation.electronicForm'),
              dataIndex: 'eJustifiedRefusalCount',
              align: 'center',
              className: 'refusalColumn summaryColumn',
              rowSpan: 2,
            },
            {
              title: t('governmentServiceEducation.paperFormShort'),
              dataIndex: 'paperJustifiedRefusalCount',
              align: 'center',
              className: 'refusalColumn summaryColumn',
              rowSpan: 2,
            },
          ],
        },
      ],
    },
    {
      title: t('governmentServiceEducation.unjustifiedRefusals'),
      key: 'unjustifiedRefusals',
      rowSpan: 2,
      className: 'refusalColumn',
      children: [
        {
          children: [
            {
              title: t('governmentServiceEducation.electronicForm'),
              dataIndex: 'eUnjustifiedRefusalCount',
              align: 'center',
              className: 'refusalColumn summaryColumn',
              rowSpan: 2,
            },
            {
              title: t('governmentServiceEducation.paperFormShort'),
              dataIndex: 'paperUnjustifiedRefusalCount',
              align: 'center',
              className: 'refusalColumn summaryColumn',
              rowSpan: 2,
            },
          ],
        },
      ],
    },
  ];
};