import React, { useEffect } from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { loadLocalities } from '../../../../../redux/asyncActions/reportSummaryDictionaries';
import { DropdownRender } from '../../../components/DropdownRender';

export const LocalitySelect = () => {
  const { t } = useTranslation();
  const localities = useSelector((state) => state.reportSummaryDictionaries.localities);
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();
  const form = Form.useFormInstance();
  const regionIds = Form.useWatch('regionIds', form);
  const localityIds = Form.useWatch('localityIds', form);

  const localityOptions = localities.map((item) => ({
    label: item.nameRu,
    value: item.id
  }));

  const allLocalityIds = localities.map(item => item.id);

  const handleSelectAll = () => {
    const shouldSelectAll = !localityIds || localityIds.length !== allLocalityIds.length;
    form.setFieldsValue({ localityIds: shouldSelectAll ? allLocalityIds : [] });
  };

  useEffect(() => {
    if (regionIds !== undefined && (regionIds || []).length > 0) {
      dispatch(loadLocalities(regionIds, setIsLoading));
    }

    form.setFieldsValue({ localityIds: [] });
  }, [dispatch, regionIds, form]);

  return (
    <Form.Item
      name="localityIds"
      rules={[{ required: true, message: t('error.requiredField') }]}
    >
      <Select
        mode="multiple"
        maxTagCount="responsive"
        loading={isLoading}
        style={{ minWidth: 200 }}
        placeholder={t('governmentServiceEducation.locality')}
        disabled={regionIds === undefined || regionIds.length === 0 || localities.length === 0 || isLoading}
        options={localityOptions}
        filterOption={(input, option) =>
          option.label.toLowerCase().includes(input.toLowerCase())
        }
        optionFilterProp="label"
        dropdownRender={(menu) => (
          <DropdownRender
            menu={menu}
            handleSelectAll={handleSelectAll}
            formIds={localityIds}
            dictionaryIds={allLocalityIds}
          />
        )}
      />
    </Form.Item>
  );
};