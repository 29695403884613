/* eslint-disable no-undef */
/* eslint-disable max-len */

export const API_AUTH =
  process.env.NODE_ENV === 'production'
    ? 'https://akmsc.smartkokshetau.kz/api/auth'
    : '/api/auth';
export const API_ROOT =
  process.env.NODE_ENV === 'production'
    ? 'https://akmsc.smartkokshetau.kz/api/ccadmin'
    : '/api/ccadmin';
