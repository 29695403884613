import * as XlsxPopulate from 'xlsx-populate';

export function generateExcelExport(templateUrl, fileName, cellKeys) {
  return generate(templateUrl, cellKeys)
    .then(function(blob) {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName + '.xlsx');
      } else {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.href = url;
        a.download = fileName + '.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    })
    .catch(function(err) {
      alert(err.message || err);
      throw err;
    });
}

function getWorkbook(templateUrl) {
  return new Promise(function(resolve, reject) {
    var req = new XMLHttpRequest();
    var url = templateUrl;
    req.open('GET', url, true);
    req.responseType = 'arraybuffer';
    req.onreadystatechange = function() {
      if (req.readyState === 4) {
        if (req.status === 200) {
          resolve(XlsxPopulate.fromDataAsync(req.response));
        } else {
          reject('Received a ' + req.status + ' HTTP code.');
        }
      }
    };

    req.send();
  });
}

function generate(templateUrl, cellKeys = [], outPutType = null) {
  return getWorkbook(templateUrl).then(function(workbook) {
    cellKeys.forEach((el) => {
      if (el.key === 'H7' || el.key === 'G13') {
        workbook
          .sheet(1)
          .cell(el.key)
          .value(el.data);
      }
      workbook
        .sheet(0)
        .cell(el.key)
        .value(el.data);
      if (el.border)
        workbook.style('border', 'Borders').style('borderStyle', 'thin');
    });

    return workbook.outputAsync({ type: outPutType });
  });
}
