export const getSummaryGovernmentTotals = (dataArr) => {
  const initial = {
    governmentForCitizenJur: 0,
    governmentForCitizenPhys: 0,
    paperFormJur: 0,
    paperFormPhys: 0,
    paperJustifiedRefusalCount: 0,
    paperUnjustifiedRefusalCount: 0,
    eJustifiedRefusalCount: 0,
    eUnjustifiedRefusalCount: 0,
    totalGovServicesProvidedJur: 0,
    totalGovServicesProvidedPhys: 0,
    eGovernmentPortalJur: 0,
    eGovernmentPortalPhys: 0,
    eServiceProviderInfoNoContactJur: 0,
    eServiceProviderInfoNoContactPhys: 0,
    eFormServiceProviderInfoDirContactManualJur: 0,
    eFormServiceProviderInfoDirContactManualPhys: 0
  };

  return dataArr.reduce((acc, item) => {
    acc.governmentForCitizenJur += (item.governmentForCitizenJur || 0);
    acc.governmentForCitizenPhys += (item.governmentForCitizenPhys || 0);
    acc.paperFormJur += (item.paperFormJur || 0);
    acc.paperFormPhys += (item.paperFormPhys || 0);
    acc.paperJustifiedRefusalCount += (item.paperJustifiedRefusalCount || 0);
    acc.paperUnjustifiedRefusalCount += (item.paperUnjustifiedRefusalCount || 0);
    acc.eJustifiedRefusalCount += (item.eJustifiedRefusalCount || 0);
    acc.eUnjustifiedRefusalCount += (item.eUnjustifiedRefusalCount || 0);
    acc.totalGovServicesProvidedJur += (item.totalGovServicesProvidedJur || 0);
    acc.totalGovServicesProvidedPhys += (item.totalGovServicesProvidedPhys || 0);
    acc.eGovernmentPortalJur += (item.eGovernmentPortalJur || 0);
    acc.eGovernmentPortalPhys += (item.eGovernmentPortalPhys || 0);
    acc.eServiceProviderInfoNoContactJur += (item.eServiceProviderInfoNoContactJur || 0);
    acc.eServiceProviderInfoNoContactPhys += (item.eServiceProviderInfoNoContactPhys || 0);
    acc.eFormServiceProviderInfoDirContactManualJur += (item.eFormServiceProviderInfoDirContactManualJur || 0);
    acc.eFormServiceProviderInfoDirContactManualPhys += (item.eFormServiceProviderInfoDirContactManualPhys || 0);
    return acc;
  }, initial);
};