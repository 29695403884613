const Department = (user) => {
  switch (user.username) {
    case 'uze':
      return 'УЗЭ';
    case 'ugm':
      return 'УГМ';
    case 'ukgs':
      return 'УКГС';
    case 'ueiir':
      return 'УЭиИР';
    case 'alts':
      return 'АлТС';
    case 'almatysu':
      return 'АлматыСУ';
    case 'kaztransgaz':
      return 'КазТрансГаз';
    case 'azhk':
      return 'АЖК';
    case 'alatauskiy':
      return 'Акимат Алатауского района';
    case 'almalinskiy':
      return 'Акимат Алмалинского района';
    case 'auezovskiy':
      return 'Акимат Ауэзовского района';
    case 'medeuskiy':
      return 'Акимат Медеуского района';
    case 'bostandykskiy':
      return 'Акимат Бостандыкского района';
    case 'zhetysuskiy':
      return 'Акимат Жетысуского района';
    case 'nauryzbayskiy':
      return 'Акимат Наурызбайского района';
    case 'turksibskiy':
      return 'Акимат Турксибского района';
    default:
      return '';
  }
};
export default Department;