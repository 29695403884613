import React, { useState, useEffect, useMemo } from 'react';
import { Spin, Table, Checkbox, Button, Divider, Space } from 'antd';
import { API_ROOT } from '../../config';
import { useTranslation } from 'react-i18next';
import { getNetwork } from '../../utils/getNetwork';
import BasicLayout from '../../components/layout/BasicLayout';
import moment from 'moment';
import styles from './UserActions.module.scss';
import {checkRole} from '../../utils/CheckRoleWithToken';
import { RangePicker } from './components/RangePicker';

const roles = ['all', 'akm'];

const Events = {
  VIEW: 'userActions.eventView',
  CREATE: 'userActions.eventCreate',
  UPDATE: 'userActions.eventUpdate',
  DELETE: 'userActions.eventDelete',
  OPEN_CASE: 'userActions.eventOpenCase',
};

const EventObject = {
  serviceName: 'userActions.serviceName',
  serviceCode: 'userActions.serviceCode',
  organization: 'userActions.organization',
  modelOrganization: 'userActions.modelOrganization',
  chapter: 'userActions.chapter',
  category: 'userActions.category',
  subcategory: 'userActions.subcategory',
  reportTitle: 'userActions.reportTitle',
  region: 'userActions.region',
  year: 'userActions.year',
  month: 'userActions.month',
};

export const UserActions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userActionsData, setUserActionsData] = useState([]);
  const [isAction, setIsAction] = useState(false);
  const { t } = useTranslation();

  const { Group: CheckboxGroup } = Checkbox;

  const getData = () => {
    setIsLoading(true);
    getNetwork()
      .get(API_ROOT + '/user-activity')
      .then(res => {
        if (res && res.status === 200) {
          setUserActionsData(res.data);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const getColumnSearchProps = name => ({
    filterDropdown: ({ selectedKeys, setSelectedKeys, clearFilters, confirm, close }) => (
      <div className={styles.box}>
        <div>
          {name === 'dateTime' ? (
            <Space direction="vertical" size={12}>
              <RangePicker selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys} />
            </Space>
          ) : (
            <CheckboxGroup
              className={styles.checkboxGroup}
              options={useMemo(() =>
                userActionsData.reduce((acc, cur) => {
                  if (!acc.includes(cur[name])) {
                    acc.push(cur[name]);
                  }
                  return acc;
                }, [])
              )}
              value={selectedKeys}
              onChange={setSelectedKeys}
            />
          )}
        </div>
        <Divider className={styles.divider} />
        <div className={styles.wrapButton}>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
          >
            {t('userActions.reset')}
          </Button>
          <Button
            onClick={() => {
              close();
              confirm();
            }}
            type="primary"
          >
            {t('userActions.choose')}
          </Button>
        </div>
      </div>
    ),
    onFilter: (value, record) =>
      name === 'dateTime'
        ? (moment(record[name]).format('YYYY-MM-DD') === moment(value).format('YYYY-MM-DD'))
        : record[name] === value,
  });

  const COLUMNS = [
    {
      title: '№',
      dataIndex: 'key',
      align: 'center',
    },
    {
      title: t('userActions.login'),
      dataIndex: 'username',
      ...getColumnSearchProps('username'),
    },
    {
      title: t('userActions.dateTime'),
      dataIndex: 'dateTime',
      render: record => (record ? moment(record).format('DD.MM.YYYY HH:mm:ss') : '—'),
      ...getColumnSearchProps('dateTime'),
    },
    {
      title: t('userActions.action'),
      dataIndex: 'event',
      render: record => (record ? t(Events[record]) : '—'),
      ...getColumnSearchProps('event'),
    },
    {
      title: t('userActions.caseName'),
      dataIndex: 'caseCode',
      render: record => record || '—',
      ...getColumnSearchProps('caseCode'),
    },
    {
      title: t('userActions.object'),
      dataIndex: 'object',
      render: record =>
        record
          ? Object.keys(record).map(key => (
              <p className={styles.actionObject}>
                <span>{t(EventObject[key])}:</span> {record[key]}
              </p>
            ))
          : '—',
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const roleList = checkRole();
    const result = roleList.filter(role => roles.includes(role));

    setIsAction(result.length);
  }, []);

  return (
    <BasicLayout>
      {isAction ? (
        <div className={styles.actions}>
          <Spin tip={t('intersections.loadingPleaseWait') + '...'} spinning={isLoading}>
            <Table
              columns={COLUMNS}
              dataSource={userActionsData.map((data, index) => ({ ...data, key: index + 1 }))}
              bordered
            />
          </Spin>
        </div>
      ) : (
        <div className={styles.errorTitle}>
          <h1>{t('error.doNotHaveAccessToViewThisModule')}</h1>
        </div>
      )}
    </BasicLayout>
  );
};
