import { message } from 'antd';
import axios from 'axios';
import {
  setDistrict,
  setLocalities,
  setMonth,
  setOrganizations,
  setOrganizationTypes,
  setReportNames,
  setYear,
  loadDictionariesStart,
  loadDictionariesSuccess,
  loadDictionariesError,
} from '../actions/reportSummaryDictionaries';
import {
  fetchDistricts,
  fetchLocalities,
  fetchOrganizations,
  fetchReportNames,
  fetchYears,
  fetchMonths,
  fetchOrganizationTypes
} from '../../services/reportSummaryDictionaries';
import i18n from '../../i18n';

export const loadReportNames = () => async (dispatch) => {
  try {
    const response = await fetchReportNames();
    const result = response.data;
    dispatch(setReportNames(result));
  } catch (error) {
    message.error(i18n.t('error.loadingData'));
  }
};

export const loadDistricts = () => async (dispatch) => {
  try {
    const response = await fetchDistricts();
    const result = response.data;
    dispatch(setDistrict(result));
  } catch (error) {
    message.error(i18n.t('error.loadingData'));
  }
};

let cancelTokenSource = null;

export const loadLocalities = (listId, setIsLoading) => async (dispatch) => {
  if (cancelTokenSource) {
    cancelTokenSource.cancel();
  }

  const thisRequestCancelTokenSource = axios.CancelToken.source();
  cancelTokenSource = thisRequestCancelTokenSource;

  try {
    setIsLoading(true);
    const response = await fetchLocalities(listId, thisRequestCancelTokenSource);
    if (cancelTokenSource !== thisRequestCancelTokenSource) {
      return;
    }

    const result = response.data;
    dispatch(setLocalities(result));
  } catch (error) {
    if (axios.isCancel(error)) {
      return;
    }
    message.error(i18n.t('error.loadingData'));
  } finally {
    if (cancelTokenSource === thisRequestCancelTokenSource) {
      cancelTokenSource = null;
    }
    setIsLoading(false);
  }
};


export const loadOrganizations = (listId, setIsLoading) => async (dispatch) => {
  if (cancelTokenSource) {
    cancelTokenSource.cancel();
  }

  const thisRequestCancelTokenSource = axios.CancelToken.source();
  cancelTokenSource = thisRequestCancelTokenSource;
  try {
    setIsLoading(true);
    const response = await fetchOrganizations(listId, thisRequestCancelTokenSource);
    if (cancelTokenSource !== thisRequestCancelTokenSource) {
      return;
    }
    const result = response.data;
    dispatch(setOrganizations(result));
  } catch (error) {
    if (axios.isCancel(error)) {
      return;
    }
    message.error(i18n.t('error.loadingData'));
  } finally {
    if (cancelTokenSource === thisRequestCancelTokenSource) {
      cancelTokenSource = null;
    }
    setIsLoading(false);
  }
};

export const loadOrganizationTypes = () => async (dispatch) => {
  try {
    const response = await fetchOrganizationTypes();
    const result = response.data;
    dispatch(setOrganizationTypes(result));
  } catch (error) {
    message.error(i18n.t('error.loadingData'));
  }
};

export const loadYears = () => async (dispatch) => {
  try {
    const response = await fetchYears();
    const result = response.data;
    dispatch(setYear(result));
  } catch (error) {
    message.error(i18n.t('error.loadingData'));
  }
};

export const loadMonths = () => async (dispatch) => {
  try {
    const response = await fetchMonths();
    const result = response.data;
    dispatch(setMonth(result));
  } catch (error) {
    message.error(i18n.t('error.loadingData'));
  }
};

export const loadAllDictionaryReportSummary = () => async (dispatch) => {
  try {
    dispatch(loadDictionariesStart());
    const [
      responseReportNames,
      responseDistricts,
      responseOrganizationTypes,
      responseYears,
      responseMonths
    ] = await Promise.all([
      fetchReportNames(),
      fetchDistricts(),
      fetchOrganizationTypes(),
      fetchYears(),
      fetchMonths()
    ]);

    dispatch(loadDictionariesSuccess({
      reportNames: responseReportNames.data,
      districts: responseDistricts.data,
      organizationTypes: responseOrganizationTypes.data,
      years: responseYears.data,
      months: responseMonths.data
    }));
  } catch (error) {
    dispatch(loadDictionariesError(error));
  }
};