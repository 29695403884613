import React, { useState, useEffect, useMemo } from 'react';
import { Button, Input, Spin, Table, Popconfirm, message } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { API_ROOT } from '../../../../../config';
import { useTranslation } from 'react-i18next';
import { EventForm } from '../../forms/EventForm';
import { getNetwork } from '../../../../../utils/getNetwork';
const { Search } = Input;

export const ListEvent = ({ isAction }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [eventData, setEventData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [modalState, setModalState] = useState({
    show: false,
    editData: {},
  });
  const { t } = useTranslation();

  const handleClickCreate = () => {
    setModalState({ show: true, editData: {} });
  };

  const getEventData = () => {
    setIsLoading(true);
    getNetwork().get(API_ROOT + '/reestruslug/getAll/events').then((res) => {
      if (res && res.status === 200) {
        setEventData(res.data);
        setSearchData(res.data);
      }
      setIsLoading(false);
    });
  };

  const confirmDelete = (id) => {
    getNetwork().delete(API_ROOT + '/reestruslug/event/' + id).then((res) => {
      if (res.status === 200) {
        message.success('Успешно удален!');
        getEventData();
      }
    });
  };

  const columns = [
    {
      title: '№',
      dataIndex: 'key',
      align: 'center',
      width: '5%',
    },
    {
      title: 'Раздел',
      dataIndex: 'chapter',
      width: '35%',
    },
    {
      title: 'Категория',
      dataIndex: 'category',
      width: '35%',
    },
    {
      title: 'Подкатегория',
      dataIndex: 'subcategory',
      width: '25%',
    },
    (isAction && (
      {
        title: t('intersections.action'),
        render: (_, record) => (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span
              className="link"
              onClick={() =>
                setModalState({ show: true, editData: record })
              }
            >
              {t('intersections.edit')}
            </span>
            <Popconfirm
              title={t('intersections.exactlyWantToDelete') + '?'}
              onConfirm={() => confirmDelete(record.subcategoryId)}
              onCancel={() => { }}
              okText="Да"
              cancelText="Нет"
            >
              <a href="#">{t('intersections.delete')}</a>
            </Popconfirm>
          </div>
        ),
      }
    ))
  ].filter(Boolean);

  useEffect(() => {
    getEventData();
  }, []);

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
        <Button onClick={handleClickCreate}>Создать</Button>
        <Search
          defaultValue={searchValue}
          style={{ marginLeft: 20, width: 400 }}
          placeholder="Поиск по категориям"
          onSearch={(value) => {
            setSearchValue(value);
            setSearchData(eventData.filter((event) => event.category.toLowerCase().includes(value.toLowerCase())));
          }}
          enterButton
        />
      </div>
      <Spin
        tip={t('intersections.loadingPleaseWait') + '...'}
        spinning={isLoading}
      >
        <Table
          columns={columns}
          dataSource={searchData.map((data, index) => ({ ...data, key: index + 1 }))}
          bordered
        />
      </Spin>
      <Modal
        title="Новое мероприятие"
        visible={modalState.show}
        onOk={() => setModalState((prev) => ({ ...prev, show: true }))}
        onCancel={() => setModalState((prev) => ({ ...prev, show: false }))}
        footer={false}
      >
        <EventForm
          editData={modalState.editData}
          getEventData={getEventData}
          onCancel={() => setModalState((prev) => ({ ...prev, show: false }))}
        />
      </Modal>
    </>
  );
};